/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull website-ts'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 303
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/evaneos/Website%20B2C/implementation/website-ts)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '1a7d3eb3e901b9800c0cffde28862826',
  development: '2f7298009c7097eeb97117eae6dc0eb9'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '303',
    branch: 'main',
    source: 'website-ts',
    versionId: '27db5c05-4709-467f-a12a-ac1d1372cb01'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * permet de savoir si l'utilisateur a accès à la page de recommendation
   */
  ab_reco_available?: boolean;
  /**
   * Contient le nombre d'activités contenues dans le trip project "actif" de l'utilisateur
   *
   *
   * :warning: Cela concerne le MVPOC Maturity sur les Activités
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   */
  activity_trip_project_activities_count?: number;
  dclid?: any;
  /**
   * **Dans le code :
   *  **`ProductAnalytics.set('device_category', window.innerWidth < 992 ? 'mobile' : 'desktop');`
   */
  device_category?: string;
  fbclid?: any;
  gclid?: any;
  /**
   * Contient l'information de si l'utilisateur possède un trip project "actif" en cours.
   *
   *
   * :warning: Cela concerne le MVPOC Maturity sur les Activités
   */
  has_active_activity_trip_project?: boolean;
  initial_dclid?: any;
  initial_fbclid?: any;
  initial_gbraid?: any;
  initial_gclid?: any;
  initial_ko_click_id?: any;
  initial_msclkid?: any;
  initial_referrer?: any;
  initial_referring_domain?: any;
  initial_ttclid?: any;
  initial_twclid?: any;
  initial_utm_campaign?: any;
  initial_utm_content?: any;
  initial_utm_id?: any;
  initial_utm_medium?: any;
  initial_utm_source?: any;
  initial_utm_term?: any;
  initial_wbraid?: any;
  /**
   * Propriété permettant de savoir si un user est un salarié d'Evaneos.
   *
   * Ca dépend de l'adresse email.
   */
  is_evaneos?: boolean;
  isConnected?: any;
  KameleoonCampaignID?: any;
  KameleoonCampaignName?: any;
  KameleoonVariationID?: any;
  KameleoonVariationName?: any;
  msclkid?: any;
  referrer?: any;
  referring_domain?: any;
  twclid?: any;
  /**
   * this properties track the status of the user
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | prospect, wanderlust, repeater experiencer, first timer experiencer |
   */
  user_status?: "prospect" | "wanderlust" | "repeater experiencer" | "first timer experiencer";
  utm_campaign?: any;
  utm_content?: any;
  utm_medium?: any;
  utm_source?: any;
  utm_term?: any;
  wbraid?: any;
}

export interface DebugMapLoadFailedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface DebugMapLoadStartedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface DebugMapLoadSucceededProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PyriteFacetClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * ID de la facet (uid) ex: **6409b6e0dcf679a9bc08c613**
   */
  facet_id?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
  /**
   * URL de redirection du CTA Exemple sur ces deux CTA dont les URL de redirection peuvent être administrés par les équipes Métier
   *
   *
   *
   */
  url_redirection: string;
}

export interface PyriteMagazineClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AbItineraryMoreDetailAgentBlockClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | date, price |
   */
  type: "date" | "price";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AccessContentClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ActivitiesMilestoneDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Type of milestone displayed on the page (1,2 or 3)
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | milestone_1, milestone_2, milestone_3 |
   */
  milestone_type: "milestone_1" | "milestone_2" | "milestone_3";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ActivityClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * Number of POIs contained by activity
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  poi_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Number of tags contained by activity
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  tag_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ActivityListClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ActivitySearchbarUsedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  search_value: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ActivityTagFilterClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ActivityTagFilterSelectedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  tags: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AddToMyTripClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * Number of POIs contained by activity
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  poi_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Number of tags contained by activity
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  tag_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AdditionalQuestionAnsweredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Tells us in which of the 3 possible path of the budget experiment the user is in (no budget / budget ok / budget not ok)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Calendly, BC, BNC, BNR |
   */
  current_path: "Calendly" | "BC" | "BNC" | "BNR";
  /**
   * ID de la réponse à une des questions de la discussion entre Evaneos et le traveler juste après la creation de sa request.
   */
  id_message: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Tells us in which of the 3 possible path of the budget experiment the user is in (no budget / budget ok / budget not ok)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Calendly, BC, BNC, BNR |
   */
  start_path: "Calendly" | "BC" | "BNC" | "BNR";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AgenciesDiscoveryClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AgenciesListClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AgencyClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Json qui indique les filtres présents dans where-to-go
   *
   * Format:
   *
   * ```
   * {"continent": [], "needs": [], "period": []}
   * ```
   */
  filter_where_to_go?: string;
  path: string;
  /**
   * La tranche de budget à laquelle appartient l'item clické dans les résultats du POC Where To Go
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | economic, luxurious, moderate |
   */
  poc_wheretogo_budget_type?: "economic" | "luxurious" | "moderate";
  poc_wheretogo_has_social_proof_banner?: boolean;
  /**
   * Le score de "sustainability" de l'agence retournée dans les résultats du POC Where To Go
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  poc_wheretogo_sustainability_score?: number;
  /**
   * Note de l'agence retournée dans les résultats du POC Where To Go
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 5 |
   */
  poc_wheretogo_testimonial_score?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AgencyDiscoveryClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AgencyReviewClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AgencyReviewsFilteredProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   * | Max Value | 5 |
   */
  stars_filtered: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AgencyReviewsMoreProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AgencyReviewsSeenProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AgencyZoneClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AgentClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AllThematicsFilterClickedProperties {
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ArticleTagClickedProperties {
  /**
   * Type de l'article.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | general_inspiration, general_practical, food, history, formalities, guide, transportation, budget, security, souvenir, courtesy, tip, wild, arts, honeymoon, personalities, religion, geography, to_see, disability, other, eco_tourism, events, culture, language, economy |
   */
  article_type:
    | "general_inspiration"
    | "general_practical"
    | "food"
    | "history"
    | "formalities"
    | "guide"
    | "transportation"
    | "budget"
    | "security"
    | "souvenir"
    | "courtesy"
    | "tip"
    | "wild"
    | "arts"
    | "honeymoon"
    | "personalities"
    | "religion"
    | "geography"
    | "to_see"
    | "disability"
    | "other"
    | "eco_tourism"
    | "events"
    | "culture"
    | "language"
    | "economy";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface AuthenticationSucceedProperties {
  /**
   * Booléen permettant de savoir si le compte client était déjà présent dans notre BDD.
   *
   * Utile car le comportement n'est pas forcément le même si c'est un nouveau user.
   */
  already_existing?: boolean;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * **email:** Evaneos login (email + password)
   *
   * **google:** authentication with Google account
   *
   * **apple:** authentication with Apple account
   *
   * **google-one-tap:** authentication with Google one tap version (https://developers.google.com/identity/gsi/web/guides/features?hl=fr)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, google, apple, google-one-tap |
   */
  connection_source: "email" | "google" | "apple" | "google-one-tap";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface BetterTripClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface BlockViewedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Lorsqu'il y a un affichage en ligne, on souhaite savoir l'ordre de la ligne. -> Pour l'instant il est à zero systématiquement dans les pages migrées.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  row_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_rows: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface BookmarkActivityClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * Number of POIs contained by activity
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  poi_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Number of tags contained by activity
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  tag_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface BudgetAdjustmentQuestionAnsweredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * ID de la réponse à une des questions de la discussion entre Evaneos et le traveler juste après la creation de sa request.
   */
  id_message: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Tells us in which of the 3 possible path of the budget experiment the user is in (no budget / budget ok / budget not ok)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Calendly, BC, BNC, BNR |
   */
  start_path: "Calendly" | "BC" | "BNC" | "BNR";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface BudgetAdjustmentQuestionDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Tells us in which of the 3 possible path of the budget experiment the user is in (no budget / budget ok / budget not ok)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Calendly, BC, BNC, BNR |
   */
  start_path: "Calendly" | "BC" | "BNC" | "BNR";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface BudgetCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Réponse à l'étape profil du TF qui indique si le user connait les dates fixes de son voyage.
   */
  known_date: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * Réponse étape profil du TF : {alone | couple | family | friends | group}
   *
   * \-> Les adjonctions à "famille" correspondent aux champs profile de where to go
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | alone, couple, family, friends, group, family_babies, family_kids, family_teen |
   */
  profile: "alone" | "couple" | "family" | "friends" | "group" | "family_babies" | "family_kids" | "family_teen";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
  /**
   * Devise du voyage
   *
   *
   *
   */
  user_budget_currency: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  user_budget_value: number;
}

export interface BudgetMinOrMaxUsedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface BudgetPopinSuggestionClosedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface BudgetPreferenceQuestionAnsweredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * ID de la réponse à une des questions de la discussion entre Evaneos et le traveler juste après la creation de sa request.
   */
  id_message: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Tells us in which of the 3 possible path of the budget experiment the user is in (no budget / budget ok / budget not ok)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Calendly, BC, BNC, BNR |
   */
  start_path: "Calendly" | "BC" | "BNC" | "BNR";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface BudgetPreferenceQuestionDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Tells us in which of the 3 possible path of the budget experiment the user is in (no budget / budget ok / budget not ok)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Calendly, BC, BNC, BNR |
   */
  start_path: "Calendly" | "BC" | "BNC" | "BNR";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface BudgetRangeQuestionAnsweredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * ID de la réponse à une des questions de la discussion entre Evaneos et le traveler juste après la creation de sa request.
   */
  id_message: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Tells us in which of the 3 possible path of the budget experiment the user is in (no budget / budget ok / budget not ok)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Calendly, BC, BNC, BNR |
   */
  start_path: "Calendly" | "BC" | "BNC" | "BNR";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface BudgetRulerUsedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface BudgetSuggestionClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Réponse à l'étape profil du TF qui indique si le user connait les dates fixes de son voyage.
   */
  known_date: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * Réponse étape profil du TF : {alone | couple | family | friends | group}
   *
   * \-> Les adjonctions à "famille" correspondent aux champs profile de where to go
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | alone, couple, family, friends, group, family_babies, family_kids, family_teen |
   */
  profile: "alone" | "couple" | "family" | "friends" | "group" | "family_babies" | "family_kids" | "family_teen";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface CalendlyClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  /**
   * local agent id
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  agent_id?: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface CalendlyDisplayedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface CalendlyMeetingBookedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  /**
   * local agent id
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  agent_id?: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface CbPaymentStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface CbPaymentStepFailedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  failure_reasons?: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface CbPaymentSubmittedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  cb_type: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ContinentFilteredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Valeur issue de l'info ici :
   *
   *
   *
   */
  continent: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  unfiltered: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface CtaInspirezMoiClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface DeleteActivityClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * Number of POIs contained by activity
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  poi_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Number of tags contained by activity
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  tag_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface DestinationArticleClickedProperties {
  /**
   * Type de l'article.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | general_inspiration, general_practical, food, history, formalities, guide, transportation, budget, security, souvenir, courtesy, tip, wild, arts, honeymoon, personalities, religion, geography, to_see, disability, other, eco_tourism, events, culture, language, economy |
   */
  article_type:
    | "general_inspiration"
    | "general_practical"
    | "food"
    | "history"
    | "formalities"
    | "guide"
    | "transportation"
    | "budget"
    | "security"
    | "souvenir"
    | "courtesy"
    | "tip"
    | "wild"
    | "arts"
    | "honeymoon"
    | "personalities"
    | "religion"
    | "geography"
    | "to_see"
    | "disability"
    | "other"
    | "eco_tourism"
    | "events"
    | "culture"
    | "language"
    | "economy";
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface DestinationArticleListClickedProperties {
  /**
   * Type de l'article.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | general_inspiration, general_practical, food, history, formalities, guide, transportation, budget, security, souvenir, courtesy, tip, wild, arts, honeymoon, personalities, religion, geography, to_see, disability, other, eco_tourism, events, culture, language, economy |
   */
  article_type:
    | "general_inspiration"
    | "general_practical"
    | "food"
    | "history"
    | "formalities"
    | "guide"
    | "transportation"
    | "budget"
    | "security"
    | "souvenir"
    | "courtesy"
    | "tip"
    | "wild"
    | "arts"
    | "honeymoon"
    | "personalities"
    | "religion"
    | "geography"
    | "to_see"
    | "disability"
    | "other"
    | "eco_tourism"
    | "events"
    | "culture"
    | "language"
    | "economy";
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface DestinationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  /**
   * Json qui indique les filtres présents dans where-to-go
   *
   * Format:
   *
   * ```
   * {"continent": [], "needs": [], "period": []}
   * ```
   */
  filter_where_to_go?: {
    continent?: any[];
    needs?: any[];
    period?: any[];
  };
  has_social_proof_banner?: boolean;
  is_accessible_by_train?: boolean;
  path: string;
  /**
   * La tranche de budget à laquelle appartient l'item clické dans les résultats du POC Where To Go
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | economic, luxurious, moderate |
   */
  poc_wheretogo_budget_type?: "economic" | "luxurious" | "moderate";
  poc_wheretogo_has_experiences?: boolean;
  poc_wheretogo_has_social_proof_banner?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Lorsqu'il y a un affichage en ligne, on souhaite savoir l'ordre de la ligne.
   *
   * Début à 1.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  row_number?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_rows?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface DestinationDiscoveryClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface DestinationVisaClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface DestinationsGuideDownloadedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface Et1DisplayedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Tells us in which of the 3 possible path of the budget experiment the user is in (no budget / budget ok / budget not ok)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Calendly, BC, BNC, BNR |
   */
  start_path: "Calendly" | "BC" | "BNC" | "BNR";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ExitStoryClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Correspond au numéro de la story sur lequel est le user.
   *  Exemple, il scroll sur la première story, la valeur retournée est 1.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  story_step: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface FacetClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * ID de la facet (uid) ex: **6409b6e0dcf679a9bc08c613**
   */
  facet_id?: string;
  /**
   * Les thématiques des facets sont regroupées dans différentes catégories qui correspondent à cette propriété. Côté code, cela correspond à `facet.filter.name` A date, voici les correspondances
   *
   *
   *
   *
   *
   *
   *
   *
   */
  facet_tag_category: string;
  /**
   * Tag de la facet cliquée (ex: nature, bike, summer...)
   *
   * Côté code, cela correspond à `facet.filter.value`
   *
   * Sur l'event Itinerary clicked, c'était utilisé quand on avait la catégorisation par facet sur la page desti donc la propriété est en optionnel sur cet event comment l'affichage est de nouveau en grille.
   */
  facet_tag_name: string;
  /**
   * Permet de savoir si l'action réalisée avait des ugc (true) ou non (false)
   */
  has_ugc?: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Lorsqu'il y a un affichage en ligne, on souhaite savoir l'ordre de la ligne. -> Pour l'instant il est à zero systématiquement dans les pages migrées.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  row_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface FakeExplorerClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface FakeExplorerExitClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface FakeMapClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface FamilyWithBabiesFilterSelectedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface FilterClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * ID de la facet (uid) ex: **6409b6e0dcf679a9bc08c613**
   */
  facet_id?: string;
  /**
   * Lorsque le user clique sur une des filtres, cette proprioété permet de savoir le nb d'itinéraires disponibles.
   *  Utile car l'expérience peut différer en fonction
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_itineraries_available: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  tags: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface FlightPriceMonthSelectedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | January, February, March, April, May, June, July, August, September, October, November, December |
   */
  month:
    | "January"
    | "February"
    | "March"
    | "April"
    | "May"
    | "June"
    | "July"
    | "August"
    | "September"
    | "October"
    | "November"
    | "December";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderAllDestinationsClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderBCorpClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderCarbonNeutralClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderCommitmentsClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderConceptClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderContinentClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Valeur issue de l'info ici :
   *
   *
   *
   */
  continent: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderDestinationWheretogoClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Valeur issue de l'info ici :
   *
   *
   *
   */
  continent: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderHelpCenterClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderHowThematicClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Parfois plusieurs events sont déclenchés alors que le user clique sur différents éléments (comme dans le header par ex) donc il faut pouvoir distinguer les liens cliqués. Cette prop a pour but de savoir lequel est cliqué
   *
   * Cette propriété est differente de `thematic_name`
   *
   * Page Thematic Famille : [https://www.evaneos.fr/ou\\-partir/voyage\\-en\\-famille/](https://www.evaneos.fr/ou%5C-partir/voyage%5C-en%5C-famille/) => slug = `voyage\-en\-famille` (va être traduit dans chaque langue / marché)
   *
   * Cette propriété est maintenant remplacée par **facet_tag_name** sur l'event Viewed page Facet car c'était un naming différent pour la même chose.
   */
  thematic_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderImpactReportClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderLogoClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderMagazineClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderOtherDestinationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Valeur issue de l'info ici :
   *
   *
   *
   */
  continent: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  destination_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderOurImpactClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderRecommendationClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderTopDestinationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Valeur issue de l'info ici :
   *
   *
   *
   */
  continent: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  destination_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderWhatThematicClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Parfois plusieurs events sont déclenchés alors que le user clique sur différents éléments (comme dans le header par ex) donc il faut pouvoir distinguer les liens cliqués. Cette prop a pour but de savoir lequel est cliqué
   *
   * Cette propriété est differente de `thematic_name`
   *
   * Page Thematic Famille : [https://www.evaneos.fr/ou\\-partir/voyage\\-en\\-famille/](https://www.evaneos.fr/ou%5C-partir/voyage%5C-en%5C-famille/) => slug = `voyage\-en\-famille` (va être traduit dans chaque langue / marché)
   *
   * Cette propriété est maintenant remplacée par **facet_tag_name** sur l'event Viewed page Facet car c'était un naming différent pour la même chose.
   */
  thematic_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderWhenThematicClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Parfois plusieurs events sont déclenchés alors que le user clique sur différents éléments (comme dans le header par ex) donc il faut pouvoir distinguer les liens cliqués. Cette prop a pour but de savoir lequel est cliqué
   *
   * Cette propriété est differente de `thematic_name`
   *
   * Page Thematic Famille : [https://www.evaneos.fr/ou\\-partir/voyage\\-en\\-famille/](https://www.evaneos.fr/ou%5C-partir/voyage%5C-en%5C-famille/) => slug = `voyage\-en\-famille` (va être traduit dans chaque langue / marché)
   *
   * Cette propriété est maintenant remplacée par **facet_tag_name** sur l'event Viewed page Facet car c'était un naming différent pour la même chose.
   */
  thematic_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderWheretogoClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HeaderWhoThematicClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Parfois plusieurs events sont déclenchés alors que le user clique sur différents éléments (comme dans le header par ex) donc il faut pouvoir distinguer les liens cliqués. Cette prop a pour but de savoir lequel est cliqué
   *
   * Cette propriété est differente de `thematic_name`
   *
   * Page Thematic Famille : [https://www.evaneos.fr/ou\\-partir/voyage\\-en\\-famille/](https://www.evaneos.fr/ou%5C-partir/voyage%5C-en%5C-famille/) => slug = `voyage\-en\-famille` (va être traduit dans chaque langue / marché)
   *
   * Cette propriété est maintenant remplacée par **facet_tag_name** sur l'event Viewed page Facet car c'était un naming différent pour la même chose.
   */
  thematic_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HelpCenterClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HelpQuestionCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  need_help: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HelpSuggestionQuestionCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | map, chatbot, quiz |
   */
  help_type: "map" | "chatbot" | "quiz";
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  need_help: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface HubClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  hub_name: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
  /**
   * URL de redirection du CTA Exemple sur ces deux CTA dont les URL de redirection peuvent être administrés par les équipes Métier
   *
   *
   *
   */
  url_redirection: string;
}

export interface ImpactReportClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface InformationStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  /**
   * Distingue si il est possible d'offrir un choix d'assurance au voyageur pour cette échéance. False si il ne s'agit pas d'un premier paiement et/ou si la devise voyageur est de l'EUR
   */
  is_insurance_eligible: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface InsuranceStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | insurance_refused, no_appropriate_insurance_available, cancellation, multiple-risk |
   */
  payment_insurance_selected:
    | "insurance_refused"
    | "no_appropriate_insurance_available"
    | "cancellation"
    | "multiple-risk";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface InsuranceStepMoreDetailsButtonClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  /**
   * Multi-risk, cancellation or no insurance
   */
  insurance_type: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface InsuranceStepOtherOffersClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface InsuranceStepProductCancellationClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface InsuranceStepProductMultipleRisksClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface InsuranceStepProductPremiumAssistanceClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface InsuranceStepRefusalClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface InternationalFlightHelpCenterClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * ID de la facet (uid) ex: **6409b6e0dcf679a9bc08c613**
   */
  facet_id?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ItinerariesListClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ItineraryClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Correspond à la durée proposée d'un Iti
   */
  duration: string;
  /**
   * Tag de la facet cliquée (ex: nature, bike, summer...)
   *
   * Côté code, cela correspond à `facet.filter.value`
   *
   * Sur l'event Itinerary clicked, c'était utilisé quand on avait la catégorisation par facet sur la page desti donc la propriété est en optionnel sur cet event comment l'affichage est de nouveau en grille.
   */
  facet_tag_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  /**
   * Nombre de cartes itinéraire vues lors de la consultation de la liste itinéraire dans la step offer du TF pour les hesitating avant que le user clique l’aperçu d’un circuit ou sur passer l'étape. On considère que le user a vu une carte itinéraire lorsque il lui a été affiché le CTA “Apercu du circuit”. (Experimentation TF hesitating Oct. 24)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_cards_iti_viewed?: number;
  path: string;
  /**
   * Segment name selected
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | bike, hike, boat, train, car |
   */
  poc_wheretogo_segment?: "bike" | "hike" | "boat" | "train" | "car";
  /**
   * Correspond au prix proposé d'un Iti
   */
  price: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Lorsqu'il y a un affichage en ligne, on souhaite savoir l'ordre de la ligne. -> Pour l'instant il est à zero systématiquement dans les pages migrées.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  row_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  slug?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  tags: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_rows?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ItineraryDetailsClickedProperties {
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Permet de déterminer si un toogle a été ouvert (TRUE) ou fermé (FALSE)
   */
  is_open: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ItineraryStepClickProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Give the ID of the step in an itinerary
   *
   *
   *
   */
  itinerary_step_id: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ItineraryWishlistAddedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ListActivityOnboardingSkipClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Number of the step of the onboarding process
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  onboarding_step: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ListActivityOnboardingStartClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ListActivityOnboardingStep1ViewedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ListActivityOnboardingStep2ViewedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ListActivityOnboardingStep3ViewedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ListItinerariesFilterClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Name of the filter clicked
   *
   *
   *
   */
  filter_name: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ListItinerariesFilterModalClosedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ListItinerariesFilterSelectedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Name of the filter clicked
   *
   *
   *
   */
  filter_name: string;
  /**
   * Value of the filter selected
   *
   *
   *
   */
  filter_value: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MagazineClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MapDraggedOrDroppedProperties {
  "[Amplitude] Session Recorded"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MapZoomedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MarketingDestinationDetailsCtaClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Lorsqu'il y a un affichage en ligne, on souhaite savoir l'ordre de la ligne. -> Pour l'instant il est à zero systématiquement dans les pages migrées.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  row_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
  /**
   * URL de redirection du CTA Exemple sur ces deux CTA dont les URL de redirection peuvent être administrés par les équipes Métier
   *
   *
   *
   */
  url_redirection: string;
}

export interface MarketingTopDestinationClickedProperties {
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
  /**
   * URL de redirection du CTA Exemple sur ces deux CTA dont les URL de redirection peuvent être administrés par les équipes Métier
   *
   *
   *
   */
  url_redirection: string;
}

export interface MaturityQuestionCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  need_help: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ModalRedirectionRecoPageClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ModalRedirectionRecoPageClosedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ModalRedirectionRecoPageDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ModalSiteSuggestionClosedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ModalSiteSuggestionDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MoreDetailsClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MoreItinerariesClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * ID de la facet (uid) ex: **6409b6e0dcf679a9bc08c613**
   */
  facet_id?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MorePhotoClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MoreTestimonialsClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripAgencyDiscussionClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * This property identify the page on which the event was launched.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | account-trip-need-help, account-trip-about-the-agency, account-trip-payments, account-trip-messages, account-trip-shared-documents, account-trip-travellers, account-trip-partners |
   */
  from_page:
    | "account-trip-need-help"
    | "account-trip-about-the-agency"
    | "account-trip-payments"
    | "account-trip-messages"
    | "account-trip-shared-documents"
    | "account-trip-travellers"
    | "account-trip-partners";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripAgencyInformationClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripAppDownloadClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | ios|android |
   */
  app_system: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripAskAgentClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripAskAgentClosedClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripAskAgentSentClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripAssistantClosedClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripAssistantOpenedClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripCalendlyFormSubmittedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripCallButtonClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripCoTravelerInvitedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripEmailClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripEvaneosNumberCalledProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripExploreFlightsClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripFlightClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripFlightInformationClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripHelpCenterClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * This property identify the page on which the event was launched.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | account-trip-need-help, account-trip-about-the-agency, account-trip-payments, account-trip-messages, account-trip-shared-documents, account-trip-travellers, account-trip-partners |
   */
  from_page:
    | "account-trip-need-help"
    | "account-trip-about-the-agency"
    | "account-trip-payments"
    | "account-trip-messages"
    | "account-trip-shared-documents"
    | "account-trip-travellers"
    | "account-trip-partners";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripIUnderstandClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripInsuranceInformationClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripMessageSentProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripMoreDetailsClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripNoFlightClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripNonUsefulClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripPaymentScheduleClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripScheduleAppointmentClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripSelectionProcessClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripSendEmailClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripSendInfoClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripSendInfoClosedClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripSendInfoSentClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripSharedDocumentDownloadedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Catégorie de la pièce jointe dans Esper
   */
  document_category: string;
  /**
   * Macro catégorie de la pièce jointe.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | (before trip|in trip|legacy) |
   */
  document_group: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripSharedDocumentViewedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Catégorie de la pièce jointe dans Esper
   */
  document_category: string;
  /**
   * Macro catégorie de la pièce jointe.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | (before trip|in trip|legacy) |
   */
  document_group: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripTestimonialsClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripUsefulClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripVisaInformationClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  trip_status: string;
}

export interface MyTripWifiInformationClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripActivityPlaceholderClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  activities_count: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripButtonDisplayedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  activities_count: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface MyTripsClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * ID de la réponse à une des questions de la discussion entre Evaneos et le traveler juste après la creation de sa request.
   */
  id_message: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarAgenciesClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarDestinationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarExperienceClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarFacetClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * ID de la facet (uid) ex: **6409b6e0dcf679a9bc08c613**
   */
  facet_id?: string;
  /**
   * Correspond à la fin de l'url donc le slug de
   *
   * >  /costa-rica/circuits-en-famille
   *
   * est
   *
   * > en-famille
   */
  facet_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Parfois plusieurs events sont déclenchés alors que le user clique sur différents éléments (comme dans le header par ex) donc il faut pouvoir distinguer les liens cliqués. Cette prop a pour but de savoir lequel est cliqué
   *
   * Cette propriété est differente de `thematic_name`
   *
   * Page Thematic Famille : [https://www.evaneos.fr/ou\\-partir/voyage\\-en\\-famille/](https://www.evaneos.fr/ou%5C-partir/voyage%5C-en%5C-famille/) => slug = `voyage\-en\-famille` (va être traduit dans chaque langue / marché)
   *
   * Cette propriété est maintenant remplacée par **facet_tag_name** sur l'event Viewed page Facet car c'était un naming différent pour la même chose.
   */
  thematic_slug?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarGroupClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarInspirationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarListItinerariesClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarPlacesClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarPracticalDetailsClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarRegionClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarTestimonialClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarTravelGuideClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarItiBudgetClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarItiCircuitClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarItiDetailsClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NavbarItiTransportClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NeedsFilteredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  needs: ("relax" | "sport" | "culture" | "any" | "discovery")[];
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  unfiltered: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NewsletterSubscribedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NextCarouselClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Permet de savoir si l'action réalisée avait des ugc (true) ou non (false)
   */
  has_ugc?: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NextStoryClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Correspond au numéro de la story sur lequel est le user.
   *  Exemple, il scroll sur la première story, la valeur retournée est 1.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  story_step: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface NonUsefulButtonClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface OpinionPoiSearchedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface OpinionPoiTestimonialPublishedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PageSharedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * Type de partage (FB, twitter, whatsapp, lien, email ou impressison) d'une page
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | facebook, twitter, whatsapp, link, email, print |
   */
  sharing_type: "facebook" | "twitter" | "whatsapp" | "link" | "email" | "print";
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PaginationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Permet de distinguer le type de clics quand il y a plusieurs façon pour une seule action.
   *  Exemple sur la page Facet avec deux façons d'accéder aux témoignages
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | stars, button, card, link, previous, next, number, background, cross |
   */
  click_type: "stars" | "button" | "card" | "link" | "previous" | "next" | "number" | "background" | "cross";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  page_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PartnershipClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
  /**
   * URL de redirection du CTA Exemple sur ces deux CTA dont les URL de redirection peuvent être administrés par les équipes Métier
   *
   *
   *
   */
  url_redirection: string;
}

export interface PathfinderAccommodationsDefinedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderAccommodationsQuestionAskedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderActivitiesDefinedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderActivitiesQuestionAskedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderAdviceAskedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_question_tag: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderAdviceGivenProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
}

export interface PathfinderAgencyPresentationDisplayedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderAlternativeDestinationSelectedProperties {
  pathfinder_alternative_destination_slug: string;
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderAutonomousBannerDownloadClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PathfinderAutonomousSurveyAnswerSelectedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | local_agency, mixed, self |
   */
  pathfinder_autonomous_survey_answer: "local_agency" | "mixed" | "self";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PathfinderBudgetAlternativesDisplayedProperties {
  pathfinder_budget_alternatives_asked: boolean;
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderBudgetComponentDisplayedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderBudgetConfirmedProperties {
  pathfinder_budget_below_minimum: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | flexible, fixed |
   */
  pathfinder_budget_type: "flexible" | "fixed";
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderBudgetDefinedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderBudgetQuestionAskedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderBudgetTypeSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | flexible, fixed |
   */
  pathfinder_budget_type: "flexible" | "fixed";
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderBudgetUnknownSelectedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderContactAgentSelectedProperties {
  pathfinder_destination_slug?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_experiences_selected_count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_experiences_viewed_count: number;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderDatesDefinedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderDestinationPlanTripClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PathfinderDurationDefinedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderExperiencesRecommendedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderExperiencesSelectedProperties {
  pathfinder_destination_slug?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_experiences_selected_count: number;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderFirstMessageSentProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
}

export interface PathfinderGroupCompositionQuestionAskedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderGroupDefinedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderHasMinimumBudgetSelectedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderInspirationMessageSentProperties {
  pathfinder_destination_slug?: string;
  pathfinder_inspiration_input: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderInspirationSurveyAnsweredProperties {
  pathfinder_destination_slug?: string;
  pathfinder_inspiration_survey_answer: string;
}

export interface PathfinderItineraryCommentedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_itinerary_comment: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderItineraryViewedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderMessageSentProperties {
  pathfinder_destination_slug?: string;
  /**
   * Number of characters in the message sent
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  pathfinder_sent_message_length: number;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderNavInsightsIntentsProperties {
  pathfinder_destination_slug?: string;
  pathfinder_nav_insights_description: string;
  pathfinder_nav_insights_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_nav_insights_page_count: number;
  pathfinder_thread_id: string;
}

export interface PathfinderNoAlternativeDestinationSelectedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderNoAlternativeSelectedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderOnboardingQuestionAnsweredProperties {
  pathfinder_destination_slug?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, planning |
   */
  pathfinder_onboarding_answer: "inspiration" | "planning";
  pathfinder_thread_id: string;
}

export interface PathfinderProjectInvalidatedProperties {
  pathfinder_activities_evaluation: boolean;
  pathfinder_budget_evaluation: boolean;
  pathfinder_destination_slug?: string;
  pathfinder_global_evaluation: boolean;
  pathfinder_last_minute_evaluation: boolean;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderProjectValidatedProperties {
  pathfinder_activities_evaluation: boolean;
  pathfinder_budget_evaluation: boolean;
  pathfinder_destination_slug?: string;
  pathfinder_global_evaluation: boolean;
  pathfinder_last_minute_evaluation: boolean;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderProjectValidationDisplayedProperties {
  pathfinder_activities_evaluation: boolean;
  pathfinder_budget_evaluation: boolean;
  pathfinder_destination_slug?: string;
  pathfinder_global_evaluation: boolean;
  pathfinder_last_minute_evaluation: boolean;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderRequestSentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  pathfinder_activities: string[];
  pathfinder_activities_evaluation: boolean;
  pathfinder_budget_evaluation: boolean;
  /**
   * The total fixed budget provided by the user.
   *
   * It is a string but we always provide a number.
   *
   *
   * Example: 5000
   */
  pathfinder_budget_fixed?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_budget_per_person?: number;
  /**
   * The total budget range of provided by the user.
   *
   * This a string containing the lower bound and upper bound of the range budget. Separated by a comma.
   *
   *  Example: 5000,6000
   */
  pathfinder_budget_range?: string;
  pathfinder_destination_slug?: string;
  pathfinder_global_evaluation: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_group_composition_adults: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_group_composition_babies: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_group_composition_kids: number;
  pathfinder_last_minute_evaluation: boolean;
  /**
   * The ID of the request generated at the end of the experience.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_request_id: number;
  pathfinder_thread_id: string;
  pathfinder_travel_dates: string;
  /**
   * in days
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  pathfinder_travel_duration: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderTravelDatesQuestionAskedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderTravelDurationQuestionAskedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderViewAlternativeDestinationsSelectedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderViewBudgetAlternativesSelectedProperties {
  pathfinder_destination_slug?: string;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderViewItinerarySelectedProperties {
  pathfinder_destination_slug?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_experiences_selected_count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_experiences_viewed_count: number;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PathfinderViewOtherExperiencesSelectedProperties {
  pathfinder_destination_slug?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_experiences_selected_count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_experiences_viewed_count: number;
  pathfinder_thread_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pathfinder_user_message_count: number;
}

export interface PayButtonOnPaymentScheduleClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  instalment_id: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PaymentInformationStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  /**
   * In the payment funnel, this property represents the payment type selected by the customer:
   *
   */
  payment_type_selected: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PaymentInformationStepFailedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  payment_form_error: string;
  /**
   * In the payment funnel, this property represents the payment type selected by the customer:
   *
   */
  payment_type_selected: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PaymentTypeChangedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  /**
   * In the payment funnel, this property represents the payment type selected by the customer:
   *
   */
  payment_type_selected: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PeriodFilteredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | January, February, March, April, May, June, July, August, September, October, November, December |
   */
  month:
    | "January"
    | "February"
    | "March"
    | "April"
    | "May"
    | "June"
    | "July"
    | "August"
    | "September"
    | "October"
    | "November"
    | "December";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PersonalInformationAdviceEmailsClickedProperties {
  "[Amplitude] Session Recorded"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | optin|optout |
   */
  email_status: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PersonalInformationInformationRegisteredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PersonalInformationNewsletterEmailsClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | optin|optout |
   */
  email_status: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PersonalInformationPasswordRegistredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PersonalInformationSmsNotificationsClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | optin|optout |
   */
  status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PoiClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Permet de distinguer le type de clics quand il y a plusieurs façon pour une seule action.
   *  Exemple sur la page Facet avec deux façons d'accéder aux témoignages
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | stars, button, card, link, previous, next, number, background, cross |
   */
  click_type: "stars" | "button" | "card" | "link" | "previous" | "next" | "number" | "background" | "cross";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PoiClusterClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PoiListClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PopInLoginDisplayedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PopinDetailsItineraryClosedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PopinInfosDestinationClosedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PopinNewsletterClosedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | exit, three_page_views |
   */
  origin: "exit" | "three_page_views";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PopinNewsletterDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | exit, three_page_views |
   */
  origin: "exit" | "three_page_views";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PopinSaveActivitiesClosedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PopinSaveActivitiesDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * cross_click : croix du tripform
   *
   * window_exit : sortie de la fenêtre du navigateur
   *
   * tab_exit : changement d'onglet sur mobile
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | exit, inactivity, cross_click, window_exit, tab_exit |
   */
  popin_trigger: "exit" | "inactivity" | "cross_click" | "window_exit" | "tab_exit";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PracticalInformationClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Permet de déterminer si un toogle a été ouvert (TRUE) ou fermé (FALSE)
   */
  is_open: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | formality, addresses, essential, general_information |
   */
  practical_information_thematic: "formality" | "addresses" | "essential" | "general_information";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PracticalInformationDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PreferencesChoiceViewedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PreferencesClosedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PreferencesClosedWithoutSaveProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PreferencesUpdatedProperties {
  budget_updated: boolean;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  climate_updated: boolean;
  destination_updated: boolean;
  distance_updated: boolean;
  duration_updated: boolean;
  path: string;
  period_updated: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PreviousStoryClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Correspond au numéro de la story sur lequel est le user.
   *  Exemple, il scroll sur la première story, la valeur retournée est 1.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  story_step: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavAccountClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavAgenciesListClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavAllDestinationsClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavBCorpClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavCarbonNeutralClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavCommitmentsClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavConceptClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavContinentClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Valeur issue de l'info ici :
   *
   *
   *
   */
  continent: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavContinentSelectedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Valeur issue de l'info ici :
   *
   *
   *
   */
  continent: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavDestinationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Permet de distinguer le type de clics quand il y a plusieurs façon pour une seule action.
   *  Exemple sur la page Facet avec deux façons d'accéder aux témoignages
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | stars, button, card, link, previous, next, number, background, cross |
   */
  click_type: "stars" | "button" | "card" | "link" | "previous" | "next" | "number" | "background" | "cross";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavDestinationRecommendationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Valeur issue de l'info ici :
   *
   *
   *
   */
  continent: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavDestinationWheretogoClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Valeur issue de l'info ici :
   *
   *
   *
   */
  continent: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavExploreClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavFavoriteClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavHelpCenterClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavHubDestinationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavImpactReportClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavItinerariesListClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavLogoClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavLogoutClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavMagazineClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavOtherDestinationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Valeur issue de l'info ici :
   *
   *
   *
   */
  continent: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavOurImpactClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavPersonalInfoClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavPoiListClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavPreviousStepClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavRecommendationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavRegionClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Indique l'ID  d'une région
   *
   * Le region_id est différent de la destination_id
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  region_id: number;
  /**
   * Indique le slugname d'une région (ex : Cinque Terre)
   *
   * Pour un Hub région (= une destination de type région), alors il n'y a pas de region_slug mais un destination_slug
   */
  region_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavTestimonialClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type: "region" | "country";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavThematicClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Permet de distinguer le type de clics quand il y a plusieurs façon pour une seule action.
   *  Exemple sur la page Facet avec deux façons d'accéder aux témoignages
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | stars, button, card, link, previous, next, number, background, cross |
   */
  click_type: "stars" | "button" | "card" | "link" | "previous" | "next" | "number" | "background" | "cross";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  column_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Lorsqu'il y a un affichage en ligne, on souhaite savoir l'ordre de la ligne. -> Pour l'instant il est à zero systématiquement dans les pages migrées.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  row_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | who, when, what, how_long |
   */
  thematic_group: "who" | "when" | "what" | "how_long";
  /**
   * Parfois plusieurs events sont déclenchés alors que le user clique sur différents éléments (comme dans le header par ex) donc il faut pouvoir distinguer les liens cliqués. Cette prop a pour but de savoir lequel est cliqué
   *
   * Cette propriété est differente de `thematic_name`
   *
   * Page Thematic Famille : [https://www.evaneos.fr/ou\\-partir/voyage\\-en\\-famille/](https://www.evaneos.fr/ou%5C-partir/voyage%5C-en%5C-famille/) => slug = `voyage\-en\-famille` (va être traduit dans chaque langue / marché)
   *
   * Cette propriété est maintenant remplacée par **facet_tag_name** sur l'event Viewed page Facet car c'était un naming différent pour la même chose.
   */
  thematic_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavThematicSelectedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Lorsqu'il y a un affichage en ligne, on souhaite savoir l'ordre de la ligne. -> Pour l'instant il est à zero systématiquement dans les pages migrées.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  row_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | who, when, what, how_long |
   */
  thematic_group: "who" | "when" | "what" | "how_long";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavTopDestinationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Valeur issue de l'info ici :
   *
   *
   *
   */
  continent: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavTravelGuideClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type: "region" | "country";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavTripsListClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavWhentogoClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type: "region" | "country";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PrimaryNavWheretogoClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface PromotionCardClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * Type of the promotion card displayed (only one of the two below are actually displayed).
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | save_activities, sign_up |
   */
  promotion_card_type: "save_activities" | "sign_up";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface QuestionClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Permet de déterminer si un toogle a été ouvert (TRUE) ou fermé (FALSE)
   */
  is_open: boolean;
  path: string;
  /**
   * Permet de faire mapping des questions cross marché
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  question_id: number;
  /**
   * Propriété permettant de distinguer quelle question a été cliquée par le user.
   *
   *
   *
   *
   */
  question_label: string;
  /**
   * Position de la question de la FAQ. Incrément commence à 1 pour la question la plus haute dans la page
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  question_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationModalAgencyClosedProperties {
  /**
   * Type du boutton pour passer l'étape d'offre dans l'hesitating flow au sein du tripform(experimentation Oct.24).
   *
   *
   * background: click en dehors de l'écran / popin /modale pour fermer.
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | CTA, Cross, Navbar, background |
   */
  button_type: "CTA" | "Cross" | "Navbar" | "background";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * in a sequence of difference steps in modal, allows to know the name of the step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, support, agency |
   */
  modal_step_name: "inspiration" | "destination" | "support" | "agency";
  /**
   * in a sequence of difference steps in modal, allows to know which step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  modal_step_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationModalAgencyDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * in a sequence of difference steps in modal, allows to know the name of the step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, support, agency |
   */
  modal_step_name: "inspiration" | "destination" | "support" | "agency";
  /**
   * in a sequence of difference steps in modal, allows to know which step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  modal_step_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationModalClosedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Permet de distinguer le type de clics quand il y a plusieurs façon pour une seule action.
   *  Exemple sur la page Facet avec deux façons d'accéder aux témoignages
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | stars, button, card, link, previous, next, number, background, cross |
   */
  click_type: "stars" | "button" | "card" | "link" | "previous" | "next" | "number" | "background" | "cross";
  /**
   * in a sequence of difference steps in modal, allows to know the name of the step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, support, agency |
   */
  modal_step_name: "inspiration" | "destination" | "support" | "agency";
  /**
   * in a sequence of difference steps in modal, allows to know which step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  modal_step_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationModalDestinationClosedProperties {
  /**
   * Type du boutton pour passer l'étape d'offre dans l'hesitating flow au sein du tripform(experimentation Oct.24).
   *
   *
   * background: click en dehors de l'écran / popin /modale pour fermer.
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | CTA, Cross, Navbar, background |
   */
  button_type: "CTA" | "Cross" | "Navbar" | "background";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * in a sequence of difference steps in modal, allows to know the name of the step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, support, agency |
   */
  modal_step_name: "inspiration" | "destination" | "support" | "agency";
  /**
   * in a sequence of difference steps in modal, allows to know which step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  modal_step_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationModalDestinationDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * in a sequence of difference steps in modal, allows to know the name of the step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, support, agency |
   */
  modal_step_name: "inspiration" | "destination" | "support" | "agency";
  /**
   * in a sequence of difference steps in modal, allows to know which step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  modal_step_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationModalDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * in a sequence of difference steps in modal, allows to know the name of the step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, support, agency |
   */
  modal_step_name: "inspiration" | "destination" | "support" | "agency";
  /**
   * in a sequence of difference steps in modal, allows to know which step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  modal_step_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationModalInspirationClosedProperties {
  /**
   * Type du boutton pour passer l'étape d'offre dans l'hesitating flow au sein du tripform(experimentation Oct.24).
   *
   *
   * background: click en dehors de l'écran / popin /modale pour fermer.
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | CTA, Cross, Navbar, background |
   */
  button_type: "CTA" | "Cross" | "Navbar" | "background";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * in a sequence of difference steps in modal, allows to know the name of the step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, support, agency |
   */
  modal_step_name: "inspiration" | "destination" | "support" | "agency";
  /**
   * in a sequence of difference steps in modal, allows to know which step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  modal_step_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationModalInspirationDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * in a sequence of difference steps in modal, allows to know the name of the step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, support, agency |
   */
  modal_step_name: "inspiration" | "destination" | "support" | "agency";
  /**
   * in a sequence of difference steps in modal, allows to know which step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  modal_step_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationModalNextClickedProperties {
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Allows us to understand the choice made by the user in the recommendation modal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, information, activity, agency, agency_reco, agency_choice, non_applicable |
   */
  modal_reco_choice:
    | "inspiration"
    | "destination"
    | "information"
    | "activity"
    | "agency"
    | "agency_reco"
    | "agency_choice"
    | "non_applicable";
  /**
   * in a sequence of difference steps in modal, allows to know the name of the step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, support, agency |
   */
  modal_step_name: "inspiration" | "destination" | "support" | "agency";
  /**
   * in a sequence of difference steps in modal, allows to know which step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  modal_step_number: number;
  path: string;
  /**
   * Allows us to understand the choice made by the user in the recommendation modal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, information, activity, agency, agency_reco, agency_choice, non_applicable |
   */
  reco_choice?:
    | "inspiration"
    | "destination"
    | "information"
    | "activity"
    | "agency"
    | "agency_reco"
    | "agency_choice"
    | "non_applicable";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationModalPreviousClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * in a sequence of difference steps in modal, allows to know the name of the step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, support, agency |
   */
  modal_step_name: "inspiration" | "destination" | "support" | "agency";
  /**
   * in a sequence of difference steps in modal, allows to know which step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  modal_step_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationModalSupportClosedProperties {
  /**
   * Type du boutton pour passer l'étape d'offre dans l'hesitating flow au sein du tripform(experimentation Oct.24).
   *
   *
   * background: click en dehors de l'écran / popin /modale pour fermer.
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | CTA, Cross, Navbar, background |
   */
  button_type: "CTA" | "Cross" | "Navbar" | "background";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * in a sequence of difference steps in modal, allows to know the name of the step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, support, agency |
   */
  modal_step_name: "inspiration" | "destination" | "support" | "agency";
  /**
   * in a sequence of difference steps in modal, allows to know which step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  modal_step_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationModalSupportDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * in a sequence of difference steps in modal, allows to know the name of the step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, destination, support, agency |
   */
  modal_step_name: "inspiration" | "destination" | "support" | "agency";
  /**
   * in a sequence of difference steps in modal, allows to know which step we're in
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  modal_step_number: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationPageTeaserClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationPageTeaserClosedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationPageTeaserCtaClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationPageTeaserDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RecommendationsPreferencesClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RegionClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Permet de distinguer le type de clics quand il y a plusieurs façon pour une seule action.
   *  Exemple sur la page Facet avec deux façons d'accéder aux témoignages
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | stars, button, card, link, previous, next, number, background, cross |
   */
  click_type: "stars" | "button" | "card" | "link" | "previous" | "next" | "number" | "background" | "cross";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Indique l'ID  d'une région
   *
   * Le region_id est différent de la destination_id
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  region_id: number;
  /**
   * Indique le slugname d'une région (ex : Cinque Terre)
   *
   * Pour un Hub région (= une destination de type région), alors il n'y a pas de region_slug mais un destination_slug
   */
  region_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RegionFilterClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RegionFilterSelectedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  region: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ReinsuranceClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RequestSentProperties {
  already_existing?: boolean;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  connection_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  is_requalified?: boolean;
  mail_optin_checked?: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  /**
   * Answer to the maturity check question in the hesitating flow in the Tripform (Experiment of Oct 24).
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  maturity_check_answer?: number;
  path: string;
  phone_number_completed?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  quality_cluster?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ISSUE ON QUALITY, MEDIUM QUALITY, TOP QUALITY, BAD QUALITY, HIGH QUALITY |
   */
  quality_grouping: "ISSUE ON QUALITY" | "MEDIUM QUALITY" | "TOP QUALITY" | "BAD QUALITY" | "HIGH QUALITY";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  sms_optin_checked?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  social_login_source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  test_mobile_maturity?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Avant le (date), le trip_form*id était en fait le* request_id. Le naming avait était mal fait
   */
  trip_form_id: string;
  /**
   * Permet de savoir à quelle étape du TF une action a lieu (clic sur le bouton précédent ou envoi du TF)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | trip, information, profile, phone, maturity, budget, personalization, dates, duration, offer, maturity check, hesitating trip, offer pop in iti, list-itineraries, pop-in-iti |
   */
  trip_form_step:
    | "trip"
    | "information"
    | "profile"
    | "phone"
    | "maturity"
    | "budget"
    | "personalization"
    | "dates"
    | "duration"
    | "offer"
    | "maturity check"
    | "hesitating trip"
    | "offer pop in iti"
    | "list-itineraries"
    | "pop-in-iti";
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RhythmQuestionAnsweredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * ID de la réponse à une des questions de la discussion entre Evaneos et le traveler juste après la creation de sa request.
   */
  id_message: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Tells us in which of the 3 possible path of the budget experiment the user is in (no budget / budget ok / budget not ok)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Calendly, BC, BNC, BNR |
   */
  start_path: "Calendly" | "BC" | "BNC" | "BNR";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface RhythmQuestionDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Tells us in which of the 3 possible path of the budget experiment the user is in (no budget / budget ok / budget not ok)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Calendly, BC, BNC, BNR |
   */
  start_path: "Calendly" | "BC" | "BNC" | "BNR";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface SaveMyTripClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface SaveMyTripLaterClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface SearchbarUsedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  button_clicked: boolean;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  scrolling_menu_clicked: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  search_value: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ShowMapClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface SignInButtonClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface SignUpButtonClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Permet de distinguer le type de clics quand il y a plusieurs façon pour une seule action.
   *  Exemple sur la page Facet avec deux façons d'accéder aux témoignages
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | stars, button, card, link, previous, next, number, background, cross |
   */
  click_type: "stars" | "button" | "card" | "link" | "previous" | "next" | "number" | "background" | "cross";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface SiteSuggestionChangedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * La valeur est à true si le user clique sur le choix proposé à gauche et à false si c'est le choix de droite. Quand le user choisit une langue dans le menu déroulant, il faudrait que ça soit pareil, true si c'est la langue proposée et false sinon.
   *  True à gauche
   *  False à droite
   *
   *
   */
  site_suggested: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface SiteSuggestionStayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface StoryStartedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface SuggestionClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Réponse à l'étape profil du TF qui indique si le user connait les dates fixes de son voyage.
   */
  known_date: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * Réponse étape profil du TF : {alone | couple | family | friends | group}
   *
   * \-> Les adjonctions à "famille" correspondent aux champs profile de where to go
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | alone, couple, family, friends, group, family_babies, family_kids, family_teen |
   */
  profile: "alone" | "couple" | "family" | "friends" | "group" | "family_babies" | "family_kids" | "family_teen";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Sur l'AB suggestion de desti/facet, dans le TF, cette propr permet de distinguer si c'esrt une facet ou desti qui est cliquée
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | facet, destination |
   */
  suggestion_card_type: "facet" | "destination";
  /**
   * Pour l'AB sur les suggestions de prix dans le TF, permet de savoir l'id de la desti suggérée cliquée.
   */
  suggestion_destination_slug?: string;
  /**
   * Pour l'AB sur les suggestions de prix dans le TF, permet de savoir l'id de la facet suggérée cliquée.
   */
  suggestion_facet_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface SuitcaseArticleClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface SummaryStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface SurveyStoryAnsweredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Valeur correspondant aux smileys de réponse
   *
   * 1 = pire smiley
   *
   * 5 = smiley très heureux
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   * | Max Value | 5 |
   */
  survey_story_answer: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TeSentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  /**
   * local agent id
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  agent_id?: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Contenu du message envoyé par le traveler à l'agent (test maturty discussion post tripform).
   */
  message_content?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Gives us the rank of the message the user has sent to Evaneos in its discussion with Evaneos right after the creation of his request (1st message, 2nd message ect...)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  te_rank: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TestimonialClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Permet de distinguer le type de clics quand il y a plusieurs façon pour une seule action.
   *  Exemple sur la page Facet avec deux façons d'accéder aux témoignages
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | stars, button, card, link, previous, next, number, background, cross |
   */
  click_type: "stars" | "button" | "card" | "link" | "previous" | "next" | "number" | "background" | "cross";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ThematicClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Parfois plusieurs events sont déclenchés alors que le user clique sur différents éléments (comme dans le header par ex) donc il faut pouvoir distinguer les liens cliqués. Cette prop a pour but de savoir lequel est cliqué
   *
   * Cette propriété est differente de `thematic_name`
   *
   * Page Thematic Famille : [https://www.evaneos.fr/ou\\-partir/voyage\\-en\\-famille/](https://www.evaneos.fr/ou%5C-partir/voyage%5C-en%5C-famille/) => slug = `voyage\-en\-famille` (va être traduit dans chaque langue / marché)
   *
   * Cette propriété est maintenant remplacée par **facet_tag_name** sur l'event Viewed page Facet car c'était un naming différent pour la même chose.
   */
  thematic_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ThematicFilterClickedProperties {
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Correspond au computername (ou name) = `family` (ne change pas, comme un ID 1, 2, 3...) d'une thématique
   */
  thematic_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TipiRecommendChoiceClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * User filled a budget for his trip form
   */
  has_budget: boolean;
  /**
   * User filled a description for his trip form
   */
  has_description: boolean;
  is_filtered: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   * | Max Value | 14 |
   */
  quality_cluster: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ISSUE ON QUALITY, MEDIUM QUALITY, TOP QUALITY, BAD QUALITY, HIGH QUALITY |
   */
  quality_grouping: "ISSUE ON QUALITY" | "MEDIUM QUALITY" | "TOP QUALITY" | "BAD QUALITY" | "HIGH QUALITY";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | first, second, from_scratch |
   */
  tipi_recommendation_choice: "first" | "second" | "from_scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Avant le (date), le trip_form*id était en fait le* request_id. Le naming avait était mal fait
   */
  trip_form_id: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TipiRecommendChoiceSubmittedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * User filled a budget for his trip form
   */
  has_budget: boolean;
  has_comment: boolean;
  /**
   * User filled a description for his trip form
   */
  has_description: boolean;
  is_filtered: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   * | Max Value | 14 |
   */
  quality_cluster: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ISSUE ON QUALITY, MEDIUM QUALITY, TOP QUALITY, BAD QUALITY, HIGH QUALITY |
   */
  quality_grouping: "ISSUE ON QUALITY" | "MEDIUM QUALITY" | "TOP QUALITY" | "BAD QUALITY" | "HIGH QUALITY";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | first, second, from_scratch |
   */
  tipi_recommendation_choice: "first" | "second" | "from_scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Avant le (date), le trip_form*id était en fait le* request_id. Le naming avait était mal fait
   */
  trip_form_id: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TipiRecommendCommentPopinClosedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * User filled a budget for his trip form
   */
  has_budget: boolean;
  /**
   * User filled a description for his trip form
   */
  has_description: boolean;
  is_filtered: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   * | Max Value | 14 |
   */
  quality_cluster: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ISSUE ON QUALITY, MEDIUM QUALITY, TOP QUALITY, BAD QUALITY, HIGH QUALITY |
   */
  quality_grouping: "ISSUE ON QUALITY" | "MEDIUM QUALITY" | "TOP QUALITY" | "BAD QUALITY" | "HIGH QUALITY";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | first, second, from_scratch |
   */
  tipi_recommendation_choice: "first" | "second" | "from_scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Avant le (date), le trip_form*id était en fait le* request_id. Le naming avait était mal fait
   */
  trip_form_id: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TipiRecommendDayClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  day_number: number;
  /**
   * User filled a budget for his trip form
   */
  has_budget: boolean;
  /**
   * User filled a description for his trip form
   */
  has_description: boolean;
  is_filtered: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   * | Max Value | 14 |
   */
  quality_cluster: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ISSUE ON QUALITY, MEDIUM QUALITY, TOP QUALITY, BAD QUALITY, HIGH QUALITY |
   */
  quality_grouping: "ISSUE ON QUALITY" | "MEDIUM QUALITY" | "TOP QUALITY" | "BAD QUALITY" | "HIGH QUALITY";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * The TiPi recommended on the page which user is interacting with
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | first, second |
   */
  tipi_recommendation: "first" | "second";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Avant le (date), le trip_form*id était en fait le* request_id. Le naming avait était mal fait
   */
  trip_form_id: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TipiRecommendSkipClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * User filled a budget for his trip form
   */
  has_budget: boolean;
  /**
   * User filled a description for his trip form
   */
  has_description: boolean;
  is_filtered: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   * | Max Value | 14 |
   */
  quality_cluster: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ISSUE ON QUALITY, MEDIUM QUALITY, TOP QUALITY, BAD QUALITY, HIGH QUALITY |
   */
  quality_grouping: "ISSUE ON QUALITY" | "MEDIUM QUALITY" | "TOP QUALITY" | "BAD QUALITY" | "HIGH QUALITY";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Avant le (date), le trip_form*id était en fait le* request_id. Le naming avait était mal fait
   */
  trip_form_id: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TipiRecommendTabClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * User filled a budget for his trip form
   */
  has_budget: boolean;
  /**
   * User filled a description for his trip form
   */
  has_description: boolean;
  is_filtered: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   * | Max Value | 14 |
   */
  quality_cluster: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ISSUE ON QUALITY, MEDIUM QUALITY, TOP QUALITY, BAD QUALITY, HIGH QUALITY |
   */
  quality_grouping: "ISSUE ON QUALITY" | "MEDIUM QUALITY" | "TOP QUALITY" | "BAD QUALITY" | "HIGH QUALITY";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * The TiPi recommended on the page which user is interacting with
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | first, second |
   */
  tipi_recommendation: "first" | "second";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Avant le (date), le trip_form*id était en fait le* request_id. Le naming avait était mal fait
   */
  trip_form_id: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ToasterActivityAddedClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Allows to know which version of the toaster is clicked.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | logged_in, logged_out |
   */
  toaster_version: "logged_in" | "logged_out";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TransportSearchClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Transportation type selected
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | train, plane |
   */
  transport_type: "train" | "plane";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TravelDateModificationClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Réponse à l'étape profil du TF qui indique si le user connait les dates fixes de son voyage.
   */
  known_date: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * Réponse étape profil du TF : {alone | couple | family | friends | group}
   *
   * \-> Les adjonctions à "famille" correspondent aux champs profile de where to go
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | alone, couple, family, friends, group, family_babies, family_kids, family_teen |
   */
  profile: "alone" | "couple" | "family" | "friends" | "group" | "family_babies" | "family_kids" | "family_teen";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TravelGuideArticleClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * propriété plus utilisée
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | why-to-go, general-and-inspiration, general-practical, essential, when-to-go, suitcase, vocabulary, author, advice, region, place-list, destination, visa, guide |
   */
  travel_guide_article_type:
    | "why-to-go"
    | "general-and-inspiration"
    | "general-practical"
    | "essential"
    | "when-to-go"
    | "suitcase"
    | "vocabulary"
    | "author"
    | "advice"
    | "region"
    | "place-list"
    | "destination"
    | "visa"
    | "guide";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TravelGuideClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TravelersTestimonialArticleClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripAdjustmentQuestionAnsweredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * ID de la réponse à une des questions de la discussion entre Evaneos et le traveler juste après la creation de sa request.
   */
  id_message: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Tells us in which of the 3 possible path of the budget experiment the user is in (no budget / budget ok / budget not ok)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Calendly, BC, BNC, BNR |
   */
  start_path: "Calendly" | "BC" | "BNC" | "BNR";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripAdjustmentQuestionDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * Tells us in which of the 3 possible path of the budget experiment the user is in (no budget / budget ok / budget not ok)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Calendly, BC, BNC, BNR |
   */
  start_path: "Calendly" | "BC" | "BNC" | "BNR";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripNotationClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 1, 2, 3, 4, 5 |
   */
  stars_selected: "1" | "2" | "3" | "4" | "5";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripPreparationClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripProjectInProgressMyTripClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripProjectInProgressPopinDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripProjectInProgressRestartClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripReviewClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  review_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripReviewsSortClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Value of the filter selected
   *
   *
   *
   */
  filter_value: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformActivitiesHostingStepCompletedProperties {
  /**
   * Permet de savoir si les envies voyageurs sont affichées en premier dans l'experiment tripform flow hesitating - Maturity novembre 2024
   *
   */
  activities_displayed_first: boolean;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Nombre de fois qu'un user a cliqué sur une carte au sein du one pager envies et hebergements au sein de l'experiment hestitating v3 dans le tripform
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nb_cards_clicked: number;
  /**
   * Nombre de cartes iti séléctionnées (checked) dans l'itération 3 du flow hesitating.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nb_cards_selected: number;
  /**
   * Indique le nombre de type(s) d'hebergement choisi(s) par le user dans la trip step hesitating (Experiment Oct. 24)
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_hosting_types_checked: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformActivitiesHostingStepViewedProperties {
  /**
   * Permet de savoir si les envies voyageurs sont affichées en premier dans l'experiment tripform flow hesitating - Maturity novembre 2024
   *
   */
  activities_displayed_first: boolean;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformActivitiesStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Answer to the maturity check question in the hesitating flow in the Tripform (Experiment of Oct 24).
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  maturity_check_answer?: number;
  /**
   * Nombre de cartes iti séléctionnées (checked) dans l'itération 3 du flow hesitating.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nb_cards_selected: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_main_cards_selected: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformBudgetStepCompletedProperties {
  /**
   * Correspond budget maximum renseigné par le user dans l'étape budget:
   *
   *  Renvoyer -1 dans le cas ou le user n'a rien mis dans le champs
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  budget_max_user?: number;
  /**
   * Budget median que l'on affiche à l'utilisateur lors de l'étape budget.
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  budget_median_desti?: number;
  /**
   * Correspond au budget minimum rempli par le user lors de l'étape budget dans le TF:
   *
   *
   * Renvoyer -1 dans le cas ou le user n'a pas renseigné le champs
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  budget_min_user?: number;
  /**
   * Correspond à la valeur proposée à l'utilisateur dans le bandeau en haut de page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  budget_reco?: number;
  /**
   * Correspond à la valeur indiquée par l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  budget_user?: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * "True" si budget personnalisé affiché
   *
   * "False" si message par défaut
   */
  is_budget_reco_available?: boolean;
  /**
   * Nous indique si le budget median que nous montrons à l'utilisateur se trouve entre le min et le max renseignés par ce dernier:
   *
   */
  is_median_between_min_max?: boolean;
  /**
   * Booléan permettant de savoir si le champ de budget a été rempli.
   *
   *
   * Dans le cas de l'experimentation Maturity sur l'étape budget au sein du TF (Experiment de Juillet 2024): pour la variation, il faut renvoyer TRUE dans le cas ou au moins une des 2 cases min / max a été remplie par le user.
   */
  is_project_budget_completed?: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  /**
   * Les 2 champs min et max ont été remplis par le user:
   *
   */
  min_and_max_completed?: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromAgenciesListProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromAgencyProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromDestinationProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromDestinationArticleProperties {
  /**
   * Type de l'article.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | general_inspiration, general_practical, food, history, formalities, guide, transportation, budget, security, souvenir, courtesy, tip, wild, arts, honeymoon, personalities, religion, geography, to_see, disability, other, eco_tourism, events, culture, language, economy |
   */
  article_type:
    | "general_inspiration"
    | "general_practical"
    | "food"
    | "history"
    | "formalities"
    | "guide"
    | "transportation"
    | "budget"
    | "security"
    | "souvenir"
    | "courtesy"
    | "tip"
    | "wild"
    | "arts"
    | "honeymoon"
    | "personalities"
    | "religion"
    | "geography"
    | "to_see"
    | "disability"
    | "other"
    | "eco_tourism"
    | "events"
    | "culture"
    | "language"
    | "economy";
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromDestinationArticlesListProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromDestinationSuitcaseProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromDestinationVisaProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromDestinationVocabularyProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromDestinationWhentogoProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromExploreProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromFacetProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * ID de la facet (uid) ex: **6409b6e0dcf679a9bc08c613**
   */
  facet_id?: string;
  /**
   * **ajout d'une valeur is_mvp sur les pages Facet pour le suivi des déploiements du nouveau template**
   */
  is_mvp?: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromItiProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromListItiProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromPoiProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromPoiListProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromRegionProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Indique l'ID  d'une région
   *
   * Le region_id est différent de la destination_id
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  region_id: number;
  /**
   * Indique le slugname d'une région (ex : Cinque Terre)
   *
   * Pour un Hub région (= une destination de type région), alors il n'y a pas de region_slug mais un destination_slug
   */
  region_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromRegionWhentogoProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Indique l'ID  d'une région
   *
   * Le region_id est différent de la destination_id
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  region_id: number;
  /**
   * Indique le slugname d'une région (ex : Cinque Terre)
   *
   * Pour un Hub région (= une destination de type région), alors il n'y a pas de region_slug mais un destination_slug
   */
  region_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformCreationStartedFromTravelGuideProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformDatesStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Réponse à l'étape profil du TF qui indique si le user connait les dates fixes de son voyage.
   */
  known_date: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * Indique si la période choisie par le user est une periode recommandée / neutre / last minute.
   *
   * Utilisée dans un premier temps dans le cadre de l'experiment sur la periode de maturity au sein du tripform.
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | recommended, last minute, neutral |
   */
  period_type: "recommended" | "last minute" | "neutral";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Durée de voyage selectionnée par le user lorsqu'il ne connait pas ses dates fixes.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Less than 1 week, 1 week, 2 weeks, 3weeks, More than 3 weeks, Advise me |
   */
  trip_duration?: "Less than 1 week" | "1 week" | "2 weeks" | "3weeks" | "More than 3 weeks" | "Advise me";
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformDestinationInfosStepCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Quit, No I don't want to choose, Yes I want to choose, more-infos, next-step, keep my budget, adjust my budget, ajdust program, adjust hostings, adjust period, Yes, No, itinerary proposal, choose budget range, not communicate budget, Economique, Confort, Premium |
   */
  answer:
    | "Quit"
    | "No I don't want to choose"
    | "Yes I want to choose"
    | "more-infos"
    | "next-step"
    | "keep my budget"
    | "adjust my budget"
    | "ajdust program"
    | "adjust hostings"
    | "adjust period"
    | "Yes"
    | "No"
    | "itinerary proposal"
    | "choose budget range"
    | "not communicate budget"
    | "Economique"
    | "Confort"
    | "Premium";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Answer of the user at the 2nd step of the Tripform specific to the hesitating (Experimeent oct. 24)
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | destination-infos, itinerary-ideas, send-project |
   */
  hesitating_qualification_answer?: "destination-infos" | "itinerary-ideas" | "send-project";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformExitButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Permet de savoir à quelle étape du TF une action a lieu (clic sur le bouton précédent ou envoi du TF)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | trip, information, profile, phone, maturity, budget, personalization, dates, duration, offer, maturity check, hesitating trip, offer pop in iti, list-itineraries, pop-in-iti |
   */
  trip_form_step:
    | "trip"
    | "information"
    | "profile"
    | "phone"
    | "maturity"
    | "budget"
    | "personalization"
    | "dates"
    | "duration"
    | "offer"
    | "maturity check"
    | "hesitating trip"
    | "offer pop in iti"
    | "list-itineraries"
    | "pop-in-iti";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformExitPopinContinuedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformExitPopinDisplayedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * cross_click : croix du tripform
   *
   * window_exit : sortie de la fenêtre du navigateur
   *
   * tab_exit : changement d'onglet sur mobile
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | exit, inactivity, cross_click, window_exit, tab_exit |
   */
  popin_trigger: "exit" | "inactivity" | "cross_click" | "window_exit" | "tab_exit";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformExitPopinLeftProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformHesitatingQualificationStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Answer of the user at the 2nd step of the Tripform specific to the hesitating (Experimeent oct. 24)
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | destination-infos, itinerary-ideas, send-project |
   */
  hesitating_qualification_answer?: "destination-infos" | "itinerary-ideas" | "send-project";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformHesitatingTripStepCompletedProperties {
  /**
   * Correspond à la valeur indiquée par l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  budget_user?: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Answer of the user at the 2nd step of the Tripform specific to the hesitating (Experimeent oct. 24)
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | destination-infos, itinerary-ideas, send-project |
   */
  hesitating_qualification_answer?: "destination-infos" | "itinerary-ideas" | "send-project";
  /**
   * Booléan permettant de savoir si le champ de budget a été rempli.
   *
   *
   * Dans le cas de l'experimentation Maturity sur l'étape budget au sein du TF (Experiment de Juillet 2024): pour la variation, il faut renvoyer TRUE dans le cas ou au moins une des 2 cases min / max a été remplie par le user.
   */
  is_project_budget_completed?: boolean;
  /**
   * Booléan permettant de savoir si le champ description a été rempli
   */
  is_project_description_completed: boolean;
  /**
   * Réponse à l'étape profil du TF qui indique si le user connait les dates fixes de son voyage.
   */
  known_date: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  /**
   * Answer to the maturity check question in the hesitating flow in the Tripform (Experiment of Oct 24).
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  maturity_check_answer?: number;
  /**
   * Nombre de cartes iti séléctionnées (checked) dans l'itération 3 du flow hesitating.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nb_cards_selected: number;
  /**
   * Indique le nombre de type(s) d'hebergement choisi(s) par le user dans la trip step hesitating (Experiment Oct. 24)
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_hosting_types_checked: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_main_cards_selected: number;
  path: string;
  /**
   * Nombre de caractères inscrits dans la description du voyage.
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  project_description_length: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformHostingStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Ajout d'un besoin spécifique de l'utilisateur à l'aide d'un champs libre.
   */
  is_specific_need_completed: boolean;
  /**
   * Answer to the maturity check question in the hesitating flow in the Tripform (Experiment of Oct 24).
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  maturity_check_answer?: number;
  /**
   * Indique le nombre de type(s) d'hebergement choisi(s) par le user dans la trip step hesitating (Experiment Oct. 24)
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_hosting_types_checked: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformInspirationalItineraryClickedProperties {
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Answer of the user at the 2nd step of the Tripform specific to the hesitating (Experimeent oct. 24)
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | destination-infos, itinerary-ideas, send-project |
   */
  hesitating_qualification_answer?: "destination-infos" | "itinerary-ideas" | "send-project";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  /**
   * Nombre de popin détails itinéraire vu par le user dans le flow hesitating dans l'étape offer au sein du Tripforom (Experiment Oct. 24).
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_popin_details_iti_viewed?: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Permet de savoir à quelle étape du TF une action a lieu (clic sur le bouton précédent ou envoi du TF)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | trip, information, profile, phone, maturity, budget, personalization, dates, duration, offer, maturity check, hesitating trip, offer pop in iti, list-itineraries, pop-in-iti |
   */
  trip_form_step:
    | "trip"
    | "information"
    | "profile"
    | "phone"
    | "maturity"
    | "budget"
    | "personalization"
    | "dates"
    | "duration"
    | "offer"
    | "maturity check"
    | "hesitating trip"
    | "offer pop in iti"
    | "list-itineraries"
    | "pop-in-iti";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformMailConnectionProperties {
  /**
   * Booléen permettant de savoir si le compte client était déjà présent dans notre BDD.
   *
   * Utile car le comportement n'est pas forcément le même si c'est un nouveau user.
   */
  already_existing?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformMaturityCheckStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Answer of the user at the 2nd step of the Tripform specific to the hesitating (Experimeent oct. 24)
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | destination-infos, itinerary-ideas, send-project |
   */
  hesitating_qualification_answer?: "destination-infos" | "itinerary-ideas" | "send-project";
  /**
   * Answer to the maturity check question in the hesitating flow in the Tripform (Experiment of Oct 24).
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  maturity_check_answer?: number;
  path: string;
  /**
   * Indicate if the user goes into the request path or the nurturing path
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | request, nurturing |
   */
  path_type: "request" | "nurturing";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformMaturityStepCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformMissingInformationsStepCompletedProperties {
  /**
   * Booléen permettant de savoir si le compte client était déjà présent dans notre BDD.
   *
   * Utile car le comportement n'est pas forcément le même si c'est un nouveau user.
   */
  already_existing?: boolean;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * type connection en social login : apple ou google
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | apple, google |
   */
  social_login_source?: "apple" | "google";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * Informations manquantes demandées
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  tripform_missing_informations: ("phone_number" | "last_name")[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformMissingInformationsViewedProperties {
  /**
   * Booléen permettant de savoir si le compte client était déjà présent dans notre BDD.
   *
   * Utile car le comportement n'est pas forcément le même si c'est un nouveau user.
   */
  already_existing?: boolean;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * type connection en social login : apple ou google
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | apple, google |
   */
  social_login_source?: "apple" | "google";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * Informations manquantes demandées
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  tripform_missing_informations: ("phone_number" | "last_name")[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformPersonalizationStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformPreviousStepProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Permet de savoir à quelle étape du TF une action a lieu (clic sur le bouton précédent ou envoi du TF)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | trip, information, profile, phone, maturity, budget, personalization, dates, duration, offer, maturity check, hesitating trip, offer pop in iti, list-itineraries, pop-in-iti |
   */
  trip_form_step:
    | "trip"
    | "information"
    | "profile"
    | "phone"
    | "maturity"
    | "budget"
    | "personalization"
    | "dates"
    | "duration"
    | "offer"
    | "maturity check"
    | "hesitating trip"
    | "offer pop in iti"
    | "list-itineraries"
    | "pop-in-iti";
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformProfileStepCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  /**
   * Answer of the user at the 2nd step of the Tripform specific to the hesitating (Experimeent oct. 24)
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | destination-infos, itinerary-ideas, send-project |
   */
  hesitating_qualification_answer?: "destination-infos" | "itinerary-ideas" | "send-project";
  /**
   * Réponse à l'étape profil du TF qui indique si le user connait les dates fixes de son voyage.
   */
  known_date: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  /**
   * Answer to the maturity check question in the hesitating flow in the Tripform (Experiment of Oct 24).
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  maturity_check_answer?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * Réponse étape profil du TF : {alone | couple | family | friends | group}
   *
   * \-> Les adjonctions à "famille" correspondent aux champs profile de where to go
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | alone, couple, family, friends, group, family_babies, family_kids, family_teen |
   */
  profile: "alone" | "couple" | "family" | "friends" | "group" | "family_babies" | "family_kids" | "family_teen";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   *
   * The maturity answer to the date question.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | precise, approximate, unknown |
   */
  test_maturity_date: "precise" | "approximate" | "unknown";
  /**
   *
   * The answers to the question :
   * What are the 3 most important criteria to define your travel dates?
   *
   * The answers are :
   *
   * * local events
   *
   * * pricing
   *
   * * tourist influx
   *
   * * weather
   *
   * * other (to precise)
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   * | Item Type | string |
   */
  test_period_criteria?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformProjectDescriptionClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformReadyToSentProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformRhythmStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Ajout d'un besoin spécifique de l'utilisateur à l'aide d'un champs libre.
   */
  is_specific_need_completed: boolean;
  /**
   * Answer to the maturity check question in the hesitating flow in the Tripform (Experiment of Oct 24).
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  maturity_check_answer?: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Type de rythme de voyage choisi dans le tripform (flow hesitating - experiment maturity)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Détendu, Dynamique, Équilibré |
   */
  rhythm_type: "Détendu" | "Dynamique" | "Équilibré";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformSkipStepClickedProperties {
  /**
   * Type du boutton pour passer l'étape d'offre dans l'hesitating flow au sein du tripform(experimentation Oct.24).
   *
   *
   * background: click en dehors de l'écran / popin /modale pour fermer.
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | CTA, Cross, Navbar, background |
   */
  button_type: "CTA" | "Cross" | "Navbar" | "background";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Nombre de cartes itinéraire vues lors de la consultation de la liste itinéraire dans la step offer du TF pour les hesitating avant que le user clique l’aperçu d’un circuit ou sur passer l'étape. On considère que le user a vu une carte itinéraire lorsque il lui a été affiché le CTA “Apercu du circuit”. (Experimentation TF hesitating Oct. 24)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_cards_iti_viewed?: number;
  /**
   * Nombre de popin détails itinéraire vu par le user dans le flow hesitating dans l'étape offer au sein du Tripforom (Experiment Oct. 24).
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_popin_details_iti_viewed?: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Permet de savoir à quelle étape du TF une action a lieu (clic sur le bouton précédent ou envoi du TF)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | trip, information, profile, phone, maturity, budget, personalization, dates, duration, offer, maturity check, hesitating trip, offer pop in iti, list-itineraries, pop-in-iti |
   */
  trip_form_step:
    | "trip"
    | "information"
    | "profile"
    | "phone"
    | "maturity"
    | "budget"
    | "personalization"
    | "dates"
    | "duration"
    | "offer"
    | "maturity check"
    | "hesitating trip"
    | "offer pop in iti"
    | "list-itineraries"
    | "pop-in-iti";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformSocialLoginAddPhoneNumberProperties {
  /**
   * Booléen permettant de savoir si le compte client était déjà présent dans notre BDD.
   *
   * Utile car le comportement n'est pas forcément le même si c'est un nouveau user.
   */
  already_existing?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * type connection en social login : apple ou google
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | apple, google |
   */
  social_login_source?: "apple" | "google";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformSocialLoginConnectionProperties {
  /**
   * Booléen permettant de savoir si le compte client était déjà présent dans notre BDD.
   *
   * Utile car le comportement n'est pas forcément le même si c'est un nouveau user.
   */
  already_existing?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * type connection en social login : apple ou google
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | apple, google |
   */
  social_login_source?: "apple" | "google";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformStartFromZeroClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformTripStepCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  /**
   * Booléan permettant de savoir si le champ de budget a été rempli.
   *
   *
   * Dans le cas de l'experimentation Maturity sur l'étape budget au sein du TF (Experiment de Juillet 2024): pour la variation, il faut renvoyer TRUE dans le cas ou au moins une des 2 cases min / max a été remplie par le user.
   */
  is_project_budget_completed?: boolean;
  /**
   * Booléan permettant de savoir si le champ description a été rempli
   */
  is_project_description_completed: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripformTripformSentProperties {
  /**
   * Booléen permettant de savoir si le compte client était déjà présent dans notre BDD.
   *
   * Utile car le comportement n'est pas forcément le même si c'est un nouveau user.
   */
  already_existing?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * Lors de l'envoi d'un tripform, le même CTA est utilisé qq soit le type de connexion (mail sign in⎜mail sign up⎜social login phone) donc cette info est spécifiée en propriété
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | mail_sign_in, mail_sign_up, social_login_phone, social_login, already_connected, mail_sign_in_password_forgotten |
   */
  connection_type:
    | "mail_sign_in"
    | "mail_sign_up"
    | "social_login_phone"
    | "social_login"
    | "already_connected"
    | "mail_sign_in_password_forgotten";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  /**
   * Answer of the user at the 2nd step of the Tripform specific to the hesitating (Experimeent oct. 24)
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | destination-infos, itinerary-ideas, send-project |
   */
  hesitating_qualification_answer?: "destination-infos" | "itinerary-ideas" | "send-project";
  is_filtered: boolean;
  /**
   * True : phone number is completed
   *  False : Phone number is not completed
   */
  is_phone_number_completed?: boolean;
  /**
   * True if the optin newsletter box is checked
   *
   */
  mail_optin_checked: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  /**
   * Answer to the maturity check question in the hesitating flow in the Tripform (Experiment of Oct 24).
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  maturity_check_answer?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * Booléan permettant de déterminer si le champ de numéro de téléphone a été complété ou non.
   *
   *
   *
   */
  phone_number_completed?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   * | Max Value | 14 |
   */
  quality_cluster: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ISSUE ON QUALITY, MEDIUM QUALITY, TOP QUALITY, BAD QUALITY, HIGH QUALITY |
   */
  quality_grouping: "ISSUE ON QUALITY" | "MEDIUM QUALITY" | "TOP QUALITY" | "BAD QUALITY" | "HIGH QUALITY";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * Check if a user accept to optin to SMS
   *
   */
  sms_optin_checked: boolean;
  /**
   * type connection en social login : apple ou google
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  social_login_source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Avant le (date), le trip_form*id était en fait le* request_id. Le naming avait était mal fait
   */
  trip_form_id: string;
  /**
   * Permet de savoir à quelle étape du TF une action a lieu (clic sur le bouton précédent ou envoi du TF)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | trip, information, profile, phone, maturity, budget, personalization, dates, duration, offer, maturity check, hesitating trip, offer pop in iti, list-itineraries, pop-in-iti |
   */
  trip_form_step:
    | "trip"
    | "information"
    | "profile"
    | "phone"
    | "maturity"
    | "budget"
    | "personalization"
    | "dates"
    | "duration"
    | "offer"
    | "maturity check"
    | "hesitating trip"
    | "offer pop in iti"
    | "list-itineraries"
    | "pop-in-iti";
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripsDestinationRecommendationClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * Name of the destination.
   */
  destination_name: string;
  /**
   * Type of the recommendation on the esper page.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | (budget|segment) |
   */
  esper_recommendation_type?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | (App|Esper|Mail|PushApp|WebsiteHome|WebsiteModalHome|WebsiteModalDestination|MailReminder|NavigationNavbar|NavigationHeadbar|NavigationEsper|TeaserPageDesti|CoverHomepage|HeaderDesti|Unknown) |
   */
  recommendation_source: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripsRecommendationClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Type of the recommendation on the esper page.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | (budget|segment) |
   */
  esper_recommendation_type?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface TripsTripClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface UsefulButtonClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface VideoPlayedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewMyTripClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Permet de distinguer le type de clics quand il y a plusieurs façon pour une seule action.
   *  Exemple sur la page Facet avec deux façons d'accéder aux témoignages
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | stars, button, card, link, previous, next, number, background, cross |
   */
  click_type: "stars" | "button" | "card" | "link" | "previous" | "next" | "number" | "background" | "cross";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageHubProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
}

export interface ViewedPageAccountProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountPersonalInformationsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountPersonalInformationsNotificationsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountPersonalInformationsPasswordProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountPoiOpinionsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountPoiOpinionsAddProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountRecommendationsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | (App|Esper|Mail|PushApp|WebsiteHome|WebsiteModalHome|WebsiteModalDestination|MailReminder|NavigationNavbar|NavigationHeadbar|NavigationEsper|TeaserPageDesti|CoverHomepage|HeaderDesti|Unknown) |
   */
  recommendation_source: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripAboutTheAgencyProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripJoinProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripJoinExpiredProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripMessagesProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * The page in ESPER has an appointment button available
   *
   *
   *
   *
   */
  has_appointment_button?: boolean;
  path: string;
  /**
   * Permet de savoir si le block de pts est visible
   *
   */
  pts_visible?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripNeedHelpProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripPartnersProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripPaymentsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripSharedDocumentsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripTransportsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * The active page display a transportation mode with train
   *
   *
   *
   */
  has_train_option: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripTravellersProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripValidateProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripValidateConfirmationProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Type of the recommendation on the esper page.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | (budget|segment) |
   */
  esper_recommendation_type?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountTripsRecommendationsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Type of the recommendation on the esper page.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | (budget|segment) |
   */
  esper_recommendation_type?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | (App|Esper|Mail|PushApp|WebsiteHome|WebsiteModalHome|WebsiteModalDestination|MailReminder|NavigationNavbar|NavigationHeadbar|NavigationEsper|TeaserPageDesti|CoverHomepage|HeaderDesti|Unknown) |
   */
  recommendation_source: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountWishlistProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAccountWishlistDestinationProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageActivitiesProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * If the current view of Evaneos Explore Activities List has Incontournable selection of activities
   */
  has_incontournable: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageActivitiesMyTripProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  activities_count: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageActivityDetailsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  cross_sell_displayed: boolean;
  path: string;
  /**
   * Number of POIs contained by activity
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  poi_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Number of tags contained by activity
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  tag_number: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAgenciesListProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Nb agences affichées
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_agencies_displayed: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageArticleProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageAutumnHubProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageBeginFormProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageBestSellersHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageBetterTravelerPledgeProperties {
  /**
   * Nom de l'étape du Better Traveler Pledge
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | local culture, explore responsibly, reduce carbon footprint, become ambassador, signature |
   */
  btp_step_name:
    | "local culture"
    | "explore responsibly"
    | "reduce carbon footprint"
    | "become ambassador"
    | "signature";
  /**
   * Numéro de l'étape du Better Traveler Pledge vue (à date, numéro de 1 à 5)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   * | Max Value | 5 |
   */
  btp_step_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageBetterTravelerPledgeConfirmationProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageBetterTravelerPledgeIntroProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageBikeHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCityBreakHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoAgenciesProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoArticleEssentialProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoArticleInspirationProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoArticlePracticalProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoArticleSuitcaseProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoArticleVisaProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoArticleVocabularyProperties {
  "[Amplitude] Session Recorded"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoArticleWhentogoProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoArticleWhytogoProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoHomeProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoListInspirationProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoListPlaceProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCocoPlaceProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCompletedFormProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Avant le (date), le trip_form*id était en fait le* request_id. Le naming avait était mal fait
   */
  trip_form_id: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageConceptProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageContestProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  referring_domain?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
}

export interface ViewedPageContextContinentProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCountriesProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCountryProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  /**
   * Booléen permettant de savoir si la destination associée a une bannière d'alerte.
   *  Information disponible sur les pages Desti, Facet et Iti
   *
   *
   *
   */
  has_alert_message: boolean;
  /**
   * The active page display a transportation mode with train
   *
   *
   *
   */
  has_train_option: boolean;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  /**
   * Permet de déterminer si l'affichage sur la page Country est au format grille (grid) ou ordonné en fonction des facets (facet) ayant le plus de potentiel SEO.
   *  S'il y a moins de 10 itinéraires disponibles, l'affichage par défaut est la grille
   *  (Screenshot à venir soon)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grid, facet |
   */
  itineraries_display_type: "grid" | "facet";
  /**
   * quand une desti est une region, on renseigne l'id du pays principal
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  main_destination_id?: number;
  /**
   * quand une desti est une region, on renseigne le nom du pays principal
   */
  main_destination_slug?: string;
  /**
   * Actuellement, on affiche maximum 12 facets sur le carrousel présent notamment sur la page Desti donc la valeur maximum attendue est 12. S'il y a moins de facets, on souhaite également savoir la valeur. Permet de mieux comparer le card_number en fonction du nb de facets affichées
   *
   * Sur la page destination, à partir d'août 2024, il ya le carrousel facet (sans les saison, durée et mois)  + un carrousel facet exprès saison, durée, mois. La propriété **nb_facets_displayed** fait la somme des facets affichées sur les deux carrousels.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_facets_displayed: number;
  /**
   * nb iti dispo sur une desti. s'il y a des doublons d'iti, ils sont comptés 2 fois
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_itineraries_displayed: number;
  /**
   * Nb questions affichées dans la FAQ des pages desti et facet
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_questions_displayed: number;
  /**
   * Lorsqu'il y a des régions, on souhaite également savoir le nb de régions affichées.
   *
   * Théoriquement, sur la page desti, c'est le nb de régions affichées dans la navbar (mais la source de données n'est pas la même donc il peut y avoir une petite différence)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_regions_displayed: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Array listant toutes les thématiques filtrables sur une page desti
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  thematic_filter_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageCountryRecommendationProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Type de desti : region ou country
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | region, country |
   */
  destination_type?: "region" | "country";
  /**
   * Booléen permettant de savoir si la destination associée a une bannière d'alerte.
   *  Information disponible sur les pages Desti, Facet et Iti
   *
   *
   *
   */
  has_alert_message: boolean;
  /**
   * The active page display a transportation mode with train
   *
   *
   *
   */
  has_train_option: boolean;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  /**
   * Permet de déterminer si l'affichage sur la page Country est au format grille (grid) ou ordonné en fonction des facets (facet) ayant le plus de potentiel SEO.
   *  S'il y a moins de 10 itinéraires disponibles, l'affichage par défaut est la grille
   *  (Screenshot à venir soon)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grid, facet |
   */
  itineraries_display_type: "grid" | "facet";
  /**
   * quand une desti est une region, on renseigne l'id du pays principal
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  main_destination_id?: number;
  /**
   * quand une desti est une region, on renseigne le nom du pays principal
   */
  main_destination_slug?: string;
  /**
   * Actuellement, on affiche maximum 12 facets sur le carrousel présent notamment sur la page Desti donc la valeur maximum attendue est 12. S'il y a moins de facets, on souhaite également savoir la valeur. Permet de mieux comparer le card_number en fonction du nb de facets affichées
   *
   * Sur la page destination, à partir d'août 2024, il ya le carrousel facet (sans les saison, durée et mois)  + un carrousel facet exprès saison, durée, mois. La propriété **nb_facets_displayed** fait la somme des facets affichées sur les deux carrousels.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_facets_displayed: number;
  /**
   * nb iti dispo sur une desti. s'il y a des doublons d'iti, ils sont comptés 2 fois
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_itineraries_displayed: number;
  /**
   * Nb questions affichées dans la FAQ des pages desti et facet
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_questions_displayed: number;
  /**
   * Correspond au nb d'itinéraires recommandés
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nb_recommended_itineraries_displayed: number;
  /**
   * Lorsqu'il y a des régions, on souhaite également savoir le nb de régions affichées.
   *
   * Théoriquement, sur la page desti, c'est le nb de régions affichées dans la navbar (mais la source de données n'est pas la même donc il peut y avoir une petite différence)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_regions_displayed: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Array listant toutes les thématiques filtrables sur une page desti
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  thematic_filter_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageDestinationArticleProperties {
  /**
   * Type de l'article.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | general_inspiration, general_practical, food, history, formalities, guide, transportation, budget, security, souvenir, courtesy, tip, wild, arts, honeymoon, personalities, religion, geography, to_see, disability, other, eco_tourism, events, culture, language, economy |
   */
  article_type:
    | "general_inspiration"
    | "general_practical"
    | "food"
    | "history"
    | "formalities"
    | "guide"
    | "transportation"
    | "budget"
    | "security"
    | "souvenir"
    | "courtesy"
    | "tip"
    | "wild"
    | "arts"
    | "honeymoon"
    | "personalities"
    | "religion"
    | "geography"
    | "to_see"
    | "disability"
    | "other"
    | "eco_tourism"
    | "events"
    | "culture"
    | "language"
    | "economy";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageDestinationArticlesListProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageDestinationDetailsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageDestinationSuitcaseProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageDestinationVisaProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageDestinationVocabularyProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageDestinationWhentogoProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageDestinationWhatToDoProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageExceptionalJourneyHubProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
}

export interface ViewedPageExperienceProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageFacetProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * ID de la facet (uid) ex: **6409b6e0dcf679a9bc08c613**
   */
  facet_id?: string;
  /**
   * Les thématiques des facets sont regroupées dans différentes catégories qui correspondent à cette propriété. Côté code, cela correspond à `facet.filter.name` A date, voici les correspondances
   *
   *
   *
   *
   *
   *
   *
   *
   */
  facet_tag_category: string;
  /**
   * Tag de la facet cliquée (ex: nature, bike, summer...)
   *
   * Côté code, cela correspond à `facet.filter.value`
   *
   * Sur l'event Itinerary clicked, c'était utilisé quand on avait la catégorisation par facet sur la page desti donc la propriété est en optionnel sur cet event comment l'affichage est de nouveau en grille.
   */
  facet_tag_name: string;
  /**
   * Booléen permettant de savoir si la destination associée a une bannière d'alerte.
   *  Information disponible sur les pages Desti, Facet et Iti
   *
   *
   *
   */
  has_alert_message: boolean;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  /**
   * **ajout d'une valeur is_mvp sur les pages Facet pour le suivi des déploiements du nouveau template**
   */
  is_mvp?: boolean;
  /**
   * Lorsque le user clique sur une des filtres, cette proprioété permet de savoir le nb d'itinéraires disponibles.
   *  Utile car l'expérience peut différer en fonction
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_itineraries_available: number;
  /**
   * Nb questions affichées dans la FAQ des pages desti et facet
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_questions_displayed: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * Parfois plusieurs events sont déclenchés alors que le user clique sur différents éléments (comme dans le header par ex) donc il faut pouvoir distinguer les liens cliqués. Cette prop a pour but de savoir lequel est cliqué
   *
   * Cette propriété est differente de `thematic_name`
   *
   * Page Thematic Famille : [https://www.evaneos.fr/ou\\-partir/voyage\\-en\\-famille/](https://www.evaneos.fr/ou%5C-partir/voyage%5C-en%5C-famille/) => slug = `voyage\-en\-famille` (va être traduit dans chaque langue / marché)
   *
   * Cette propriété est maintenant remplacée par **facet_tag_name** sur l'event Viewed page Facet car c'était un naming différent pour la même chose.
   */
  thematic_slug?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageFamilyExperienceProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageFamilyHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageFebruaryHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageFlightsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  trip_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageGastronomyExperienceProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageGastronomyHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageHikesTrekHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageHoneymoonHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageIndexProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageItineraryProperties {
  "[Amplitude] Session Recorded"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Booléen permettant de savoir si la destination associée a une bannière d'alerte.
   *  Information disponible sur les pages Desti, Facet et Iti
   *
   *
   *
   */
  has_alert_message: boolean;
  /**
   * The active page display a transportation mode with train
   *
   *
   *
   */
  has_train_option: boolean;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageJanuaryHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageJoinAGroupHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageJulyHubProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
}

export interface ViewedPageLandingEventsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageLandingRespireWarrantyProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageLandingTopDestination2020Properties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageLeadNurturingProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageListItinerariesProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Booléen permettant de savoir si la destination associée a une bannière d'alerte.
   *  Information disponible sur les pages Desti, Facet et Iti
   *
   *
   *
   */
  has_alert_message: boolean;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  /**
   * nb iti dispo sur une desti. s'il y a des doublons d'iti, ils sont comptés 2 fois
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_itineraries_displayed: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageListItinerariesRecommendationProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * Booléen permettant de savoir si la destination associée a une bannière d'alerte.
   *  Information disponible sur les pages Desti, Facet et Iti
   *
   *
   *
   */
  has_alert_message: boolean;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  /**
   * nb iti dispo sur une desti. s'il y a des doublons d'iti, ils sont comptés 2 fois
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_itineraries_displayed: number;
  /**
   * Correspond au nb d'itinéraires recommandés
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  nb_recommended_itineraries_displayed: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageLocalAgentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  /**
   * Si oui ou non la page agence a une vidéo YouTube
   *
   *
   *
   *
   */
  has_video_content: boolean;
  /**
   * Nombre d'agents de l'agence affichés sur une page agence
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_agents_displayed: number;
  path: string;
  /**
   * La note totale d'une agence
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 5 |
   */
  rating?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageLpAcquisitionProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageMagazineProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageMagazineArticleProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageMagazineArticlePreviewProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageNatureHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageNoPlaneHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageNorthernLightHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageNullProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageOffTheBeatenTrackHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageOneWeekHubProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageParadiseBeachHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPagePartnershipProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPagePathfinderConversationProperties {
  pathfinder_destination_slug?: string;
}

export interface ViewedPagePaymentProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPagePaymentIbanProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPagePaymentInsuranceProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
}

export interface ViewedPagePaymentPayerInfoProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
}

export interface ViewedPagePaymentResultProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPagePaymentResultFailureProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
}

export interface ViewedPagePaymentResultSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
}

export interface ViewedPagePaymentScheduleProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPagePaymentSummaryProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
}

export interface ViewedPagePaymentTravelInformationProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
}

export interface ViewedPagePaymentWithAdyenProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
}

export interface ViewedPagePoiListProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPagePressProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPagePrismicPreviewProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageRegionProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   */
  destination_slug: string;
  /**
   * Booléen permettant de savoir si la destination associée a une bannière d'alerte.
   *  Information disponible sur les pages Desti, Facet et Iti
   *
   *
   *
   */
  has_alert_message: boolean;
  /**
   * estce que le user peut créer un TF depuis uen page desti ?
   */
  has_trip_form_available: boolean;
  /**
   * nb iti dispo sur une desti. s'il y a des doublons d'iti, ils sont comptés 2 fois
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_itineraries_displayed: number;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Indique l'ID  d'une région
   *
   * Le region_id est différent de la destination_id
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  region_id: number;
  /**
   * Indique le slugname d'une région (ex : Cinque Terre)
   *
   * Pour un Hub région (= une destination de type région), alors il n'y a pas de region_slug mais un destination_slug
   */
  region_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageRegionWhentogoProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * Indique l'ID  d'une région
   *
   * Le region_id est différent de la destination_id
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  region_id: number;
  /**
   * Indique le slugname d'une région (ex : Cinque Terre)
   *
   * Pour un Hub région (= une destination de type région), alors il n'y a pas de region_slug mais un destination_slug
   */
  region_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageRequalificationFormProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageRequalificationFormEmailProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageSafarisHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageSelfdriveHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageSeptemberHubProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  site_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url?: string;
}

export interface ViewedPageSitemapProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageSportAdventureHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageSummerHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageTeamProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageThematicLandingProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageTipiRecommendationProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * User filled a budget for his trip form
   */
  has_budget: boolean;
  /**
   * User filled a description for his trip form
   */
  has_description: boolean;
  is_filtered: boolean;
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   * | Max Value | 14 |
   */
  quality_cluster: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ISSUE ON QUALITY, MEDIUM QUALITY, TOP QUALITY, BAD QUALITY, HIGH QUALITY |
   */
  quality_grouping: "ISSUE ON QUALITY" | "MEDIUM QUALITY" | "TOP QUALITY" | "BAD QUALITY" | "HIGH QUALITY";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * List of recommended TiPi UUIDs
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tipi_recommended: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * Avant le (date), le trip_form*id était en fait le* request_id. Le naming avait était mal fait
   */
  trip_form_id: string;
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageTopDestinationsProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageTravelGuideProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageTravelExplorerProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageTripReviewProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageTripReviewDestinationProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageTripReviewListProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageTwoWeeksHubProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageUniversalListItinerariesProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageWebinarProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageWhereToGoProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageWinterHubProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ViewedPageWorkingAbroadProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface VocabularyArticleClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WebinarReplayClickedProperties {
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
  /**
   * URL de redirection du CTA Exemple sur ces deux CTA dont les URL de redirection peuvent être administrés par les équipes Métier
   *
   *
   *
   */
  url_redirection: string;
}

export interface WebinarSignUpClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoContinentStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Valeur issue de l'info ici :
   *
   *
   *
   */
  continent: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  poc_wheretogo_continents: (
    | "any"
    | "europe"
    | "north-america"
    | "central-america"
    | "south-america"
    | "asia"
    | "africa"
    | "oceania"
    | "middle-east"
  )[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoDestinationRecommendationStartedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * True if the user is in an inspiration path
   */
  is_inspiration?: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoDurationStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | long_weekend, one_week, two_weeks, three_weeks_or_more, any |
   */
  duration_wheretogo: "long_weekend" | "one_week" | "two_weeks" | "three_weeks_or_more" | "any";
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoExitClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoExperiencesIteration3StepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  experiences: ("any" | "discover" | "relax" | "sport" | "culture")[];
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoExperiencesStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  needs: ("relax" | "sport" | "culture" | "any" | "discovery")[];
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoLoaderViewResultsClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Json qui indique les filtres présents dans where-to-go
   *
   * Format:
   *
   * ```
   * {"continent": [], "needs": [], "period": []}
   * ```
   */
  filter_where_to_go?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoMaturityUserStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  eco_responsable?: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, budget |
   */
  user_maturity?: "inspiration" | "budget";
}

export interface WheretogoOptinNlCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  opted_in: boolean;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoPaxStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | familyWithBabies, familyWithKids, familyWithTeenagers, couple, friends, solo |
   */
  profile_wheretogo: "familyWithBabies" | "familyWithKids" | "familyWithTeenagers" | "couple" | "friends" | "solo";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoPeriodStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  /**
   * period selected
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | spring, summer, autumn, winter, any |
   */
  season: "spring" | "summer" | "autumn" | "winter" | "any";
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoPreviousStepClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoRecommendationsViewedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  destination_id_recommanded: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_destinations_available?: number;
  path: string;
  /**
   * La tranche de budget à laquelle appartient l'item clické dans les résultats du POC Where To Go
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | economic, luxurious, moderate |
   */
  poc_wheretogo_budget_type?: "economic" | "luxurious" | "moderate";
  poc_wheretogo_has_social_proof_banner?: boolean;
  /**
   * Choix de l'utilisateur à l'étape "Sustainability"
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sustainable_ready, sustainable_not_ready |
   */
  poc_wheretogo_sustainability_choice?: "sustainable_ready" | "sustainable_not_ready";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | inspiration, budget |
   */
  user_maturity?: "inspiration" | "budget";
}

export interface WheretogoResultsAddedToWishlistProperties {
  /**
   * Numéro de la card cliqué par le user.
   *
   * Exemple,
   *
   * * card_number de la card jaune = 1
   *
   * * card_number de la card rouge = 2
   *
   * * card_number de la card bleue = 3
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  card_number: number;
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * La tranche de budget à laquelle appartient l'item clické dans les résultats du POC Where To Go
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | economic, luxurious, moderate |
   */
  poc_wheretogo_budget_type?: "economic" | "luxurious" | "moderate";
  poc_wheretogo_has_experiences?: boolean;
  poc_wheretogo_has_social_proof_banner?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | itinerary, experience |
   */
  poc_wheretogo_reco_type?: "itinerary" | "experience";
  /**
   * Segment name selected
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | bike, hike, boat, train, car |
   */
  poc_wheretogo_segment?: "bike" | "hike" | "boat" | "train" | "car";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoResultsSelectBudgetProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Json qui indique les filtres présents dans where-to-go
   *
   * Format:
   *
   * ```
   * {"continent": [], "needs": [], "period": []}
   * ```
   */
  filter_where_to_go?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  nb_destinations_available?: number;
  path: string;
  /**
   * La tranche de budget à laquelle appartient l'item clické dans les résultats du POC Where To Go
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | economic, luxurious, moderate |
   */
  poc_wheretogo_budget_type: "economic" | "luxurious" | "moderate";
  poc_wheretogo_has_social_proof_banner: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoResultsSelectSegmentProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * Json qui indique les filtres présents dans where-to-go
   *
   * Format:
   *
   * ```
   * {"continent": [], "needs": [], "period": []}
   * ```
   */
  filter_where_to_go?: string;
  path: string;
  /**
   * Segment name selected
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | bike, hike, boat, train, car |
   */
  poc_wheretogo_segment?: "bike" | "hike" | "boat" | "train" | "car";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoSustainabilityStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * Choix de l'utilisateur à l'étape "Sustainability"
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sustainable_ready, sustainable_not_ready |
   */
  poc_wheretogo_sustainability_choice?: "sustainable_ready" | "sustainable_not_ready";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoTransportationStepCompletedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | close_to_home, less_than_two_hours, more_than_two_hours |
   */
  transportation: "close_to_home" | "less_than_two_hours" | "more_than_two_hours";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WheretogoClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WishlistDestinationClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WishlistDiscoverDestinationClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WishlistItineraryClickedProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WishlistAddedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface WishlistClickedProperties {
  /**
   * Cette propriété a pour but d'identifier le bloc dans lequel l'event a été déclenché cat certains events peuvent être déclenchés à plusieurs endroits de la page.
   *
   * Plus de détail sur ce Notion : https://www.notion.so/leather-yard-6b5/Block-Amplitude-f922add1a78a4777b8a576dfdf797f73
   *
   * Voici à quoi correspondent chacun des blocs de la page Iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * \`
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navbar, sidebar, description, agent_information, itinerary_step, map, practical_details, no_match, facets, closeby_itineraries, elsewhere_itineraries, modal_practical_details, mobile_bottom_bar, header, agencies, testimonials, cartouche, itineraries, destinations, experience_banner, card_concept, whentogo, magazine, faq, places, popin_budget_suggestion, popin_newsletter, footer, sidebar_newsletter, agency, list_agency, list_agency_bloc, header_wheretogo_cta, intention, whytogo, travel_guide_articles, where_to_go, top_sellers_itinerary, highlighted_itinerary, newest_creation_itinerary, top_agency, cover, sticky_banner, regions, where_to_go_results_cta, where_to_go_results_title, free_and_non_binding_quote, traveler_protection, flexible_and_secure_payment, activity_incontournable, activity_list, activity_my_trip, activity_details, activity_details_map, ugc_card_button, ugc_card_image, image_gallery, activity_save_from_popin, activity_details_cross_sell, practical_information, inspiration_articles, reco_destination, reco_similar, reco_segment, reco_closetohome, reco_period, reco_budget, reco_longtohome, reco_ratings, activity_milestones, activity_live_list, themes_inspiration, reco_v2_topreco, reco_v2_topagencies, reco_v2_favoritedestis, highlight, double_picture_with_description_and_cta, double_picture_with_description, two_column_text, special_picks, best_sellers, travel_idea, inspiration, transport, reco_v3_topreco, agent_list, tripform_offer_step_destination_infos, tripform_offer_step, popin_info_destination, tripform_exit_step, articles, primary_nav, reco_favorite_desti, reco_top_agencies, discussion, destination_story |
   */
  block:
    | "navbar"
    | "sidebar"
    | "description"
    | "agent_information"
    | "itinerary_step"
    | "map"
    | "practical_details"
    | "no_match"
    | "facets"
    | "closeby_itineraries"
    | "elsewhere_itineraries"
    | "modal_practical_details"
    | "mobile_bottom_bar"
    | "header"
    | "agencies"
    | "testimonials"
    | "cartouche"
    | "itineraries"
    | "destinations"
    | "experience_banner"
    | "card_concept"
    | "whentogo"
    | "magazine"
    | "faq"
    | "places"
    | "popin_budget_suggestion"
    | "popin_newsletter"
    | "footer"
    | "sidebar_newsletter"
    | "agency"
    | "list_agency"
    | "list_agency_bloc"
    | "header_wheretogo_cta"
    | "intention"
    | "whytogo"
    | "travel_guide_articles"
    | "where_to_go"
    | "top_sellers_itinerary"
    | "highlighted_itinerary"
    | "newest_creation_itinerary"
    | "top_agency"
    | "cover"
    | "sticky_banner"
    | "regions"
    | "where_to_go_results_cta"
    | "where_to_go_results_title"
    | "free_and_non_binding_quote"
    | "traveler_protection"
    | "flexible_and_secure_payment"
    | "activity_incontournable"
    | "activity_list"
    | "activity_my_trip"
    | "activity_details"
    | "activity_details_map"
    | "ugc_card_button"
    | "ugc_card_image"
    | "image_gallery"
    | "activity_save_from_popin"
    | "activity_details_cross_sell"
    | "practical_information"
    | "inspiration_articles"
    | "reco_destination"
    | "reco_similar"
    | "reco_segment"
    | "reco_closetohome"
    | "reco_period"
    | "reco_budget"
    | "reco_longtohome"
    | "reco_ratings"
    | "activity_milestones"
    | "activity_live_list"
    | "themes_inspiration"
    | "reco_v2_topreco"
    | "reco_v2_topagencies"
    | "reco_v2_favoritedestis"
    | "highlight"
    | "double_picture_with_description_and_cta"
    | "double_picture_with_description"
    | "two_column_text"
    | "special_picks"
    | "best_sellers"
    | "travel_idea"
    | "inspiration"
    | "transport"
    | "reco_v3_topreco"
    | "agent_list"
    | "tripform_offer_step_destination_infos"
    | "tripform_offer_step"
    | "popin_info_destination"
    | "tripform_exit_step"
    | "articles"
    | "primary_nav"
    | "reco_favorite_desti"
    | "reco_top_agencies"
    | "discussion"
    | "destination_story";
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface ActivityPropertiesProperties {
  /**
   * Number of POIs contained by activity
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  poi_number: number;
  /**
   * Number of tags contained by activity
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  tag_number: number;
}

export interface AgencyInfoProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | STAR, A, B, C, NO BTI, NONE, NEW |
   */
  agency_better_trip_index: "STAR" | "A" | "B" | "C" | "NO BTI" | "NONE" | "NEW";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agency_id: number;
  agency_slug: string;
}

export interface DefaultPropertiesProperties {
  /**
   * correspond au context qu'il y avait sur GA
   */
  category: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search?: string;
  site_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  tab_url: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  title: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  url: string;
}

export interface DestinationInfoProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  destination_id: number;
  /**
   * destination slugname if pageview is related to a specific destination
   *  Sur certains events, cette propriété ne peut pas être envoyée donc la valeur par défaut est **Undefined**. Nous préferons envoyer une valeur par défaut et que la propriété soit required plutôt qu'optionnelle.
   */
  destination_slug: string;
}

export interface DocumentInformationProperties {
  /**
   * Catégorie de la pièce jointe dans Esper
   */
  document_category: string;
  /**
   * Macro catégorie de la pièce jointe.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | (before trip|in trip|legacy) |
   */
  document_group: string;
}

export interface ItineraryInfoProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itinerary_id?: number;
  itinerary_slug?: string;
}

export interface PaymentFunnelProperties {
  /**
   * Il s'agit de l'uuid de l'échéance à payer. Elle apparaît dans l'url du tunnel de paiement après la vue de l'échéancier
   */
  instalment_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
}

export interface RegionInfoProperties {
  /**
   * Indique l'ID  d'une région
   *
   * Le region_id est différent de la destination_id
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  region_id: number;
  /**
   * Indique le slugname d'une région (ex : Cinque Terre)
   *
   * Pour un Hub région (= une destination de type région), alors il n'y a pas de region_slug mais un destination_slug
   */
  region_slug: string;
}

export interface TripPropertiesProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  request_id: number;
  trip_status: string;
}

export interface TripformPropertiesProperties {
  /**
   * Réponse à la question de maturité du TF : **booking | hesitating | planning**
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, hesitating, planning |
   */
  maturity_answer: "booking" | "hesitating" | "planning";
  /**
   * Type de TF :  **agency | itinerary | scratch**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | agency, itinerary, scratch |
   */
  trip_form_type: "agency" | "itinerary" | "scratch";
}

export interface TripformQualityProperties {
  is_filtered: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   * | Max Value | 14 |
   */
  quality_cluster: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ISSUE ON QUALITY, MEDIUM QUALITY, TOP QUALITY, BAD QUALITY, HIGH QUALITY |
   */
  quality_grouping: "ISSUE ON QUALITY" | "MEDIUM QUALITY" | "TOP QUALITY" | "BAD QUALITY" | "HIGH QUALITY";
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DebugMapLoadFailed implements BaseEvent {
  event_type = '[Debug] map load failed';

  constructor(
    public event_properties: DebugMapLoadFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DebugMapLoadStarted implements BaseEvent {
  event_type = '[Debug] map load started';

  constructor(
    public event_properties: DebugMapLoadStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DebugMapLoadSucceeded implements BaseEvent {
  event_type = '[Debug] map load succeeded';

  constructor(
    public event_properties: DebugMapLoadSucceededProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PyriteFacetClicked implements BaseEvent {
  event_type = '[Pyrite] Facet clicked';

  constructor(
    public event_properties: PyriteFacetClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PyriteMagazineClicked implements BaseEvent {
  event_type = '[Pyrite] Magazine clicked';

  constructor(
    public event_properties: PyriteMagazineClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AbItineraryMoreDetailAgentBlockClicked implements BaseEvent {
  event_type = 'ab_itinerary_more_detail_agent_block_clicked';

  constructor(
    public event_properties: AbItineraryMoreDetailAgentBlockClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccessContentClicked implements BaseEvent {
  event_type = 'Access content clicked';

  constructor(
    public event_properties: AccessContentClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActivitiesMilestoneDisplayed implements BaseEvent {
  event_type = 'Activities milestone displayed';

  constructor(
    public event_properties: ActivitiesMilestoneDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActivityClicked implements BaseEvent {
  event_type = 'Activity clicked';

  constructor(
    public event_properties: ActivityClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActivityListClicked implements BaseEvent {
  event_type = 'Activity list clicked';

  constructor(
    public event_properties: ActivityListClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActivitySearchbarUsed implements BaseEvent {
  event_type = 'Activity searchbar used';

  constructor(
    public event_properties: ActivitySearchbarUsedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActivityTagFilterClicked implements BaseEvent {
  event_type = 'Activity tag filter clicked';

  constructor(
    public event_properties: ActivityTagFilterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActivityTagFilterSelected implements BaseEvent {
  event_type = 'Activity tag filter selected';

  constructor(
    public event_properties: ActivityTagFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddToMyTripClicked implements BaseEvent {
  event_type = 'Add to my trip clicked';

  constructor(
    public event_properties: AddToMyTripClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AdditionalQuestionAnswered implements BaseEvent {
  event_type = 'Additional question answered';

  constructor(
    public event_properties: AdditionalQuestionAnsweredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgenciesDiscoveryClicked implements BaseEvent {
  event_type = 'Agencies discovery clicked';

  constructor(
    public event_properties: AgenciesDiscoveryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgenciesListClicked implements BaseEvent {
  event_type = 'Agencies list clicked';

  constructor(
    public event_properties: AgenciesListClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgencyClicked implements BaseEvent {
  event_type = 'Agency clicked';

  constructor(
    public event_properties: AgencyClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgencyDiscoveryClicked implements BaseEvent {
  event_type = 'Agency discovery clicked';

  constructor(
    public event_properties: AgencyDiscoveryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgencyReviewClicked implements BaseEvent {
  event_type = 'Agency review clicked';

  constructor(
    public event_properties: AgencyReviewClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgencyReviewsFiltered implements BaseEvent {
  event_type = 'Agency reviews filtered';

  constructor(
    public event_properties: AgencyReviewsFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgencyReviewsMore implements BaseEvent {
  event_type = 'Agency reviews more';

  constructor(
    public event_properties: AgencyReviewsMoreProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgencyReviewsSeen implements BaseEvent {
  event_type = 'Agency reviews seen';

  constructor(
    public event_properties: AgencyReviewsSeenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgencyZoneClicked implements BaseEvent {
  event_type = 'Agency zone clicked';

  constructor(
    public event_properties: AgencyZoneClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentClicked implements BaseEvent {
  event_type = 'Agent clicked';

  constructor(
    public event_properties: AgentClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AllThematicsFilterClicked implements BaseEvent {
  event_type = 'All thematics filter clicked';

  constructor(
    public event_properties: AllThematicsFilterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ArticleTagClicked implements BaseEvent {
  event_type = 'Article tag clicked';

  constructor(
    public event_properties: ArticleTagClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AuthenticationSucceed implements BaseEvent {
  event_type = 'Authentication succeed';

  constructor(
    public event_properties: AuthenticationSucceedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BetterTripClicked implements BaseEvent {
  event_type = 'BetterTrip clicked';

  constructor(
    public event_properties: BetterTripClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BlockViewed implements BaseEvent {
  event_type = 'Block viewed';

  constructor(
    public event_properties: BlockViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookmarkActivityClicked implements BaseEvent {
  event_type = 'Bookmark activity clicked';

  constructor(
    public event_properties: BookmarkActivityClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BudgetAdjustmentQuestionAnswered implements BaseEvent {
  event_type = 'Budget adjustment question answered';

  constructor(
    public event_properties: BudgetAdjustmentQuestionAnsweredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BudgetAdjustmentQuestionDisplayed implements BaseEvent {
  event_type = 'Budget adjustment question displayed';

  constructor(
    public event_properties: BudgetAdjustmentQuestionDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BudgetCompleted implements BaseEvent {
  event_type = 'Budget completed';

  constructor(
    public event_properties: BudgetCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BudgetMinOrMaxUsed implements BaseEvent {
  event_type = 'Budget min or max used';

  constructor(
    public event_properties: BudgetMinOrMaxUsedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BudgetPopinSuggestionClosed implements BaseEvent {
  event_type = 'Budget popin suggestion closed';

  constructor(
    public event_properties: BudgetPopinSuggestionClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BudgetPreferenceQuestionAnswered implements BaseEvent {
  event_type = 'Budget preference question answered';

  constructor(
    public event_properties: BudgetPreferenceQuestionAnsweredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BudgetPreferenceQuestionDisplayed implements BaseEvent {
  event_type = 'Budget preference question displayed';

  constructor(
    public event_properties: BudgetPreferenceQuestionDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BudgetRangeQuestionAnswered implements BaseEvent {
  event_type = 'Budget range question answered';

  constructor(
    public event_properties: BudgetRangeQuestionAnsweredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BudgetRulerUsed implements BaseEvent {
  event_type = 'Budget ruler used';

  constructor(
    public event_properties: BudgetRulerUsedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BudgetSuggestionClicked implements BaseEvent {
  event_type = 'Budget suggestion clicked';

  constructor(
    public event_properties: BudgetSuggestionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendlyClicked implements BaseEvent {
  event_type = 'Calendly clicked';

  constructor(
    public event_properties: CalendlyClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendlyDisplayed implements BaseEvent {
  event_type = 'Calendly displayed';

  constructor(
    public event_properties: CalendlyDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendlyMeetingBooked implements BaseEvent {
  event_type = 'Calendly meeting booked';

  constructor(
    public event_properties: CalendlyMeetingBookedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CbPaymentStepCompleted implements BaseEvent {
  event_type = 'CB payment step completed';

  constructor(
    public event_properties: CbPaymentStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CbPaymentStepFailed implements BaseEvent {
  event_type = 'CB payment step failed';

  constructor(
    public event_properties: CbPaymentStepFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CbPaymentSubmitted implements BaseEvent {
  event_type = 'Cb payment submitted';

  constructor(
    public event_properties: CbPaymentSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContinentFiltered implements BaseEvent {
  event_type = 'Continent filtered';

  constructor(
    public event_properties: ContinentFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CtaInspirezMoiClicked implements BaseEvent {
  event_type = 'CTA "inspirez-moi" clicked';

  constructor(
    public event_properties: CtaInspirezMoiClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteActivityClicked implements BaseEvent {
  event_type = 'Delete activity clicked';

  constructor(
    public event_properties: DeleteActivityClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DestinationArticleClicked implements BaseEvent {
  event_type = 'Destination article clicked';

  constructor(
    public event_properties: DestinationArticleClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DestinationArticleListClicked implements BaseEvent {
  event_type = 'Destination article list clicked';

  constructor(
    public event_properties: DestinationArticleListClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DestinationClicked implements BaseEvent {
  event_type = 'Destination clicked';

  constructor(
    public event_properties: DestinationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DestinationDiscoveryClicked implements BaseEvent {
  event_type = 'Destination discovery clicked';

  constructor(
    public event_properties: DestinationDiscoveryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DestinationVisaClicked implements BaseEvent {
  event_type = 'Destination visa clicked';

  constructor(
    public event_properties: DestinationVisaClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DestinationsGuideDownloaded implements BaseEvent {
  event_type = 'Destinations guide downloaded';

  constructor(
    public event_properties: DestinationsGuideDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Et1Displayed implements BaseEvent {
  event_type = 'ET1 displayed';

  constructor(
    public event_properties: Et1DisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExitStoryClicked implements BaseEvent {
  event_type = 'Exit story clicked';

  constructor(
    public event_properties: ExitStoryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FacetClicked implements BaseEvent {
  event_type = 'Facet clicked';

  constructor(
    public event_properties: FacetClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FakeExplorerClicked implements BaseEvent {
  event_type = 'Fake explorer clicked';

  constructor(
    public event_properties: FakeExplorerClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FakeExplorerExitClicked implements BaseEvent {
  event_type = 'Fake explorer exit clicked';

  constructor(
    public event_properties: FakeExplorerExitClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FakeMapClicked implements BaseEvent {
  event_type = 'Fake map clicked';

  constructor(
    public event_properties: FakeMapClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FamilyWithBabiesFilterSelected implements BaseEvent {
  event_type = 'Family with babies filter selected';

  constructor(
    public event_properties: FamilyWithBabiesFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterClicked implements BaseEvent {
  event_type = 'Filter clicked';

  constructor(
    public event_properties: FilterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FlightPriceMonthSelected implements BaseEvent {
  event_type = 'Flight price month selected';

  constructor(
    public event_properties: FlightPriceMonthSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderAllDestinationsClicked implements BaseEvent {
  event_type = 'Header - all destinations clicked';

  constructor(
    public event_properties: HeaderAllDestinationsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderBCorpClicked implements BaseEvent {
  event_type = 'Header - B corp clicked';

  constructor(
    public event_properties: HeaderBCorpClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderCarbonNeutralClicked implements BaseEvent {
  event_type = 'Header - carbon neutral clicked';

  constructor(
    public event_properties: HeaderCarbonNeutralClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderCommitmentsClicked implements BaseEvent {
  event_type = 'Header - commitments clicked';

  constructor(
    public event_properties: HeaderCommitmentsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderConceptClicked implements BaseEvent {
  event_type = 'Header - concept clicked';

  constructor(
    public event_properties: HeaderConceptClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderContinentClicked implements BaseEvent {
  event_type = 'Header - continent clicked';

  constructor(
    public event_properties: HeaderContinentClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderDestinationWheretogoClicked implements BaseEvent {
  event_type = 'Header - destination wheretogo clicked';

  constructor(
    public event_properties: HeaderDestinationWheretogoClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderHelpCenterClicked implements BaseEvent {
  event_type = 'Header - help center clicked';

  constructor(
    public event_properties: HeaderHelpCenterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderHowThematicClicked implements BaseEvent {
  event_type = 'Header - how thematic clicked';

  constructor(
    public event_properties: HeaderHowThematicClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderImpactReportClicked implements BaseEvent {
  event_type = 'Header - impact report clicked';

  constructor(
    public event_properties: HeaderImpactReportClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderLogoClicked implements BaseEvent {
  event_type = 'Header - logo clicked';

  constructor(
    public event_properties: HeaderLogoClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderMagazineClicked implements BaseEvent {
  event_type = 'Header - magazine clicked';

  constructor(
    public event_properties: HeaderMagazineClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderOtherDestinationClicked implements BaseEvent {
  event_type = 'Header - other destination clicked';

  constructor(
    public event_properties: HeaderOtherDestinationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderOurImpactClicked implements BaseEvent {
  event_type = 'Header - our impact clicked';

  constructor(
    public event_properties: HeaderOurImpactClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderRecommendationClicked implements BaseEvent {
  event_type = 'Header - recommendation clicked';

  constructor(
    public event_properties: HeaderRecommendationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderTopDestinationClicked implements BaseEvent {
  event_type = 'Header - top destination clicked';

  constructor(
    public event_properties: HeaderTopDestinationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderWhatThematicClicked implements BaseEvent {
  event_type = 'Header - what thematic clicked';

  constructor(
    public event_properties: HeaderWhatThematicClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderWhenThematicClicked implements BaseEvent {
  event_type = 'Header - when thematic clicked';

  constructor(
    public event_properties: HeaderWhenThematicClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderWheretogoClicked implements BaseEvent {
  event_type = 'Header - wheretogo clicked';

  constructor(
    public event_properties: HeaderWheretogoClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderWhoThematicClicked implements BaseEvent {
  event_type = 'Header - who thematic clicked';

  constructor(
    public event_properties: HeaderWhoThematicClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HelpCenterClicked implements BaseEvent {
  event_type = 'Help center clicked';

  constructor(
    public event_properties: HelpCenterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HelpQuestionCompleted implements BaseEvent {
  event_type = 'Help question completed';

  constructor(
    public event_properties: HelpQuestionCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HelpSuggestionQuestionCompleted implements BaseEvent {
  event_type = 'Help suggestion question completed';

  constructor(
    public event_properties: HelpSuggestionQuestionCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HubClicked implements BaseEvent {
  event_type = 'Hub clicked';

  constructor(
    public event_properties: HubClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ImpactReportClicked implements BaseEvent {
  event_type = 'Impact report clicked';

  constructor(
    public event_properties: ImpactReportClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InformationStepCompleted implements BaseEvent {
  event_type = 'Information step completed';

  constructor(
    public event_properties: InformationStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InsuranceStepCompleted implements BaseEvent {
  event_type = 'Insurance step completed';

  constructor(
    public event_properties: InsuranceStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InsuranceStepMoreDetailsButtonClicked implements BaseEvent {
  event_type = 'Insurance step more details button clicked';

  constructor(
    public event_properties: InsuranceStepMoreDetailsButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InsuranceStepOtherOffersClicked implements BaseEvent {
  event_type = 'Insurance step other offers clicked';

  constructor(
    public event_properties: InsuranceStepOtherOffersClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InsuranceStepProductCancellationClicked implements BaseEvent {
  event_type = 'Insurance step product cancellation clicked';

  constructor(
    public event_properties: InsuranceStepProductCancellationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InsuranceStepProductMultipleRisksClicked implements BaseEvent {
  event_type = 'Insurance step product multiple risks clicked';

  constructor(
    public event_properties: InsuranceStepProductMultipleRisksClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InsuranceStepProductPremiumAssistanceClicked implements BaseEvent {
  event_type = 'Insurance step product premium assistance clicked';

  constructor(
    public event_properties: InsuranceStepProductPremiumAssistanceClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InsuranceStepRefusalClicked implements BaseEvent {
  event_type = 'Insurance step refusal clicked';

  constructor(
    public event_properties: InsuranceStepRefusalClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InternationalFlightHelpCenterClicked implements BaseEvent {
  event_type = 'International flight help center clicked';

  constructor(
    public event_properties: InternationalFlightHelpCenterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ItinerariesListClicked implements BaseEvent {
  event_type = 'Itineraries list clicked';

  constructor(
    public event_properties: ItinerariesListClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ItineraryClicked implements BaseEvent {
  event_type = 'Itinerary clicked';

  constructor(
    public event_properties: ItineraryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ItineraryDetailsClicked implements BaseEvent {
  event_type = 'Itinerary details clicked';

  constructor(
    public event_properties: ItineraryDetailsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ItineraryStepClick implements BaseEvent {
  event_type = 'Itinerary step click';

  constructor(
    public event_properties: ItineraryStepClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ItineraryWishlistAdded implements BaseEvent {
  event_type = 'Itinerary wishlist added';

  constructor(
    public event_properties: ItineraryWishlistAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListActivityOnboardingSkipClicked implements BaseEvent {
  event_type = 'List activity onboarding skip clicked';

  constructor(
    public event_properties: ListActivityOnboardingSkipClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListActivityOnboardingStartClicked implements BaseEvent {
  event_type = 'List activity onboarding start clicked';

  constructor(
    public event_properties: ListActivityOnboardingStartClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListActivityOnboardingStep1Viewed implements BaseEvent {
  event_type = 'List activity onboarding step 1 viewed';

  constructor(
    public event_properties: ListActivityOnboardingStep1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListActivityOnboardingStep2Viewed implements BaseEvent {
  event_type = 'List activity onboarding step 2 viewed';

  constructor(
    public event_properties: ListActivityOnboardingStep2ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListActivityOnboardingStep3Viewed implements BaseEvent {
  event_type = 'List activity onboarding step 3 viewed';

  constructor(
    public event_properties: ListActivityOnboardingStep3ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListItinerariesFilterClicked implements BaseEvent {
  event_type = 'List itineraries filter clicked';

  constructor(
    public event_properties: ListItinerariesFilterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListItinerariesFilterModalClosed implements BaseEvent {
  event_type = 'List itineraries filter modal closed';

  constructor(
    public event_properties: ListItinerariesFilterModalClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListItinerariesFilterSelected implements BaseEvent {
  event_type = 'List itineraries filter selected';

  constructor(
    public event_properties: ListItinerariesFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MagazineClicked implements BaseEvent {
  event_type = 'Magazine clicked';

  constructor(
    public event_properties: MagazineClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MapDraggedOrDropped implements BaseEvent {
  event_type = 'Map dragged or dropped';

  constructor(
    public event_properties: MapDraggedOrDroppedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MapZoomed implements BaseEvent {
  event_type = 'Map zoomed';

  constructor(
    public event_properties: MapZoomedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MarketingDestinationDetailsCtaClicked implements BaseEvent {
  event_type = 'Marketing destination details CTA clicked';

  constructor(
    public event_properties: MarketingDestinationDetailsCtaClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MarketingTopDestinationClicked implements BaseEvent {
  event_type = 'Marketing top destination clicked';

  constructor(
    public event_properties: MarketingTopDestinationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MaturityQuestionCompleted implements BaseEvent {
  event_type = 'Maturity question completed';

  constructor(
    public event_properties: MaturityQuestionCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ModalRedirectionRecoPageClicked implements BaseEvent {
  event_type = 'Modal redirection reco page clicked';

  constructor(
    public event_properties: ModalRedirectionRecoPageClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ModalRedirectionRecoPageClosed implements BaseEvent {
  event_type = 'Modal redirection reco page closed';

  constructor(
    public event_properties: ModalRedirectionRecoPageClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ModalRedirectionRecoPageDisplayed implements BaseEvent {
  event_type = 'Modal redirection reco page displayed';

  constructor(
    public event_properties: ModalRedirectionRecoPageDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ModalSiteSuggestionClosed implements BaseEvent {
  event_type = 'Modal site suggestion closed';

  constructor(
    public event_properties: ModalSiteSuggestionClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ModalSiteSuggestionDisplayed implements BaseEvent {
  event_type = 'Modal site suggestion displayed';

  constructor(
    public event_properties: ModalSiteSuggestionDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MoreDetailsClicked implements BaseEvent {
  event_type = 'More details clicked';

  constructor(
    public event_properties: MoreDetailsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MoreItinerariesClicked implements BaseEvent {
  event_type = 'More itineraries clicked';

  constructor(
    public event_properties: MoreItinerariesClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MorePhotoClicked implements BaseEvent {
  event_type = 'More photo clicked';

  constructor(
    public event_properties: MorePhotoClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MoreTestimonialsClicked implements BaseEvent {
  event_type = 'More testimonials clicked';

  constructor(
    public event_properties: MoreTestimonialsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripAgencyDiscussionClicked implements BaseEvent {
  event_type = 'My trip - Agency discussion clicked';

  constructor(
    public event_properties: MyTripAgencyDiscussionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripAgencyInformationClicked implements BaseEvent {
  event_type = 'My trip - Agency information clicked';

  constructor(
    public event_properties: MyTripAgencyInformationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripAppDownloadClicked implements BaseEvent {
  event_type = 'My trip - App download clicked';

  constructor(
    public event_properties: MyTripAppDownloadClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripAskAgentClicked implements BaseEvent {
  event_type = 'My Trip - Ask agent clicked';

  constructor(
    public event_properties: MyTripAskAgentClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripAskAgentClosedClicked implements BaseEvent {
  event_type = 'My Trip - Ask agent closed clicked';

  constructor(
    public event_properties: MyTripAskAgentClosedClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripAskAgentSentClicked implements BaseEvent {
  event_type = 'My Trip - Ask agent sent clicked';

  constructor(
    public event_properties: MyTripAskAgentSentClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripAssistantClosedClicked implements BaseEvent {
  event_type = 'My Trip - Assistant closed clicked';

  constructor(
    public event_properties: MyTripAssistantClosedClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripAssistantOpenedClicked implements BaseEvent {
  event_type = 'My Trip - Assistant opened clicked';

  constructor(
    public event_properties: MyTripAssistantOpenedClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripCalendlyFormSubmitted implements BaseEvent {
  event_type = 'My trip - Calendly form submitted';

  constructor(
    public event_properties: MyTripCalendlyFormSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripCallButtonClicked implements BaseEvent {
  event_type = 'My trip - Call button clicked';

  constructor(
    public event_properties: MyTripCallButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripCoTravelerInvited implements BaseEvent {
  event_type = 'My trip - Co traveler invited';

  constructor(
    public event_properties: MyTripCoTravelerInvitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripEmailClicked implements BaseEvent {
  event_type = 'My trip - Email clicked';

  constructor(
    public event_properties: MyTripEmailClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripEvaneosNumberCalled implements BaseEvent {
  event_type = 'My trip - Evaneos number called';

  constructor(
    public event_properties: MyTripEvaneosNumberCalledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripExploreFlightsClicked implements BaseEvent {
  event_type = 'My Trip - Explore flights clicked';

  constructor(
    public event_properties: MyTripExploreFlightsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripFlightClicked implements BaseEvent {
  event_type = 'My Trip - Flight clicked';

  constructor(
    public event_properties: MyTripFlightClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripFlightInformationClicked implements BaseEvent {
  event_type = 'My trip - Flight information clicked';

  constructor(
    public event_properties: MyTripFlightInformationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripHelpCenterClicked implements BaseEvent {
  event_type = 'My trip - Help center clicked';

  constructor(
    public event_properties: MyTripHelpCenterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripIUnderstandClicked implements BaseEvent {
  event_type = 'My trip - I understand clicked';

  constructor(
    public event_properties: MyTripIUnderstandClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripInsuranceInformationClicked implements BaseEvent {
  event_type = 'My trip - Insurance information clicked';

  constructor(
    public event_properties: MyTripInsuranceInformationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripMessageSent implements BaseEvent {
  event_type = 'My trip - Message sent';

  constructor(
    public event_properties: MyTripMessageSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripMoreDetailsClicked implements BaseEvent {
  event_type = 'My trip - More details clicked';

  constructor(
    public event_properties: MyTripMoreDetailsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripNoFlightClicked implements BaseEvent {
  event_type = 'My Trip - No flight clicked';

  constructor(
    public event_properties: MyTripNoFlightClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripNonUsefulClicked implements BaseEvent {
  event_type = 'My trip - Non-useful clicked';

  constructor(
    public event_properties: MyTripNonUsefulClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripPaymentScheduleClicked implements BaseEvent {
  event_type = 'My trip - Payment schedule clicked';

  constructor(
    public event_properties: MyTripPaymentScheduleClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripScheduleAppointmentClicked implements BaseEvent {
  event_type = 'My trip - Schedule appointment clicked';

  constructor(
    public event_properties: MyTripScheduleAppointmentClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripSelectionProcessClicked implements BaseEvent {
  event_type = 'My trip - Selection process clicked';

  constructor(
    public event_properties: MyTripSelectionProcessClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripSendEmailClicked implements BaseEvent {
  event_type = 'My trip - Send email clicked';

  constructor(
    public event_properties: MyTripSendEmailClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripSendInfoClicked implements BaseEvent {
  event_type = 'My Trip - Send info clicked';

  constructor(
    public event_properties: MyTripSendInfoClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripSendInfoClosedClicked implements BaseEvent {
  event_type = 'My Trip - Send info closed clicked';

  constructor(
    public event_properties: MyTripSendInfoClosedClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripSendInfoSentClicked implements BaseEvent {
  event_type = 'My Trip - Send info sent clicked';

  constructor(
    public event_properties: MyTripSendInfoSentClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripSharedDocumentDownloaded implements BaseEvent {
  event_type = 'My trip - Shared document downloaded';

  constructor(
    public event_properties: MyTripSharedDocumentDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripSharedDocumentViewed implements BaseEvent {
  event_type = 'My trip - Shared document viewed';

  constructor(
    public event_properties: MyTripSharedDocumentViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripTestimonialsClicked implements BaseEvent {
  event_type = 'My trip - Testimonials clicked';

  constructor(
    public event_properties: MyTripTestimonialsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripUsefulClicked implements BaseEvent {
  event_type = 'My trip - Useful clicked';

  constructor(
    public event_properties: MyTripUsefulClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripVisaInformationClicked implements BaseEvent {
  event_type = 'My trip - Visa information clicked';

  constructor(
    public event_properties: MyTripVisaInformationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripWifiInformationClicked implements BaseEvent {
  event_type = 'My trip - Wifi information clicked';

  constructor(
    public event_properties: MyTripWifiInformationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripActivityPlaceholderClicked implements BaseEvent {
  event_type = 'My Trip activity placeholder clicked';

  constructor(
    public event_properties: MyTripActivityPlaceholderClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripButtonClicked implements BaseEvent {
  event_type = 'My trip button clicked';

  constructor(
    public event_properties: MyTripButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripButtonDisplayed implements BaseEvent {
  event_type = 'My trip button displayed';

  constructor(
    public event_properties: MyTripButtonDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyTripsClicked implements BaseEvent {
  event_type = 'My trips clicked';

  constructor(
    public event_properties: MyTripsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarAgenciesClicked implements BaseEvent {
  event_type = 'Navbar - agencies clicked';

  constructor(
    public event_properties: NavbarAgenciesClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarDestinationClicked implements BaseEvent {
  event_type = 'Navbar - destination clicked';

  constructor(
    public event_properties: NavbarDestinationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarExperienceClicked implements BaseEvent {
  event_type = 'Navbar - experience clicked';

  constructor(
    public event_properties: NavbarExperienceClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarFacetClicked implements BaseEvent {
  event_type = 'Navbar - facet clicked';

  constructor(
    public event_properties: NavbarFacetClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarGroupClicked implements BaseEvent {
  event_type = 'Navbar - group clicked';

  constructor(
    public event_properties: NavbarGroupClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarInspirationClicked implements BaseEvent {
  event_type = 'Navbar - inspiration clicked';

  constructor(
    public event_properties: NavbarInspirationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarListItinerariesClicked implements BaseEvent {
  event_type = 'Navbar - list itineraries clicked';

  constructor(
    public event_properties: NavbarListItinerariesClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarPlacesClicked implements BaseEvent {
  event_type = 'Navbar - places clicked';

  constructor(
    public event_properties: NavbarPlacesClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarPracticalDetailsClicked implements BaseEvent {
  event_type = 'Navbar - practical details clicked';

  constructor(
    public event_properties: NavbarPracticalDetailsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarRegionClicked implements BaseEvent {
  event_type = 'Navbar - region clicked';

  constructor(
    public event_properties: NavbarRegionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarTestimonialClicked implements BaseEvent {
  event_type = 'Navbar - testimonial clicked';

  constructor(
    public event_properties: NavbarTestimonialClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarTravelGuideClicked implements BaseEvent {
  event_type = 'Navbar - travel guide clicked';

  constructor(
    public event_properties: NavbarTravelGuideClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarItiBudgetClicked implements BaseEvent {
  event_type = 'Navbar iti - budget clicked';

  constructor(
    public event_properties: NavbarItiBudgetClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarItiCircuitClicked implements BaseEvent {
  event_type = 'Navbar iti - circuit clicked';

  constructor(
    public event_properties: NavbarItiCircuitClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarItiDetailsClicked implements BaseEvent {
  event_type = 'Navbar iti - details clicked';

  constructor(
    public event_properties: NavbarItiDetailsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarItiTransportClicked implements BaseEvent {
  event_type = 'Navbar iti - transport clicked';

  constructor(
    public event_properties: NavbarItiTransportClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NeedsFiltered implements BaseEvent {
  event_type = 'Needs filtered';

  constructor(
    public event_properties: NeedsFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NewsletterSubscribed implements BaseEvent {
  event_type = 'Newsletter subscribed';

  constructor(
    public event_properties: NewsletterSubscribedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NextCarouselClicked implements BaseEvent {
  event_type = 'Next carousel clicked';

  constructor(
    public event_properties: NextCarouselClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NextStoryClicked implements BaseEvent {
  event_type = 'Next story clicked';

  constructor(
    public event_properties: NextStoryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NonUsefulButtonClicked implements BaseEvent {
  event_type = 'Non-useful button clicked';

  constructor(
    public event_properties: NonUsefulButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpinionPoiSearched implements BaseEvent {
  event_type = 'Opinion - POI searched';

  constructor(
    public event_properties: OpinionPoiSearchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpinionPoiTestimonialPublished implements BaseEvent {
  event_type = 'Opinion - POI testimonial published';

  constructor(
    public event_properties: OpinionPoiTestimonialPublishedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PageShared implements BaseEvent {
  event_type = 'Page shared';

  constructor(
    public event_properties: PageSharedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PaginationClicked implements BaseEvent {
  event_type = 'Pagination clicked';

  constructor(
    public event_properties: PaginationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PartnershipClicked implements BaseEvent {
  event_type = 'Partnership clicked';

  constructor(
    public event_properties: PartnershipClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderAccommodationsDefined implements BaseEvent {
  event_type = 'Pathfinder - accommodations defined';

  constructor(
    public event_properties: PathfinderAccommodationsDefinedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderAccommodationsQuestionAsked implements BaseEvent {
  event_type = 'Pathfinder - accommodations question asked';

  constructor(
    public event_properties: PathfinderAccommodationsQuestionAskedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderActivitiesDefined implements BaseEvent {
  event_type = 'Pathfinder - activities defined';

  constructor(
    public event_properties: PathfinderActivitiesDefinedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderActivitiesQuestionAsked implements BaseEvent {
  event_type = 'Pathfinder - activities question asked';

  constructor(
    public event_properties: PathfinderActivitiesQuestionAskedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderAdviceAsked implements BaseEvent {
  event_type = 'Pathfinder - advice asked';

  constructor(
    public event_properties: PathfinderAdviceAskedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderAdviceGiven implements BaseEvent {
  event_type = 'Pathfinder - advice given';

  constructor(
    public event_properties: PathfinderAdviceGivenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderAgencyPresentationDisplayed implements BaseEvent {
  event_type = 'Pathfinder - agency presentation displayed';

  constructor(
    public event_properties: PathfinderAgencyPresentationDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderAlternativeDestinationSelected implements BaseEvent {
  event_type = 'Pathfinder - alternative destination selected';

  constructor(
    public event_properties: PathfinderAlternativeDestinationSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderAutonomousBannerDownloadClicked implements BaseEvent {
  event_type = 'Pathfinder - autonomous banner download clicked';

  constructor(
    public event_properties: PathfinderAutonomousBannerDownloadClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderAutonomousSurveyAnswerSelected implements BaseEvent {
  event_type = 'Pathfinder - autonomous survey answer selected';

  constructor(
    public event_properties: PathfinderAutonomousSurveyAnswerSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderBudgetAlternativesDisplayed implements BaseEvent {
  event_type = 'Pathfinder - budget alternatives displayed';

  constructor(
    public event_properties: PathfinderBudgetAlternativesDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderBudgetComponentDisplayed implements BaseEvent {
  event_type = 'Pathfinder - budget component displayed';

  constructor(
    public event_properties: PathfinderBudgetComponentDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderBudgetConfirmed implements BaseEvent {
  event_type = 'Pathfinder - budget confirmed';

  constructor(
    public event_properties: PathfinderBudgetConfirmedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderBudgetDefined implements BaseEvent {
  event_type = 'Pathfinder - budget defined';

  constructor(
    public event_properties: PathfinderBudgetDefinedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderBudgetQuestionAsked implements BaseEvent {
  event_type = 'Pathfinder - budget question asked';

  constructor(
    public event_properties: PathfinderBudgetQuestionAskedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderBudgetTypeSelected implements BaseEvent {
  event_type = 'Pathfinder - budget type selected';

  constructor(
    public event_properties: PathfinderBudgetTypeSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderBudgetUnknownSelected implements BaseEvent {
  event_type = 'Pathfinder - budget unknown selected';

  constructor(
    public event_properties: PathfinderBudgetUnknownSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderContactAgentSelected implements BaseEvent {
  event_type = 'Pathfinder - contact agent selected';

  constructor(
    public event_properties: PathfinderContactAgentSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderDatesDefined implements BaseEvent {
  event_type = 'Pathfinder - dates defined';

  constructor(
    public event_properties: PathfinderDatesDefinedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderDestinationPlanTripClicked implements BaseEvent {
  event_type = 'Pathfinder - destination plan trip clicked';

  constructor(
    public event_properties: PathfinderDestinationPlanTripClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderDurationDefined implements BaseEvent {
  event_type = 'Pathfinder - duration defined';

  constructor(
    public event_properties: PathfinderDurationDefinedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderExperiencesRecommended implements BaseEvent {
  event_type = 'Pathfinder - experiences recommended';

  constructor(
    public event_properties: PathfinderExperiencesRecommendedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderExperiencesSelected implements BaseEvent {
  event_type = 'Pathfinder - experiences selected';

  constructor(
    public event_properties: PathfinderExperiencesSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderFirstMessageSent implements BaseEvent {
  event_type = 'Pathfinder - first message sent';

  constructor(
    public event_properties: PathfinderFirstMessageSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderGroupCompositionQuestionAsked implements BaseEvent {
  event_type = 'Pathfinder - group composition question asked';

  constructor(
    public event_properties: PathfinderGroupCompositionQuestionAskedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderGroupDefined implements BaseEvent {
  event_type = 'Pathfinder - group defined';

  constructor(
    public event_properties: PathfinderGroupDefinedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderHasMinimumBudgetSelected implements BaseEvent {
  event_type = 'Pathfinder - has minimum budget selected';

  constructor(
    public event_properties: PathfinderHasMinimumBudgetSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderInspirationMessageSent implements BaseEvent {
  event_type = 'Pathfinder - inspiration message sent';

  constructor(
    public event_properties: PathfinderInspirationMessageSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderInspirationSurveyAnswered implements BaseEvent {
  event_type = 'Pathfinder - inspiration survey answered';

  constructor(
    public event_properties: PathfinderInspirationSurveyAnsweredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderItineraryCommented implements BaseEvent {
  event_type = 'Pathfinder - itinerary commented';

  constructor(
    public event_properties: PathfinderItineraryCommentedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderItineraryViewed implements BaseEvent {
  event_type = 'Pathfinder - itinerary viewed';

  constructor(
    public event_properties: PathfinderItineraryViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderMessageSent implements BaseEvent {
  event_type = 'Pathfinder - message sent';

  constructor(
    public event_properties: PathfinderMessageSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderNavInsightsIntents implements BaseEvent {
  event_type = 'Pathfinder - nav insights intents';

  constructor(
    public event_properties: PathfinderNavInsightsIntentsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderNoAlternativeDestinationSelected implements BaseEvent {
  event_type = 'Pathfinder - no alternative destination selected';

  constructor(
    public event_properties: PathfinderNoAlternativeDestinationSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderNoAlternativeSelected implements BaseEvent {
  event_type = 'Pathfinder - no alternative selected';

  constructor(
    public event_properties: PathfinderNoAlternativeSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderOnboardingQuestionAnswered implements BaseEvent {
  event_type = 'Pathfinder - onboarding question answered';

  constructor(
    public event_properties: PathfinderOnboardingQuestionAnsweredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderProjectInvalidated implements BaseEvent {
  event_type = 'Pathfinder - project invalidated';

  constructor(
    public event_properties: PathfinderProjectInvalidatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderProjectValidated implements BaseEvent {
  event_type = 'Pathfinder - project validated';

  constructor(
    public event_properties: PathfinderProjectValidatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderProjectValidationDisplayed implements BaseEvent {
  event_type = 'Pathfinder - project validation displayed';

  constructor(
    public event_properties: PathfinderProjectValidationDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderRequestSent implements BaseEvent {
  event_type = 'Pathfinder - request sent';

  constructor(
    public event_properties: PathfinderRequestSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderTravelDatesQuestionAsked implements BaseEvent {
  event_type = 'Pathfinder - travel dates question asked';

  constructor(
    public event_properties: PathfinderTravelDatesQuestionAskedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderTravelDurationQuestionAsked implements BaseEvent {
  event_type = 'Pathfinder - travel duration question asked';

  constructor(
    public event_properties: PathfinderTravelDurationQuestionAskedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderViewAlternativeDestinationsSelected implements BaseEvent {
  event_type = 'Pathfinder - view alternative destinations selected';

  constructor(
    public event_properties: PathfinderViewAlternativeDestinationsSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderViewBudgetAlternativesSelected implements BaseEvent {
  event_type = 'Pathfinder - view budget alternatives selected';

  constructor(
    public event_properties: PathfinderViewBudgetAlternativesSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderViewItinerarySelected implements BaseEvent {
  event_type = 'Pathfinder - view itinerary selected';

  constructor(
    public event_properties: PathfinderViewItinerarySelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PathfinderViewOtherExperiencesSelected implements BaseEvent {
  event_type = 'Pathfinder - view other experiences selected';

  constructor(
    public event_properties: PathfinderViewOtherExperiencesSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayButtonOnPaymentScheduleClicked implements BaseEvent {
  event_type = 'Pay button on payment schedule clicked';

  constructor(
    public event_properties: PayButtonOnPaymentScheduleClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PaymentInformationStepCompleted implements BaseEvent {
  event_type = 'Payment information step completed';

  constructor(
    public event_properties: PaymentInformationStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PaymentInformationStepFailed implements BaseEvent {
  event_type = 'Payment information step failed';

  constructor(
    public event_properties: PaymentInformationStepFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PaymentTypeChanged implements BaseEvent {
  event_type = 'Payment type changed';

  constructor(
    public event_properties: PaymentTypeChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PeriodFiltered implements BaseEvent {
  event_type = 'Period filtered';

  constructor(
    public event_properties: PeriodFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PersonalInformationAdviceEmailsClicked implements BaseEvent {
  event_type = 'Personal information - Advice emails clicked';

  constructor(
    public event_properties: PersonalInformationAdviceEmailsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PersonalInformationInformationRegistered implements BaseEvent {
  event_type = 'Personal information - Information registered';

  constructor(
    public event_properties: PersonalInformationInformationRegisteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PersonalInformationNewsletterEmailsClicked implements BaseEvent {
  event_type = 'Personal information - Newsletter emails clicked';

  constructor(
    public event_properties: PersonalInformationNewsletterEmailsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PersonalInformationPasswordRegistred implements BaseEvent {
  event_type = 'Personal information - Password registred';

  constructor(
    public event_properties: PersonalInformationPasswordRegistredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PersonalInformationSmsNotificationsClicked implements BaseEvent {
  event_type = 'Personal information - Sms notifications clicked';

  constructor(
    public event_properties: PersonalInformationSmsNotificationsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PoiClicked implements BaseEvent {
  event_type = 'POI clicked';

  constructor(
    public event_properties: PoiClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PoiClusterClicked implements BaseEvent {
  event_type = 'POI cluster clicked';

  constructor(
    public event_properties: PoiClusterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PoiListClicked implements BaseEvent {
  event_type = 'POI list clicked';

  constructor(
    public event_properties: PoiListClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PopInLoginDisplayed implements BaseEvent {
  event_type = 'Pop-in login displayed';

  constructor(
    public event_properties: PopInLoginDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PopinDetailsItineraryClosed implements BaseEvent {
  event_type = 'Popin details itinerary closed';

  constructor(
    public event_properties: PopinDetailsItineraryClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PopinInfosDestinationClosed implements BaseEvent {
  event_type = 'Popin infos destination closed';

  constructor(
    public event_properties: PopinInfosDestinationClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PopinNewsletterClosed implements BaseEvent {
  event_type = 'Popin newsletter closed';

  constructor(
    public event_properties: PopinNewsletterClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PopinNewsletterDisplayed implements BaseEvent {
  event_type = 'Popin newsletter displayed';

  constructor(
    public event_properties: PopinNewsletterDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PopinSaveActivitiesClosed implements BaseEvent {
  event_type = 'Popin save activities closed';

  constructor(
    public event_properties: PopinSaveActivitiesClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PopinSaveActivitiesDisplayed implements BaseEvent {
  event_type = 'Popin save activities displayed';

  constructor(
    public event_properties: PopinSaveActivitiesDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PracticalInformationClicked implements BaseEvent {
  event_type = 'Practical information clicked';

  constructor(
    public event_properties: PracticalInformationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PracticalInformationDisplayed implements BaseEvent {
  event_type = 'Practical information displayed';

  constructor(
    public event_properties: PracticalInformationDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PreferencesChoiceViewed implements BaseEvent {
  event_type = 'Preferences choice viewed';

  constructor(
    public event_properties: PreferencesChoiceViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PreferencesClosed implements BaseEvent {
  event_type = 'Preferences closed';

  constructor(
    public event_properties: PreferencesClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PreferencesClosedWithoutSave implements BaseEvent {
  event_type = 'Preferences closed without save';

  constructor(
    public event_properties: PreferencesClosedWithoutSaveProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PreferencesUpdated implements BaseEvent {
  event_type = 'Preferences updated';

  constructor(
    public event_properties: PreferencesUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PreviousStoryClicked implements BaseEvent {
  event_type = 'Previous story clicked';

  constructor(
    public event_properties: PreviousStoryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavAccountClicked implements BaseEvent {
  event_type = 'Primary nav - account clicked';

  constructor(
    public event_properties: PrimaryNavAccountClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavAgenciesListClicked implements BaseEvent {
  event_type = 'Primary nav - agencies list clicked';

  constructor(
    public event_properties: PrimaryNavAgenciesListClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavAllDestinationsClicked implements BaseEvent {
  event_type = 'Primary nav - all destinations clicked';

  constructor(
    public event_properties: PrimaryNavAllDestinationsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavBCorpClicked implements BaseEvent {
  event_type = 'Primary nav - B corp clicked';

  constructor(
    public event_properties: PrimaryNavBCorpClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavCarbonNeutralClicked implements BaseEvent {
  event_type = 'Primary nav - carbon neutral clicked';

  constructor(
    public event_properties: PrimaryNavCarbonNeutralClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavCommitmentsClicked implements BaseEvent {
  event_type = 'Primary nav - commitments clicked';

  constructor(
    public event_properties: PrimaryNavCommitmentsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavConceptClicked implements BaseEvent {
  event_type = 'Primary nav - concept clicked';

  constructor(
    public event_properties: PrimaryNavConceptClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavContinentClicked implements BaseEvent {
  event_type = 'Primary nav - continent clicked';

  constructor(
    public event_properties: PrimaryNavContinentClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavContinentSelected implements BaseEvent {
  event_type = 'Primary nav - continent selected';

  constructor(
    public event_properties: PrimaryNavContinentSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavDestinationClicked implements BaseEvent {
  event_type = 'Primary nav - destination clicked';

  constructor(
    public event_properties: PrimaryNavDestinationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavDestinationRecommendationClicked implements BaseEvent {
  event_type = 'Primary nav - destination recommendation clicked';

  constructor(
    public event_properties: PrimaryNavDestinationRecommendationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavDestinationWheretogoClicked implements BaseEvent {
  event_type = 'Primary nav - destination wheretogo clicked';

  constructor(
    public event_properties: PrimaryNavDestinationWheretogoClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavExploreClicked implements BaseEvent {
  event_type = 'Primary nav - explore clicked';

  constructor(
    public event_properties: PrimaryNavExploreClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavFavoriteClicked implements BaseEvent {
  event_type = 'Primary nav - favorite clicked';

  constructor(
    public event_properties: PrimaryNavFavoriteClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavHelpCenterClicked implements BaseEvent {
  event_type = 'Primary nav - help center clicked';

  constructor(
    public event_properties: PrimaryNavHelpCenterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavHubDestinationClicked implements BaseEvent {
  event_type = 'Primary nav - hub destination clicked';

  constructor(
    public event_properties: PrimaryNavHubDestinationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavImpactReportClicked implements BaseEvent {
  event_type = 'Primary nav - impact report clicked';

  constructor(
    public event_properties: PrimaryNavImpactReportClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavItinerariesListClicked implements BaseEvent {
  event_type = 'Primary nav - itineraries list clicked';

  constructor(
    public event_properties: PrimaryNavItinerariesListClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavLogoClicked implements BaseEvent {
  event_type = 'Primary nav - logo clicked';

  constructor(
    public event_properties: PrimaryNavLogoClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavLogoutClicked implements BaseEvent {
  event_type = 'Primary nav - logout clicked';

  constructor(
    public event_properties: PrimaryNavLogoutClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavMagazineClicked implements BaseEvent {
  event_type = 'Primary nav - magazine clicked';

  constructor(
    public event_properties: PrimaryNavMagazineClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavOtherDestinationClicked implements BaseEvent {
  event_type = 'Primary nav - other destination clicked';

  constructor(
    public event_properties: PrimaryNavOtherDestinationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavOurImpactClicked implements BaseEvent {
  event_type = 'Primary nav - our impact clicked';

  constructor(
    public event_properties: PrimaryNavOurImpactClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavPersonalInfoClicked implements BaseEvent {
  event_type = 'Primary nav - personal info clicked';

  constructor(
    public event_properties: PrimaryNavPersonalInfoClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavPoiListClicked implements BaseEvent {
  event_type = 'Primary nav - POI list clicked';

  constructor(
    public event_properties: PrimaryNavPoiListClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavPreviousStepClicked implements BaseEvent {
  event_type = 'Primary nav - previous step clicked';

  constructor(
    public event_properties: PrimaryNavPreviousStepClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavRecommendationClicked implements BaseEvent {
  event_type = 'Primary nav - recommendation clicked';

  constructor(
    public event_properties: PrimaryNavRecommendationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavRegionClicked implements BaseEvent {
  event_type = 'Primary nav - region clicked';

  constructor(
    public event_properties: PrimaryNavRegionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavTestimonialClicked implements BaseEvent {
  event_type = 'Primary nav - testimonial clicked';

  constructor(
    public event_properties: PrimaryNavTestimonialClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavThematicClicked implements BaseEvent {
  event_type = 'Primary nav - thematic clicked';

  constructor(
    public event_properties: PrimaryNavThematicClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavThematicSelected implements BaseEvent {
  event_type = 'Primary nav - thematic selected';

  constructor(
    public event_properties: PrimaryNavThematicSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavTopDestinationClicked implements BaseEvent {
  event_type = 'Primary nav - top destination clicked';

  constructor(
    public event_properties: PrimaryNavTopDestinationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavTravelGuideClicked implements BaseEvent {
  event_type = 'Primary nav - travel guide clicked';

  constructor(
    public event_properties: PrimaryNavTravelGuideClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavTripsListClicked implements BaseEvent {
  event_type = 'Primary nav - trips list clicked';

  constructor(
    public event_properties: PrimaryNavTripsListClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavWhentogoClicked implements BaseEvent {
  event_type = 'Primary nav - whentogo clicked';

  constructor(
    public event_properties: PrimaryNavWhentogoClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrimaryNavWheretogoClicked implements BaseEvent {
  event_type = 'Primary nav - wheretogo clicked';

  constructor(
    public event_properties: PrimaryNavWheretogoClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PromotionCardClicked implements BaseEvent {
  event_type = 'Promotion card clicked';

  constructor(
    public event_properties: PromotionCardClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuestionClicked implements BaseEvent {
  event_type = 'Question clicked';

  constructor(
    public event_properties: QuestionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationClicked implements BaseEvent {
  event_type = 'Recommendation clicked';

  constructor(
    public event_properties: RecommendationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationModalAgencyClosed implements BaseEvent {
  event_type = 'Recommendation modal agency closed';

  constructor(
    public event_properties: RecommendationModalAgencyClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationModalAgencyDisplayed implements BaseEvent {
  event_type = 'Recommendation modal agency displayed';

  constructor(
    public event_properties: RecommendationModalAgencyDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationModalClosed implements BaseEvent {
  event_type = 'Recommendation modal closed';

  constructor(
    public event_properties: RecommendationModalClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationModalDestinationClosed implements BaseEvent {
  event_type = 'Recommendation modal destination closed';

  constructor(
    public event_properties: RecommendationModalDestinationClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationModalDestinationDisplayed implements BaseEvent {
  event_type = 'Recommendation modal destination displayed';

  constructor(
    public event_properties: RecommendationModalDestinationDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationModalDisplayed implements BaseEvent {
  event_type = 'Recommendation modal displayed';

  constructor(
    public event_properties: RecommendationModalDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationModalInspirationClosed implements BaseEvent {
  event_type = 'Recommendation modal inspiration closed';

  constructor(
    public event_properties: RecommendationModalInspirationClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationModalInspirationDisplayed implements BaseEvent {
  event_type = 'Recommendation modal inspiration displayed';

  constructor(
    public event_properties: RecommendationModalInspirationDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationModalNextClicked implements BaseEvent {
  event_type = 'Recommendation modal next clicked';

  constructor(
    public event_properties: RecommendationModalNextClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationModalPreviousClicked implements BaseEvent {
  event_type = 'Recommendation modal previous clicked';

  constructor(
    public event_properties: RecommendationModalPreviousClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationModalSupportClosed implements BaseEvent {
  event_type = 'Recommendation modal support closed';

  constructor(
    public event_properties: RecommendationModalSupportClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationModalSupportDisplayed implements BaseEvent {
  event_type = 'Recommendation modal support displayed';

  constructor(
    public event_properties: RecommendationModalSupportDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationPageTeaserClicked implements BaseEvent {
  event_type = 'Recommendation page teaser clicked';

  constructor(
    public event_properties: RecommendationPageTeaserClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationPageTeaserClosed implements BaseEvent {
  event_type = 'Recommendation page teaser closed';

  constructor(
    public event_properties: RecommendationPageTeaserClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationPageTeaserCtaClicked implements BaseEvent {
  event_type = 'Recommendation page teaser CTA clicked';

  constructor(
    public event_properties: RecommendationPageTeaserCtaClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationPageTeaserDisplayed implements BaseEvent {
  event_type = 'Recommendation page teaser displayed';

  constructor(
    public event_properties: RecommendationPageTeaserDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationsPreferencesClicked implements BaseEvent {
  event_type = 'Recommendations - Preferences clicked';

  constructor(
    public event_properties: RecommendationsPreferencesClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegionClicked implements BaseEvent {
  event_type = 'Region clicked';

  constructor(
    public event_properties: RegionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegionFilterClicked implements BaseEvent {
  event_type = 'Region filter clicked';

  constructor(
    public event_properties: RegionFilterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegionFilterSelected implements BaseEvent {
  event_type = 'Region filter selected';

  constructor(
    public event_properties: RegionFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReinsuranceClicked implements BaseEvent {
  event_type = 'Reinsurance clicked';

  constructor(
    public event_properties: ReinsuranceClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RequestSent implements BaseEvent {
  event_type = 'Request Sent';

  constructor(
    public event_properties: RequestSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RhythmQuestionAnswered implements BaseEvent {
  event_type = 'Rhythm question answered';

  constructor(
    public event_properties: RhythmQuestionAnsweredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RhythmQuestionDisplayed implements BaseEvent {
  event_type = 'Rhythm question displayed';

  constructor(
    public event_properties: RhythmQuestionDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SaveMyTripClicked implements BaseEvent {
  event_type = 'Save my trip clicked';

  constructor(
    public event_properties: SaveMyTripClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SaveMyTripLaterClicked implements BaseEvent {
  event_type = 'Save my trip later clicked';

  constructor(
    public event_properties: SaveMyTripLaterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchbarUsed implements BaseEvent {
  event_type = 'Searchbar used';

  constructor(
    public event_properties: SearchbarUsedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShowMapClicked implements BaseEvent {
  event_type = 'Show map clicked';

  constructor(
    public event_properties: ShowMapClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignInButtonClicked implements BaseEvent {
  event_type = 'Sign in button clicked';

  constructor(
    public event_properties: SignInButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignUpButtonClicked implements BaseEvent {
  event_type = 'Sign up button clicked';

  constructor(
    public event_properties: SignUpButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SiteSuggestionChanged implements BaseEvent {
  event_type = 'Site suggestion changed';

  constructor(
    public event_properties: SiteSuggestionChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SiteSuggestionStayed implements BaseEvent {
  event_type = 'Site suggestion stayed';

  constructor(
    public event_properties: SiteSuggestionStayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StoryStarted implements BaseEvent {
  event_type = 'Story started';

  constructor(
    public event_properties: StoryStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SuggestionClicked implements BaseEvent {
  event_type = 'Suggestion clicked';

  constructor(
    public event_properties: SuggestionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SuitcaseArticleClicked implements BaseEvent {
  event_type = 'Suitcase article clicked';

  constructor(
    public event_properties: SuitcaseArticleClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SummaryStepCompleted implements BaseEvent {
  event_type = 'Summary step completed';

  constructor(
    public event_properties: SummaryStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SurveyStoryAnswered implements BaseEvent {
  event_type = 'Survey story answered';

  constructor(
    public event_properties: SurveyStoryAnsweredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TeSent implements BaseEvent {
  event_type = 'TE sent';

  constructor(
    public event_properties: TeSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TestimonialClicked implements BaseEvent {
  event_type = 'Testimonial clicked';

  constructor(
    public event_properties: TestimonialClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ThematicClicked implements BaseEvent {
  event_type = 'Thematic clicked';

  constructor(
    public event_properties: ThematicClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ThematicFilterClicked implements BaseEvent {
  event_type = 'Thematic filter clicked';

  constructor(
    public event_properties: ThematicFilterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TipiRecommendChoiceClicked implements BaseEvent {
  event_type = 'Tipi Recommend - Choice clicked';

  constructor(
    public event_properties: TipiRecommendChoiceClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TipiRecommendChoiceSubmitted implements BaseEvent {
  event_type = 'Tipi Recommend - Choice submitted';

  constructor(
    public event_properties: TipiRecommendChoiceSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TipiRecommendCommentPopinClosed implements BaseEvent {
  event_type = 'Tipi Recommend - Comment popin closed';

  constructor(
    public event_properties: TipiRecommendCommentPopinClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TipiRecommendDayClicked implements BaseEvent {
  event_type = 'Tipi Recommend - Day clicked';

  constructor(
    public event_properties: TipiRecommendDayClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TipiRecommendSkipClicked implements BaseEvent {
  event_type = 'Tipi Recommend - Skip clicked';

  constructor(
    public event_properties: TipiRecommendSkipClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TipiRecommendTabClicked implements BaseEvent {
  event_type = 'Tipi Recommend - Tab clicked';

  constructor(
    public event_properties: TipiRecommendTabClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToasterActivityAddedClicked implements BaseEvent {
  event_type = 'Toaster activity added clicked';

  constructor(
    public event_properties: ToasterActivityAddedClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransportSearchClicked implements BaseEvent {
  event_type = 'Transport search clicked';

  constructor(
    public event_properties: TransportSearchClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TravelDateModificationClicked implements BaseEvent {
  event_type = 'Travel date modification clicked';

  constructor(
    public event_properties: TravelDateModificationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TravelGuideArticleClicked implements BaseEvent {
  event_type = 'Travel guide article clicked';

  constructor(
    public event_properties: TravelGuideArticleClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TravelGuideClicked implements BaseEvent {
  event_type = 'Travel guide clicked';

  constructor(
    public event_properties: TravelGuideClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TravelersTestimonialArticleClicked implements BaseEvent {
  event_type = 'Travelers testimonial article clicked';

  constructor(
    public event_properties: TravelersTestimonialArticleClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripAdjustmentQuestionAnswered implements BaseEvent {
  event_type = 'Trip adjustment question answered';

  constructor(
    public event_properties: TripAdjustmentQuestionAnsweredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripAdjustmentQuestionDisplayed implements BaseEvent {
  event_type = 'Trip adjustment question displayed';

  constructor(
    public event_properties: TripAdjustmentQuestionDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripNotationClicked implements BaseEvent {
  event_type = 'Trip notation clicked';

  constructor(
    public event_properties: TripNotationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripPreparationClicked implements BaseEvent {
  event_type = 'Trip preparation clicked';

  constructor(
    public event_properties: TripPreparationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripProjectInProgressMyTripClicked implements BaseEvent {
  event_type = 'Trip project in progress my trip clicked';

  constructor(
    public event_properties: TripProjectInProgressMyTripClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripProjectInProgressPopinDisplayed implements BaseEvent {
  event_type = 'Trip project in progress popin displayed';

  constructor(
    public event_properties: TripProjectInProgressPopinDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripProjectInProgressRestartClicked implements BaseEvent {
  event_type = 'Trip project in progress restart clicked';

  constructor(
    public event_properties: TripProjectInProgressRestartClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripReviewClicked implements BaseEvent {
  event_type = 'Trip review clicked';

  constructor(
    public event_properties: TripReviewClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripReviewsSortClicked implements BaseEvent {
  event_type = 'Trip reviews sort clicked';

  constructor(
    public event_properties: TripReviewsSortClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformActivitiesHostingStepCompleted implements BaseEvent {
  event_type = 'Tripform - activities & hosting step completed';

  constructor(
    public event_properties: TripformActivitiesHostingStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformActivitiesHostingStepViewed implements BaseEvent {
  event_type = 'Tripform - activities & hosting step viewed';

  constructor(
    public event_properties: TripformActivitiesHostingStepViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformActivitiesStepCompleted implements BaseEvent {
  event_type = 'Tripform - activities step completed';

  constructor(
    public event_properties: TripformActivitiesStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformBudgetStepCompleted implements BaseEvent {
  event_type = 'Tripform - budget step completed';

  constructor(
    public event_properties: TripformBudgetStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromAgenciesList implements BaseEvent {
  event_type = 'Tripform - creation started from agencies list';

  constructor(
    public event_properties: TripformCreationStartedFromAgenciesListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromAgency implements BaseEvent {
  event_type = 'Tripform - creation started from agency';

  constructor(
    public event_properties: TripformCreationStartedFromAgencyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromDestination implements BaseEvent {
  event_type = 'Tripform - creation started from destination';

  constructor(
    public event_properties: TripformCreationStartedFromDestinationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromDestinationArticle implements BaseEvent {
  event_type = 'Tripform - creation started from destination article';

  constructor(
    public event_properties: TripformCreationStartedFromDestinationArticleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromDestinationArticlesList implements BaseEvent {
  event_type = 'Tripform - creation started from destination articles list';

  constructor(
    public event_properties: TripformCreationStartedFromDestinationArticlesListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromDestinationSuitcase implements BaseEvent {
  event_type = 'Tripform - creation started from destination suitcase';

  constructor(
    public event_properties: TripformCreationStartedFromDestinationSuitcaseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromDestinationVisa implements BaseEvent {
  event_type = 'Tripform - creation started from destination visa';

  constructor(
    public event_properties: TripformCreationStartedFromDestinationVisaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromDestinationVocabulary implements BaseEvent {
  event_type = 'Tripform - creation started from destination vocabulary';

  constructor(
    public event_properties: TripformCreationStartedFromDestinationVocabularyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromDestinationWhentogo implements BaseEvent {
  event_type = 'Tripform - creation started from destination whentogo';

  constructor(
    public event_properties: TripformCreationStartedFromDestinationWhentogoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromExplore implements BaseEvent {
  event_type = 'Tripform - creation started from explore';

  constructor(
    public event_properties: TripformCreationStartedFromExploreProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromFacet implements BaseEvent {
  event_type = 'Tripform - creation started from facet';

  constructor(
    public event_properties: TripformCreationStartedFromFacetProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromIti implements BaseEvent {
  event_type = 'Tripform - creation started from iti';

  constructor(
    public event_properties: TripformCreationStartedFromItiProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromListIti implements BaseEvent {
  event_type = 'Tripform - creation started from list iti';

  constructor(
    public event_properties: TripformCreationStartedFromListItiProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromPoi implements BaseEvent {
  event_type = 'Tripform - creation started from poi';

  constructor(
    public event_properties: TripformCreationStartedFromPoiProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromPoiList implements BaseEvent {
  event_type = 'Tripform - creation started from poi list';

  constructor(
    public event_properties: TripformCreationStartedFromPoiListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromRegion implements BaseEvent {
  event_type = 'Tripform - creation started from region';

  constructor(
    public event_properties: TripformCreationStartedFromRegionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromRegionWhentogo implements BaseEvent {
  event_type = 'Tripform - creation started from region whentogo';

  constructor(
    public event_properties: TripformCreationStartedFromRegionWhentogoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformCreationStartedFromTravelGuide implements BaseEvent {
  event_type = 'Tripform - creation started from travel guide';

  constructor(
    public event_properties: TripformCreationStartedFromTravelGuideProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformDatesStepCompleted implements BaseEvent {
  event_type = 'Tripform - dates step completed';

  constructor(
    public event_properties: TripformDatesStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformDestinationInfosStepCompleted implements BaseEvent {
  event_type = 'Tripform - destination infos step completed';

  constructor(
    public event_properties: TripformDestinationInfosStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformExitButtonClicked implements BaseEvent {
  event_type = 'Tripform - exit button clicked';

  constructor(
    public event_properties: TripformExitButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformExitPopinContinued implements BaseEvent {
  event_type = 'Tripform - exit popin continued';

  constructor(
    public event_properties: TripformExitPopinContinuedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformExitPopinDisplayed implements BaseEvent {
  event_type = 'Tripform - exit popin displayed';

  constructor(
    public event_properties: TripformExitPopinDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformExitPopinLeft implements BaseEvent {
  event_type = 'Tripform - exit popin left';

  constructor(
    public event_properties: TripformExitPopinLeftProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformHesitatingQualificationStepCompleted implements BaseEvent {
  event_type = 'Tripform - hesitating qualification step completed';

  constructor(
    public event_properties: TripformHesitatingQualificationStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformHesitatingTripStepCompleted implements BaseEvent {
  event_type = 'Tripform - Hesitating trip step completed';

  constructor(
    public event_properties: TripformHesitatingTripStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformHostingStepCompleted implements BaseEvent {
  event_type = 'Tripform - hosting step completed';

  constructor(
    public event_properties: TripformHostingStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformInspirationalItineraryClicked implements BaseEvent {
  event_type = 'Tripform - Inspirational itinerary clicked';

  constructor(
    public event_properties: TripformInspirationalItineraryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformMailConnection implements BaseEvent {
  event_type = 'Tripform - mail connection';

  constructor(
    public event_properties: TripformMailConnectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformMaturityCheckStepCompleted implements BaseEvent {
  event_type = 'Tripform - Maturity check step completed';

  constructor(
    public event_properties: TripformMaturityCheckStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformMaturityStepCompleted implements BaseEvent {
  event_type = 'Tripform - maturity step completed';

  constructor(
    public event_properties: TripformMaturityStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformMissingInformationsStepCompleted implements BaseEvent {
  event_type = 'Tripform - missing informations step completed';

  constructor(
    public event_properties: TripformMissingInformationsStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformMissingInformationsViewed implements BaseEvent {
  event_type = 'Tripform - missing informations viewed';

  constructor(
    public event_properties: TripformMissingInformationsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformPersonalizationStepCompleted implements BaseEvent {
  event_type = 'Tripform - Personalization step completed';

  constructor(
    public event_properties: TripformPersonalizationStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformPreviousStep implements BaseEvent {
  event_type = 'Tripform - previous step';

  constructor(
    public event_properties: TripformPreviousStepProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformProfileStepCompleted implements BaseEvent {
  event_type = 'Tripform - profile step completed';

  constructor(
    public event_properties: TripformProfileStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformProjectDescriptionClicked implements BaseEvent {
  event_type = 'Tripform - project description clicked';

  constructor(
    public event_properties: TripformProjectDescriptionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformReadyToSent implements BaseEvent {
  event_type = 'Tripform - ready to sent';

  constructor(
    public event_properties: TripformReadyToSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformRhythmStepCompleted implements BaseEvent {
  event_type = 'Tripform - rhythm step completed';

  constructor(
    public event_properties: TripformRhythmStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformSkipStepClicked implements BaseEvent {
  event_type = 'Tripform - skip step clicked';

  constructor(
    public event_properties: TripformSkipStepClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformSocialLoginAddPhoneNumber implements BaseEvent {
  event_type = 'Tripform - social login add phone number';

  constructor(
    public event_properties: TripformSocialLoginAddPhoneNumberProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformSocialLoginConnection implements BaseEvent {
  event_type = 'Tripform - social login connection';

  constructor(
    public event_properties: TripformSocialLoginConnectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformStartFromZeroClicked implements BaseEvent {
  event_type = 'Tripform - start from zero clicked';

  constructor(
    public event_properties: TripformStartFromZeroClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformTripStepCompleted implements BaseEvent {
  event_type = 'Tripform - trip step completed';

  constructor(
    public event_properties: TripformTripStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripformTripformSent implements BaseEvent {
  event_type = 'Tripform - tripform sent';

  constructor(
    public event_properties: TripformTripformSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripsDestinationRecommendationClicked implements BaseEvent {
  event_type = 'Trips - Destination recommendation clicked';

  constructor(
    public event_properties: TripsDestinationRecommendationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripsRecommendationClicked implements BaseEvent {
  event_type = 'Trips - Recommendation clicked';

  constructor(
    public event_properties: TripsRecommendationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TripsTripClicked implements BaseEvent {
  event_type = 'Trips - Trip clicked';

  constructor(
    public event_properties: TripsTripClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UsefulButtonClicked implements BaseEvent {
  event_type = 'Useful button clicked';

  constructor(
    public event_properties: UsefulButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VideoPlayed implements BaseEvent {
  event_type = 'Video played';

  constructor(
    public event_properties: VideoPlayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewMyTripClicked implements BaseEvent {
  event_type = 'View my trip clicked';

  constructor(
    public event_properties: ViewMyTripClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageHub implements BaseEvent {
  event_type = 'Viewed page -hub';

  constructor(
    public event_properties?: ViewedPageHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccount implements BaseEvent {
  event_type = 'Viewed page account';

  constructor(
    public event_properties: ViewedPageAccountProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountPersonalInformations implements BaseEvent {
  event_type = 'Viewed page account-personal-informations';

  constructor(
    public event_properties: ViewedPageAccountPersonalInformationsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountPersonalInformationsNotifications implements BaseEvent {
  event_type = 'Viewed page account-personal-informations-notifications';

  constructor(
    public event_properties: ViewedPageAccountPersonalInformationsNotificationsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountPersonalInformationsPassword implements BaseEvent {
  event_type = 'Viewed page account-personal-informations-password';

  constructor(
    public event_properties: ViewedPageAccountPersonalInformationsPasswordProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountPoiOpinions implements BaseEvent {
  event_type = 'Viewed page account-poi-opinions';

  constructor(
    public event_properties: ViewedPageAccountPoiOpinionsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountPoiOpinionsAdd implements BaseEvent {
  event_type = 'Viewed page account-poi-opinions-add';

  constructor(
    public event_properties: ViewedPageAccountPoiOpinionsAddProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountRecommendations implements BaseEvent {
  event_type = 'Viewed page account-recommendations';

  constructor(
    public event_properties: ViewedPageAccountRecommendationsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripAboutTheAgency implements BaseEvent {
  event_type = 'Viewed page account-trip-about-the-agency';

  constructor(
    public event_properties: ViewedPageAccountTripAboutTheAgencyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripJoin implements BaseEvent {
  event_type = 'Viewed page account-trip-join';

  constructor(
    public event_properties: ViewedPageAccountTripJoinProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripJoinExpired implements BaseEvent {
  event_type = 'Viewed page account-trip-join-expired';

  constructor(
    public event_properties: ViewedPageAccountTripJoinExpiredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripMessages implements BaseEvent {
  event_type = 'Viewed page account-trip-messages';

  constructor(
    public event_properties: ViewedPageAccountTripMessagesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripNeedHelp implements BaseEvent {
  event_type = 'Viewed page account-trip-need-help';

  constructor(
    public event_properties: ViewedPageAccountTripNeedHelpProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripPartners implements BaseEvent {
  event_type = 'Viewed page account-trip-partners';

  constructor(
    public event_properties: ViewedPageAccountTripPartnersProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripPayments implements BaseEvent {
  event_type = 'Viewed page account-trip-payments';

  constructor(
    public event_properties: ViewedPageAccountTripPaymentsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripSharedDocuments implements BaseEvent {
  event_type = 'Viewed page account-trip-shared-documents';

  constructor(
    public event_properties: ViewedPageAccountTripSharedDocumentsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripTransports implements BaseEvent {
  event_type = 'Viewed page account-trip-transports';

  constructor(
    public event_properties: ViewedPageAccountTripTransportsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripTravellers implements BaseEvent {
  event_type = 'Viewed page account-trip-travellers';

  constructor(
    public event_properties: ViewedPageAccountTripTravellersProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripValidate implements BaseEvent {
  event_type = 'Viewed page account-trip-validate';

  constructor(
    public event_properties: ViewedPageAccountTripValidateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripValidateConfirmation implements BaseEvent {
  event_type = 'Viewed page account-trip-validate-confirmation';

  constructor(
    public event_properties: ViewedPageAccountTripValidateConfirmationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTrips implements BaseEvent {
  event_type = 'Viewed page account-trips';

  constructor(
    public event_properties: ViewedPageAccountTripsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountTripsRecommendations implements BaseEvent {
  event_type = 'Viewed page account-trips-recommendations';

  constructor(
    public event_properties: ViewedPageAccountTripsRecommendationsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountWishlist implements BaseEvent {
  event_type = 'Viewed page account-wishlist';

  constructor(
    public event_properties: ViewedPageAccountWishlistProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAccountWishlistDestination implements BaseEvent {
  event_type = 'Viewed page account-wishlist-destination';

  constructor(
    public event_properties: ViewedPageAccountWishlistDestinationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageActivities implements BaseEvent {
  event_type = 'Viewed page activities';

  constructor(
    public event_properties: ViewedPageActivitiesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageActivitiesMyTrip implements BaseEvent {
  event_type = 'Viewed page activities-my-trip';

  constructor(
    public event_properties: ViewedPageActivitiesMyTripProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageActivityDetails implements BaseEvent {
  event_type = 'Viewed page activity-details';

  constructor(
    public event_properties: ViewedPageActivityDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAgenciesList implements BaseEvent {
  event_type = 'Viewed page agencies list';

  constructor(
    public event_properties: ViewedPageAgenciesListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageArticle implements BaseEvent {
  event_type = 'Viewed page article';

  constructor(
    public event_properties: ViewedPageArticleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageAutumnHub implements BaseEvent {
  event_type = 'Viewed page autumn-hub';

  constructor(
    public event_properties: ViewedPageAutumnHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageBeginForm implements BaseEvent {
  event_type = 'Viewed page beginForm';

  constructor(
    public event_properties: ViewedPageBeginFormProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageBestSellersHub implements BaseEvent {
  event_type = 'Viewed page best-sellers-hub';

  constructor(
    public event_properties: ViewedPageBestSellersHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageBetterTravelerPledge implements BaseEvent {
  event_type = 'Viewed page Better Traveler Pledge';

  constructor(
    public event_properties: ViewedPageBetterTravelerPledgeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageBetterTravelerPledgeConfirmation implements BaseEvent {
  event_type = 'Viewed page Better Traveler Pledge - Confirmation';

  constructor(
    public event_properties: ViewedPageBetterTravelerPledgeConfirmationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageBetterTravelerPledgeIntro implements BaseEvent {
  event_type = 'Viewed page Better Traveler Pledge - Intro';

  constructor(
    public event_properties: ViewedPageBetterTravelerPledgeIntroProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageBikeHub implements BaseEvent {
  event_type = 'Viewed page bike-hub';

  constructor(
    public event_properties: ViewedPageBikeHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCityBreakHub implements BaseEvent {
  event_type = 'Viewed page city-break-hub';

  constructor(
    public event_properties: ViewedPageCityBreakHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoAgencies implements BaseEvent {
  event_type = 'Viewed page coco.agencies';

  constructor(
    public event_properties: ViewedPageCocoAgenciesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoArticleEssential implements BaseEvent {
  event_type = 'Viewed page coco.article.essential';

  constructor(
    public event_properties: ViewedPageCocoArticleEssentialProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoArticleInspiration implements BaseEvent {
  event_type = 'Viewed page coco.article.inspiration';

  constructor(
    public event_properties: ViewedPageCocoArticleInspirationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoArticlePractical implements BaseEvent {
  event_type = 'Viewed page coco.article.practical';

  constructor(
    public event_properties: ViewedPageCocoArticlePracticalProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoArticleSuitcase implements BaseEvent {
  event_type = 'Viewed page coco.article.suitcase';

  constructor(
    public event_properties: ViewedPageCocoArticleSuitcaseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoArticleVisa implements BaseEvent {
  event_type = 'Viewed page coco.article.visa';

  constructor(
    public event_properties: ViewedPageCocoArticleVisaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoArticleVocabulary implements BaseEvent {
  event_type = 'Viewed page coco.article.vocabulary';

  constructor(
    public event_properties: ViewedPageCocoArticleVocabularyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoArticleWhentogo implements BaseEvent {
  event_type = 'Viewed page coco.article.whentogo';

  constructor(
    public event_properties: ViewedPageCocoArticleWhentogoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoArticleWhytogo implements BaseEvent {
  event_type = 'Viewed page coco.article.whytogo';

  constructor(
    public event_properties: ViewedPageCocoArticleWhytogoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoHome implements BaseEvent {
  event_type = 'Viewed page coco.home';

  constructor(
    public event_properties: ViewedPageCocoHomeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoListInspiration implements BaseEvent {
  event_type = 'Viewed page coco.list.inspiration';

  constructor(
    public event_properties: ViewedPageCocoListInspirationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoListPlace implements BaseEvent {
  event_type = 'Viewed page coco.list.place';

  constructor(
    public event_properties: ViewedPageCocoListPlaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCocoPlace implements BaseEvent {
  event_type = 'Viewed page coco.place';

  constructor(
    public event_properties: ViewedPageCocoPlaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCompletedForm implements BaseEvent {
  event_type = 'Viewed page completedForm';

  constructor(
    public event_properties: ViewedPageCompletedFormProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageConcept implements BaseEvent {
  event_type = 'Viewed page concept';

  constructor(
    public event_properties: ViewedPageConceptProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageContest implements BaseEvent {
  event_type = 'Viewed page contest';

  constructor(
    public event_properties?: ViewedPageContestProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageContextContinent implements BaseEvent {
  event_type = 'Viewed page context-continent';

  constructor(
    public event_properties: ViewedPageContextContinentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCountries implements BaseEvent {
  event_type = 'Viewed page countries';

  constructor(
    public event_properties: ViewedPageCountriesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCountry implements BaseEvent {
  event_type = 'Viewed page country';

  constructor(
    public event_properties: ViewedPageCountryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageCountryRecommendation implements BaseEvent {
  event_type = 'Viewed page country recommendation';

  constructor(
    public event_properties: ViewedPageCountryRecommendationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageDestinationArticle implements BaseEvent {
  event_type = 'Viewed page destination article';

  constructor(
    public event_properties: ViewedPageDestinationArticleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageDestinationArticlesList implements BaseEvent {
  event_type = 'Viewed page destination articles list';

  constructor(
    public event_properties: ViewedPageDestinationArticlesListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageDestinationDetails implements BaseEvent {
  event_type = 'Viewed page destination details';

  constructor(
    public event_properties: ViewedPageDestinationDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageDestinationSuitcase implements BaseEvent {
  event_type = 'Viewed page destination suitcase';

  constructor(
    public event_properties: ViewedPageDestinationSuitcaseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageDestinationVisa implements BaseEvent {
  event_type = 'Viewed page destination visa';

  constructor(
    public event_properties: ViewedPageDestinationVisaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageDestinationVocabulary implements BaseEvent {
  event_type = 'Viewed page destination vocabulary';

  constructor(
    public event_properties: ViewedPageDestinationVocabularyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageDestinationWhentogo implements BaseEvent {
  event_type = 'Viewed page destination whentogo';

  constructor(
    public event_properties: ViewedPageDestinationWhentogoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageDestinationWhatToDo implements BaseEvent {
  event_type = 'Viewed page destination-what-to-do';

  constructor(
    public event_properties: ViewedPageDestinationWhatToDoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageExceptionalJourneyHub implements BaseEvent {
  event_type = 'Viewed page exceptional-journey-hub';

  constructor(
    public event_properties?: ViewedPageExceptionalJourneyHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageExperience implements BaseEvent {
  event_type = 'Viewed page experience';

  constructor(
    public event_properties: ViewedPageExperienceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageFacet implements BaseEvent {
  event_type = 'Viewed page facet';

  constructor(
    public event_properties: ViewedPageFacetProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageFamilyExperience implements BaseEvent {
  event_type = 'Viewed page family-experience';

  constructor(
    public event_properties: ViewedPageFamilyExperienceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageFamilyHub implements BaseEvent {
  event_type = 'Viewed page family-hub';

  constructor(
    public event_properties: ViewedPageFamilyHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageFebruaryHub implements BaseEvent {
  event_type = 'Viewed page february-hub';

  constructor(
    public event_properties: ViewedPageFebruaryHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageFlights implements BaseEvent {
  event_type = 'Viewed page flights';

  constructor(
    public event_properties: ViewedPageFlightsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageGastronomyExperience implements BaseEvent {
  event_type = 'Viewed page gastronomy-experience';

  constructor(
    public event_properties: ViewedPageGastronomyExperienceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageGastronomyHub implements BaseEvent {
  event_type = 'Viewed page gastronomy-hub';

  constructor(
    public event_properties: ViewedPageGastronomyHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageHikesTrekHub implements BaseEvent {
  event_type = 'Viewed page hikes-trek-hub';

  constructor(
    public event_properties: ViewedPageHikesTrekHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageHoneymoonHub implements BaseEvent {
  event_type = 'Viewed page honeymoon-hub';

  constructor(
    public event_properties: ViewedPageHoneymoonHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageIndex implements BaseEvent {
  event_type = 'Viewed page index';

  constructor(
    public event_properties: ViewedPageIndexProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageItinerary implements BaseEvent {
  event_type = 'Viewed page itinerary';

  constructor(
    public event_properties: ViewedPageItineraryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageJanuaryHub implements BaseEvent {
  event_type = 'Viewed page january-hub';

  constructor(
    public event_properties: ViewedPageJanuaryHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageJoinAGroupHub implements BaseEvent {
  event_type = 'Viewed page join-a-group-hub';

  constructor(
    public event_properties: ViewedPageJoinAGroupHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageJulyHub implements BaseEvent {
  event_type = 'Viewed page july-hub';

  constructor(
    public event_properties?: ViewedPageJulyHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageLandingEvents implements BaseEvent {
  event_type = 'Viewed page landing-events';

  constructor(
    public event_properties: ViewedPageLandingEventsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageLandingRespireWarranty implements BaseEvent {
  event_type = 'Viewed page landing-respire-warranty';

  constructor(
    public event_properties: ViewedPageLandingRespireWarrantyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageLandingTopDestination2020 implements BaseEvent {
  event_type = 'Viewed page landing-top-destination-2020';

  constructor(
    public event_properties: ViewedPageLandingTopDestination2020Properties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageLeadNurturing implements BaseEvent {
  event_type = 'Viewed page lead-nurturing';

  constructor(
    public event_properties: ViewedPageLeadNurturingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageListItineraries implements BaseEvent {
  event_type = 'Viewed page list-itineraries';

  constructor(
    public event_properties: ViewedPageListItinerariesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageListItinerariesRecommendation implements BaseEvent {
  event_type = 'Viewed page list-itineraries recommendation';

  constructor(
    public event_properties: ViewedPageListItinerariesRecommendationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageLocalAgent implements BaseEvent {
  event_type = 'Viewed page local-agent';

  constructor(
    public event_properties: ViewedPageLocalAgentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageLpAcquisition implements BaseEvent {
  event_type = 'Viewed page lp-acquisition';

  constructor(
    public event_properties: ViewedPageLpAcquisitionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageMagazine implements BaseEvent {
  event_type = 'Viewed page magazine';

  constructor(
    public event_properties: ViewedPageMagazineProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageMagazineArticle implements BaseEvent {
  event_type = 'Viewed page magazine-article';

  constructor(
    public event_properties: ViewedPageMagazineArticleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageMagazineArticlePreview implements BaseEvent {
  event_type = 'Viewed page magazine-article-preview';

  constructor(
    public event_properties: ViewedPageMagazineArticlePreviewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageNatureHub implements BaseEvent {
  event_type = 'Viewed page nature-hub';

  constructor(
    public event_properties: ViewedPageNatureHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageNoPlaneHub implements BaseEvent {
  event_type = 'Viewed page no-plane-hub';

  constructor(
    public event_properties: ViewedPageNoPlaneHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageNorthernLightHub implements BaseEvent {
  event_type = 'Viewed page northern-light-hub';

  constructor(
    public event_properties: ViewedPageNorthernLightHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageNull implements BaseEvent {
  event_type = 'Viewed page null';

  constructor(
    public event_properties: ViewedPageNullProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageOffTheBeatenTrackHub implements BaseEvent {
  event_type = 'Viewed page off-the-beaten-track-hub';

  constructor(
    public event_properties: ViewedPageOffTheBeatenTrackHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageOneWeekHub implements BaseEvent {
  event_type = 'Viewed page one-week-hub';

  constructor(
    public event_properties: ViewedPageOneWeekHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageParadiseBeachHub implements BaseEvent {
  event_type = 'Viewed page paradise-beach-hub';

  constructor(
    public event_properties: ViewedPageParadiseBeachHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePartnership implements BaseEvent {
  event_type = 'Viewed page partnership';

  constructor(
    public event_properties: ViewedPagePartnershipProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePathfinderConversation implements BaseEvent {
  event_type = 'Viewed page pathfinder-conversation';

  constructor(
    public event_properties?: ViewedPagePathfinderConversationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePayment implements BaseEvent {
  event_type = 'Viewed page payment';

  constructor(
    public event_properties: ViewedPagePaymentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePaymentIban implements BaseEvent {
  event_type = 'Viewed page payment-iban';

  constructor(
    public event_properties: ViewedPagePaymentIbanProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePaymentInsurance implements BaseEvent {
  event_type = 'Viewed page payment-insurance';

  constructor(
    public event_properties?: ViewedPagePaymentInsuranceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePaymentPayerInfo implements BaseEvent {
  event_type = 'Viewed page payment-payer-info';

  constructor(
    public event_properties?: ViewedPagePaymentPayerInfoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePaymentResult implements BaseEvent {
  event_type = 'Viewed page payment-result';

  constructor(
    public event_properties: ViewedPagePaymentResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePaymentResultFailure implements BaseEvent {
  event_type = 'Viewed page payment-result-failure';

  constructor(
    public event_properties?: ViewedPagePaymentResultFailureProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePaymentResultSuccess implements BaseEvent {
  event_type = 'Viewed page payment-result-success';

  constructor(
    public event_properties?: ViewedPagePaymentResultSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePaymentSchedule implements BaseEvent {
  event_type = 'Viewed page payment-schedule';

  constructor(
    public event_properties: ViewedPagePaymentScheduleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePaymentSummary implements BaseEvent {
  event_type = 'Viewed page payment-summary';

  constructor(
    public event_properties?: ViewedPagePaymentSummaryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePaymentTravelInformation implements BaseEvent {
  event_type = 'Viewed page payment-travel-information';

  constructor(
    public event_properties?: ViewedPagePaymentTravelInformationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePaymentWithAdyen implements BaseEvent {
  event_type = 'Viewed page payment-with-adyen';

  constructor(
    public event_properties?: ViewedPagePaymentWithAdyenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePoiList implements BaseEvent {
  event_type = 'Viewed page POI list';

  constructor(
    public event_properties: ViewedPagePoiListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePress implements BaseEvent {
  event_type = 'Viewed page press';

  constructor(
    public event_properties: ViewedPagePressProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPagePrismicPreview implements BaseEvent {
  event_type = 'Viewed page prismic-preview';

  constructor(
    public event_properties: ViewedPagePrismicPreviewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageRegion implements BaseEvent {
  event_type = 'Viewed page region';

  constructor(
    public event_properties: ViewedPageRegionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageRegionWhentogo implements BaseEvent {
  event_type = 'Viewed page region whentogo';

  constructor(
    public event_properties: ViewedPageRegionWhentogoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageRequalificationForm implements BaseEvent {
  event_type = 'Viewed page requalificationForm';

  constructor(
    public event_properties: ViewedPageRequalificationFormProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageRequalificationFormEmail implements BaseEvent {
  event_type = 'Viewed page requalificationForm-email';

  constructor(
    public event_properties: ViewedPageRequalificationFormEmailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageSafarisHub implements BaseEvent {
  event_type = 'Viewed page safaris-hub';

  constructor(
    public event_properties: ViewedPageSafarisHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageSelfdriveHub implements BaseEvent {
  event_type = 'Viewed page selfdrive-hub';

  constructor(
    public event_properties: ViewedPageSelfdriveHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageSeptemberHub implements BaseEvent {
  event_type = 'Viewed page september-hub';

  constructor(
    public event_properties?: ViewedPageSeptemberHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageSitemap implements BaseEvent {
  event_type = 'Viewed page sitemap';

  constructor(
    public event_properties: ViewedPageSitemapProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageSportAdventureHub implements BaseEvent {
  event_type = 'Viewed page sport-adventure-hub';

  constructor(
    public event_properties: ViewedPageSportAdventureHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageSummerHub implements BaseEvent {
  event_type = 'Viewed page summer-hub';

  constructor(
    public event_properties: ViewedPageSummerHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageTeam implements BaseEvent {
  event_type = 'Viewed page team';

  constructor(
    public event_properties: ViewedPageTeamProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageThematicLanding implements BaseEvent {
  event_type = 'Viewed page thematic.landing';

  constructor(
    public event_properties: ViewedPageThematicLandingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageTipiRecommendation implements BaseEvent {
  event_type = 'Viewed page tipi-recommendation';

  constructor(
    public event_properties: ViewedPageTipiRecommendationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageTopDestinations implements BaseEvent {
  event_type = 'Viewed page top destinations';

  constructor(
    public event_properties: ViewedPageTopDestinationsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageTravelGuide implements BaseEvent {
  event_type = 'Viewed page travel guide';

  constructor(
    public event_properties: ViewedPageTravelGuideProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageTravelExplorer implements BaseEvent {
  event_type = 'Viewed page travel-explorer';

  constructor(
    public event_properties: ViewedPageTravelExplorerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageTripReview implements BaseEvent {
  event_type = 'Viewed page trip-review';

  constructor(
    public event_properties: ViewedPageTripReviewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageTripReviewDestination implements BaseEvent {
  event_type = 'Viewed page trip-review-destination';

  constructor(
    public event_properties: ViewedPageTripReviewDestinationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageTripReviewList implements BaseEvent {
  event_type = 'Viewed page trip-review-list';

  constructor(
    public event_properties: ViewedPageTripReviewListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageTwoWeeksHub implements BaseEvent {
  event_type = 'Viewed page two-weeks-hub';

  constructor(
    public event_properties: ViewedPageTwoWeeksHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageUniversalListItineraries implements BaseEvent {
  event_type = 'Viewed page universal-list-itineraries';

  constructor(
    public event_properties: ViewedPageUniversalListItinerariesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageWebinar implements BaseEvent {
  event_type = 'Viewed page webinar';

  constructor(
    public event_properties: ViewedPageWebinarProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageWhereToGo implements BaseEvent {
  event_type = 'Viewed page where-to-go';

  constructor(
    public event_properties: ViewedPageWhereToGoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageWinterHub implements BaseEvent {
  event_type = 'Viewed page winter-hub';

  constructor(
    public event_properties: ViewedPageWinterHubProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedPageWorkingAbroad implements BaseEvent {
  event_type = 'Viewed page working-abroad';

  constructor(
    public event_properties: ViewedPageWorkingAbroadProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VocabularyArticleClicked implements BaseEvent {
  event_type = 'Vocabulary article clicked';

  constructor(
    public event_properties: VocabularyArticleClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebinarReplayClicked implements BaseEvent {
  event_type = 'Webinar replay clicked';

  constructor(
    public event_properties: WebinarReplayClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebinarSignUpClicked implements BaseEvent {
  event_type = 'Webinar sign-up clicked';

  constructor(
    public event_properties: WebinarSignUpClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoContinentStepCompleted implements BaseEvent {
  event_type = 'Wheretogo - Continent step completed';

  constructor(
    public event_properties: WheretogoContinentStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoDestinationRecommendationStarted implements BaseEvent {
  event_type = 'Wheretogo - Destination recommendation started';

  constructor(
    public event_properties: WheretogoDestinationRecommendationStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoDurationStepCompleted implements BaseEvent {
  event_type = 'Wheretogo - Duration step completed';

  constructor(
    public event_properties: WheretogoDurationStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoExitClicked implements BaseEvent {
  event_type = 'Wheretogo - exit clicked';

  constructor(
    public event_properties: WheretogoExitClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoExperiencesIteration3StepCompleted implements BaseEvent {
  event_type = 'Wheretogo - Experiences iteration 3 step completed';

  constructor(
    public event_properties: WheretogoExperiencesIteration3StepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoExperiencesStepCompleted implements BaseEvent {
  event_type = 'Wheretogo - Experiences step completed';

  constructor(
    public event_properties: WheretogoExperiencesStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoLoaderViewResultsClicked implements BaseEvent {
  event_type = 'Wheretogo - Loader view results clicked';

  constructor(
    public event_properties: WheretogoLoaderViewResultsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoMaturityUserStepCompleted implements BaseEvent {
  event_type = 'Wheretogo - Maturity user step completed';

  constructor(
    public event_properties: WheretogoMaturityUserStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoOptinNlCompleted implements BaseEvent {
  event_type = 'Wheretogo - Optin NL completed';

  constructor(
    public event_properties: WheretogoOptinNlCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoPaxStepCompleted implements BaseEvent {
  event_type = 'Wheretogo - Pax step completed';

  constructor(
    public event_properties: WheretogoPaxStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoPeriodStepCompleted implements BaseEvent {
  event_type = 'Wheretogo - Period step completed';

  constructor(
    public event_properties: WheretogoPeriodStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoPreviousStepClicked implements BaseEvent {
  event_type = 'Wheretogo - Previous step clicked';

  constructor(
    public event_properties: WheretogoPreviousStepClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoRecommendationsViewed implements BaseEvent {
  event_type = 'Wheretogo - Recommendations viewed';

  constructor(
    public event_properties: WheretogoRecommendationsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoResultsAddedToWishlist implements BaseEvent {
  event_type = 'Wheretogo - Results added to wishlist';

  constructor(
    public event_properties: WheretogoResultsAddedToWishlistProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoResultsSelectBudget implements BaseEvent {
  event_type = 'Wheretogo - Results select budget';

  constructor(
    public event_properties: WheretogoResultsSelectBudgetProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoResultsSelectSegment implements BaseEvent {
  event_type = 'Wheretogo - Results select segment';

  constructor(
    public event_properties: WheretogoResultsSelectSegmentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoSustainabilityStepCompleted implements BaseEvent {
  event_type = 'Wheretogo - Sustainability step completed';

  constructor(
    public event_properties: WheretogoSustainabilityStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoTransportationStepCompleted implements BaseEvent {
  event_type = 'Wheretogo - Transportation step completed';

  constructor(
    public event_properties: WheretogoTransportationStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WheretogoClicked implements BaseEvent {
  event_type = 'Wheretogo clicked';

  constructor(
    public event_properties: WheretogoClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WishlistDestinationClicked implements BaseEvent {
  event_type = 'Wishlist - Destination clicked';

  constructor(
    public event_properties: WishlistDestinationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WishlistDiscoverDestinationClicked implements BaseEvent {
  event_type = 'Wishlist - Discover destination clicked';

  constructor(
    public event_properties: WishlistDiscoverDestinationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WishlistItineraryClicked implements BaseEvent {
  event_type = 'Wishlist - Itinerary clicked';

  constructor(
    public event_properties: WishlistItineraryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WishlistAdded implements BaseEvent {
  event_type = 'Wishlist added';

  constructor(
    public event_properties: WishlistAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WishlistClicked implements BaseEvent {
  event_type = 'Wishlist clicked';

  constructor(
    public event_properties: WishlistClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * [Debug] map load failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/%5BDebug%5D%20map%20load%20failed)
   *
   * Event temporaire pour tester les comportements au chargement de la carte :
   *
   * * la map commence le chargement
   *
   *
   * * la map a été chargée
   *
   *
   * * la map n'a pas été chargée
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  debugMapLoadFailed(
    properties: DebugMapLoadFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DebugMapLoadFailed(properties), options);
  }

  /**
   * [Debug] map load started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/%5BDebug%5D%20map%20load%20started)
   *
   * Event temporaire pour tester les comportements au chargement de la carte :
   *
   * * la map commence le chargement
   *
   *
   * * la map a été chargée
   *
   *
   * * la map n'a pas été chargée
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  debugMapLoadStarted(
    properties: DebugMapLoadStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DebugMapLoadStarted(properties), options);
  }

  /**
   * [Debug] map load succeeded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/%5BDebug%5D%20map%20load%20succeeded)
   *
   * Event temporaire pour tester les comportements au chargement de la carte :
   *
   * * la map commence le chargement
   *
   *
   * * la map a été chargée
   *
   *
   * * la map n'a pas été chargée
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  debugMapLoadSucceeded(
    properties: DebugMapLoadSucceededProperties,
    options?: EventOptions,
  ) {
    return this.track(new DebugMapLoadSucceeded(properties), options);
  }

  /**
   * [Pyrite] Facet clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/%5BPyrite%5D%20Facet%20clicked)
   *
   * Clic sur une card permattant d'afficher une facet depuis une page Hub (pyrite)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  pyriteFacetClicked(
    properties: PyriteFacetClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PyriteFacetClicked(properties), options);
  }

  /**
   * [Pyrite] Magazine clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/%5BPyrite%5D%20Magazine%20clicked)
   *
   * Clic sur un article du mag depuis une page Pyrite (ex: Hub)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  pyriteMagazineClicked(
    properties: PyriteMagazineClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PyriteMagazineClicked(properties), options);
  }

  /**
   * ab_itinerary_more_detail_agent_block_clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/ab_itinerary_more_detail_agent_block_clicked)
   *
   *
   *
   *
   *  Check if the user clicked on more detail button in the block behind the itinerary title. 
   *  WARNING : It does not include the more detail after the map
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  abItineraryMoreDetailAgentBlockClicked(
    properties: AbItineraryMoreDetailAgentBlockClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AbItineraryMoreDetailAgentBlockClicked(properties), options);
  }

  /**
   * Access content clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Access%20content%20clicked)
   *
   * Sur le site FR, pour regarder l'intégrité des étapes d'un iti, il faut être connecté. Cet event a pour but de tracker les users qui cliquent sur le CTA
   *
   *
   *
   *
   * Owner: Florian Kroockmann
   *
   * @param properties The event's properties (e.g. agency_better_trip_index)
   * @param options Amplitude event options.
   */
  accessContentClicked(
    properties: AccessContentClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccessContentClicked(properties), options);
  }

  /**
   * Activities milestone displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Activities%20milestone%20displayed)
   *
   * Event déclencher pour tracquer l'affichage des milestones (1,2 ou 3) dans la page activités.
   *
   *
   * Lien Notion >https://www.notion.so/leather-yard-6b5/L-accompagnement-du-user-via-des-milestones-pousse-l-utilisateur-aller-plus-loin-dans-le-funnel-11a3a715e76846a1a42aa51a76b80f24?pvs=4#189393856a8b4cd78e1758767b7a60eb
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  activitiesMilestoneDisplayed(
    properties: ActivitiesMilestoneDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActivitiesMilestoneDisplayed(properties), options);
  }

  /**
   * Activity clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Activity%20clicked)
   *
   * Event to track when a user clicks on a specific activity.
   *
   *
   *
   *
   *
   * My Trip
   *
   *
   *
   *
   *
   * Incontournables
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  activityClicked(
    properties: ActivityClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActivityClicked(properties), options);
  }

  /**
   * Activity list clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Activity%20list%20clicked)
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  activityListClicked(
    properties: ActivityListClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActivityListClicked(properties), options);
  }

  /**
   * Activity searchbar used
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Activity%20searchbar%20used)
   *
   * Event to track when users interact with the search bar to search activities. Triggered when user unfocus de field and the field is not empty.
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  activitySearchbarUsed(
    properties: ActivitySearchbarUsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActivitySearchbarUsed(properties), options);
  }

  /**
   * Activity tag filter clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Activity%20tag%20filter%20clicked)
   *
   * Event indicating that a user has clicked on the filter selector on Evaneos Explore Activity List.
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  activityTagFilterClicked(
    properties: ActivityTagFilterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActivityTagFilterClicked(properties), options);
  }

  /**
   * Activity tag filter selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Activity%20tag%20filter%20selected)
   *
   * Event to track when a user selects a specific activity tag filter in the Evaneos Explore Activity List. 
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  activityTagFilterSelected(
    properties: ActivityTagFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActivityTagFilterSelected(properties), options);
  }

  /**
   * Add to my trip clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Add%20to%20my%20trip%20clicked)
   *
   * Event to track when a user clicks on the "Add to my trip" button on an activity.
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  addToMyTripClicked(
    properties: AddToMyTripClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddToMyTripClicked(properties), options);
  }

  /**
   * Additional question answered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Additional%20question%20answered)
   *
   * The user answer to the question, "do you want to answer to a final question ?" in the discussion with Evaneos.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  additionalQuestionAnswered(
    properties: AdditionalQuestionAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new AdditionalQuestionAnswered(properties), options);
  }

  /**
   * Agencies discovery clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Agencies%20discovery%20clicked)
   *
   * Sur la page desti, si une tab thematique est vide, on affiche un empty state contenant une ancre menant vers la block agence. 
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  agenciesDiscoveryClicked(
    properties: AgenciesDiscoveryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgenciesDiscoveryClicked(properties), options);
  }

  /**
   * Agencies list clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Agencies%20list%20clicked)
   *
   * Clic sur le CTA permettant d'accéder à la liste de toutes les agences.
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  agenciesListClicked(
    properties: AgenciesListClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgenciesListClicked(properties), options);
  }

  /**
   * Agency clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Agency%20clicked)
   *
   * Clic sur une agence
   *
   * S'il n'y a pas de carrousel avec différentes agences, alors la propriété card_number = 0
   *
   *
   *
   *
   * Sur une page facet :
   *
   *
   *
   *
   * Sur une page itinéraire :
   *
   *
   *  (block agent_information ↑)
   *
   *
   *  (block sidebar ↑)
   *
   * Sur la nouvelle page facet :
   *
   *
   *
   *
   * Sur la homepage (block agency_homepage)
   *
   *
   *
   *
   * Sur la page agence 
   *
   *
   * Sur la page liste agence :  
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. agency_better_trip_index)
   * @param options Amplitude event options.
   */
  agencyClicked(
    properties: AgencyClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgencyClicked(properties), options);
  }

  /**
   * Agency discovery clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Agency%20discovery%20clicked)
   *
   * Clcik sur découvrir l'agence dans la modale des avis.
   *
   *
   * @param properties The event's properties (e.g. agency_id)
   * @param options Amplitude event options.
   */
  agencyDiscoveryClicked(
    properties: AgencyDiscoveryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgencyDiscoveryClicked(properties), options);
  }

  /**
   * Agency review clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Agency%20review%20clicked)
   *
   *
   *
   *
   * Event: Agency review clicked
   *
   * Description: This event is triggered when a user clicks on the "review" button or link for a specific agency on the website. It indicates user engagement with agency reviews, which can provide valuable insights into user preferences and decision-making processes.
   *
   *
   * The page property indicates if the event is triggered on the agent page or on the list agencies page
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. agency_better_trip_index)
   * @param options Amplitude event options.
   */
  agencyReviewClicked(
    properties: AgencyReviewClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgencyReviewClicked(properties), options);
  }

  /**
   * Agency reviews filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Agency%20reviews%20filtered)
   *
   * Cette propriété track le fait que l'utilisateur ai filtré les reviews
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. agency_better_trip_index)
   * @param options Amplitude event options.
   */
  agencyReviewsFiltered(
    properties: AgencyReviewsFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgencyReviewsFiltered(properties), options);
  }

  /**
   * Agency reviews more
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Agency%20reviews%20more)
   *
   * Cette propriété track indique que l'utilisateur a cliqué sur le bouton afficher plus
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. agency_better_trip_index)
   * @param options Amplitude event options.
   */
  agencyReviewsMore(
    properties: AgencyReviewsMoreProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgencyReviewsMore(properties), options);
  }

  /**
   * Agency reviews seen
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Agency%20reviews%20seen)
   *
   * Cette propriété track le fait que l'utilisateur ai vu la partie review de la page agence 
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. agency_better_trip_index)
   * @param options Amplitude event options.
   */
  agencyReviewsSeen(
    properties: AgencyReviewsSeenProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgencyReviewsSeen(properties), options);
  }

  /**
   * Agency zone clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Agency%20zone%20clicked)
   *
   *
   * This event tracks when a user clicks on the agency zone on the destination card
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  agencyZoneClicked(
    properties: AgencyZoneClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgencyZoneClicked(properties), options);
  }

  /**
   * Agent clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Agent%20clicked)
   *
   * Click sur une card Agent 
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  agentClicked(
    properties: AgentClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentClicked(properties), options);
  }

  /**
   * All thematics filter clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/All%20thematics%20filter%20clicked)
   *
   *
   *
   *
   * Le card_number associé à cet event est 0
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. card_number)
   * @param options Amplitude event options.
   */
  allThematicsFilterClicked(
    properties: AllThematicsFilterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AllThematicsFilterClicked(properties), options);
  }

  /**
   * Article tag clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Article%20tag%20clicked)
   *
   * Le lien est non cliquable mais on souhaite savoir si les users cliquent dessus ou pas. En fonction, on pourra changer l'affichage
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. article_type)
   * @param options Amplitude event options.
   */
  articleTagClicked(
    properties: ArticleTagClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ArticleTagClicked(properties), options);
  }

  /**
   * Authentication succeed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Authentication%20succeed)
   *
   * Event déclenché dès qu'un user arrive à se connecter/créer un compte.
   * Via les propriétés, on pourra savoir
   *
   * * si le user avait déjà un compte existant (signin) ou s'il a créé un compte (signup)
   *
   * * si le user a utilisé le mail, Google ou Apple
   *
   * Cet event peut être déclenché dans le TF ou lors de la navigation sur les autres pages du site. La prop Category permettra de savoir depuis quelle page le user était lorsqu'il s'est connecté.
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. already_existing)
   * @param options Amplitude event options.
   */
  authenticationSucceed(
    properties: AuthenticationSucceedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AuthenticationSucceed(properties), options);
  }

  /**
   * BetterTrip clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/BetterTrip%20clicked)
   *
   * L'utilisateur a cliqué sur un des liens menant vers le site bettertrip
   *
   *
   *
   * Sur la homepage :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  betterTripClicked(
    properties: BetterTripClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BetterTripClicked(properties), options);
  }

  /**
   * Block viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Block%20viewed)
   *
   * **La vue d'un block. Au chargement de la page bien que tous les blocks soient chargés ils ne sont pas tous visibles pour l'utilisateur. Lorsque l'utilisateur scroll, et qu'il peut voir un block en entier event à déclencher**
   *
   * Owner: clarisse.bolignano@external.evaneos.com
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  blockViewed(
    properties: BlockViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BlockViewed(properties), options);
  }

  /**
   * Bookmark activity clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Bookmark%20activity%20clicked)
   *
   * Event to track when a user clicks on a bookmark activity on Evaneos Explore Activity List
   *
   *
   *
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  bookmarkActivityClicked(
    properties: BookmarkActivityClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookmarkActivityClicked(properties), options);
  }

  /**
   * Budget adjustment question answered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Budget%20adjustment%20question%20answered)
   *
   * The user answered the budget adjustement question in the discussion with Evaneos.
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  budgetAdjustmentQuestionAnswered(
    properties: BudgetAdjustmentQuestionAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new BudgetAdjustmentQuestionAnswered(properties), options);
  }

  /**
   * Budget adjustment question displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Budget%20adjustment%20question%20displayed)
   *
   * The question on the budget adjustment in case the user has a low budget is displayed to the user in the discussion with Evaneos after the creation of the request.
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  budgetAdjustmentQuestionDisplayed(
    properties: BudgetAdjustmentQuestionDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BudgetAdjustmentQuestionDisplayed(properties), options);
  }

  /**
   * Budget completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Budget%20completed)
   *
   * Event déclenché dès qu'un user indique une valeur sur le budget de son voyage On souhaite récupérer également le montant + la devise.
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  budgetCompleted(
    properties: BudgetCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BudgetCompleted(properties), options);
  }

  /**
   * Budget min or max used
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Budget%20min%20or%20max%20used)
   *
   * Le user interagit avec les cases min / max de l’étape (c.a.d qu’il remplit au moins une des deux cases): 
   *  :
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  budgetMinOrMaxUsed(
    properties: BudgetMinOrMaxUsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BudgetMinOrMaxUsed(properties), options);
  }

  /**
   * Budget popin suggestion closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Budget%20popin%20suggestion%20closed)
   *
   * Clic permettant de fermer la popin de l'AB test : ce clic peut être sur la croix en haut à droite ou en dehors de la popin.
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  budgetPopinSuggestionClosed(
    properties: BudgetPopinSuggestionClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BudgetPopinSuggestionClosed(properties), options);
  }

  /**
   * Budget preference question answered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Budget%20preference%20question%20answered)
   *
   * The user answers the question about his budget preference in the discussion with Evaneos. 
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  budgetPreferenceQuestionAnswered(
    properties: BudgetPreferenceQuestionAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new BudgetPreferenceQuestionAnswered(properties), options);
  }

  /**
   * Budget preference question displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Budget%20preference%20question%20displayed)
   *
   * The user sees the question on the min. budget required for his destination in the discussion with Evaneos.
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  budgetPreferenceQuestionDisplayed(
    properties: BudgetPreferenceQuestionDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BudgetPreferenceQuestionDisplayed(properties), options);
  }

  /**
   * Budget range question answered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Budget%20range%20question%20answered)
   *
   * The user answer to the budget range / preference question in the discusssion with Evaneos.
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  budgetRangeQuestionAnswered(
    properties: BudgetRangeQuestionAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new BudgetRangeQuestionAnswered(properties), options);
  }

  /**
   * Budget ruler used
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Budget%20ruler%20used)
   *
   * L'utilisateur intéragit au moins une fois avec la reglette de l'étape budget:
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  budgetRulerUsed(
    properties: BudgetRulerUsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BudgetRulerUsed(properties), options);
  }

  /**
   * Budget suggestion clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Budget%20suggestion%20clicked)
   *
   * Clic sur le CTA présent à la troisieme etape du TF
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  budgetSuggestionClicked(
    properties: BudgetSuggestionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BudgetSuggestionClicked(properties), options);
  }

  /**
   * Calendly clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Calendly%20clicked)
   *
   * The user interacts at least once with the calendly of the agent on the discussion with Evaneos right after his request
   *
   * @param properties The event's properties (e.g. agency_id)
   * @param options Amplitude event options.
   */
  calendlyClicked(
    properties: CalendlyClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendlyClicked(properties), options);
  }

  /**
   * Calendly displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Calendly%20displayed)
   *
   * The user sees the calendly of in the discussion with Evaneos right after his request 
   *
   *
   * @param properties The event's properties (e.g. agency_id)
   * @param options Amplitude event options.
   */
  calendlyDisplayed(
    properties: CalendlyDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendlyDisplayed(properties), options);
  }

  /**
   * Calendly meeting booked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Calendly%20meeting%20booked)
   *
   * The user has booked a meeting in the calendly of an agent in the discussion with Evaneos right after the creation of his requests
   *
   *
   *
   *
   * @param properties The event's properties (e.g. agency_id)
   * @param options Amplitude event options.
   */
  calendlyMeetingBooked(
    properties: CalendlyMeetingBookedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendlyMeetingBooked(properties), options);
  }

  /**
   * CB payment step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/CB%20payment%20step%20completed)
   *
   *
   *
   *
   *
   * Il s'agit de la **validation technique** du paiment. Les données du formulaires ont bien été transmises à ADYEN pour traitement.
   *
   *
   * Attention : il ne s'agit pas du succès du paiement en soi car il peut toujours être refusé.
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  cbPaymentStepCompleted(
    properties: CbPaymentStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CbPaymentStepCompleted(properties), options);
  }

  /**
   * CB payment step failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/CB%20payment%20step%20failed)
   *
   *
   *
   *
   *
   * Il s'agit du refus **technique** du paiement : les données n'ont pas été remontée à Adyen qui ne peut donc pas statuer sur le paiement en soi (exemple: mauvaise clé adyen, indisponibilité du service côté Adyen, etc)
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  cbPaymentStepFailed(
    properties: CbPaymentStepFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CbPaymentStepFailed(properties), options);
  }

  /**
   * Cb payment submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Cb%20payment%20submitted)
   *
   * This event tracks the submission of a payment in the payment funnel
   *
   *
   *
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  cbPaymentSubmitted(
    properties: CbPaymentSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CbPaymentSubmitted(properties), options);
  }

  /**
   * Continent filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Continent%20filtered)
   *
   *
   * This event tracks when a user filters their search results by continent on the "Where to Go" page
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  continentFiltered(
    properties: ContinentFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContinentFiltered(properties), options);
  }

  /**
   * CTA "inspirez-moi" clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/CTA%20%22inspirez-moi%22%20clicked)
   *
   * This event track if the "inspirez moi" button is clicked 
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  ctaInspirezMoiClicked(
    properties: CtaInspirezMoiClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CtaInspirezMoiClicked(properties), options);
  }

  /**
   * Delete activity clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Delete%20activity%20clicked)
   *
   * Event to track when a user clicks on the delete activity button on the Evaneos Explore MVPOC
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  deleteActivityClicked(
    properties: DeleteActivityClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteActivityClicked(properties), options);
  }

  /**
   * Destination article clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Destination%20article%20clicked)
   *
   * Clic sur un article Inspiration ou info pratique
   *
   *
   *
   *
   *
   * sur la page list article
   *
   *
   *
   *
   * @param properties The event's properties (e.g. article_type)
   * @param options Amplitude event options.
   */
  destinationArticleClicked(
    properties: DestinationArticleClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DestinationArticleClicked(properties), options);
  }

  /**
   * Destination article list clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Destination%20article%20list%20clicked)
   *
   * This event tracks when a user clicks on a travel guide article LIST on the website : inspiration or practical information
   *
   *
   *
   *
   * @param properties The event's properties (e.g. article_type)
   * @param options Amplitude event options.
   */
  destinationArticleListClicked(
    properties: DestinationArticleListClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DestinationArticleListClicked(properties), options);
  }

  /**
   * Destination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Destination%20clicked)
   *
   * Clic sur une desti : Page facet :
   *
   *
   *
   *
   * Home page :
   *
   *
   *
   *
   * Where-to-go :
   *
   *
   *
   *
   * where to go
   *
   *
   *
   *
   * where to go recommendation
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  destinationClicked(
    properties: DestinationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DestinationClicked(properties), options);
  }

  /**
   * Destination discovery clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Destination%20discovery%20clicked)
   *
   * Clic sur le CTA présent dans la cover du travel guide (rouge) pour découvrir la destination 
   *
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  destinationDiscoveryClicked(
    properties: DestinationDiscoveryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DestinationDiscoveryClicked(properties), options);
  }

  /**
   * Destination visa clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Destination%20visa%20clicked)
   *
   * clic sur un bouton menant à une page visa
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  destinationVisaClicked(
    properties: DestinationVisaClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DestinationVisaClicked(properties), options);
  }

  /**
   * Destinations guide downloaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Destinations%20guide%20downloaded)
   *
   * Télechargement du guide desti:
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  destinationsGuideDownloaded(
    properties: DestinationsGuideDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DestinationsGuideDownloaded(properties), options);
  }

  /**
   * ET1 displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/ET1%20displayed)
   *
   * The user sees the first message sent by Evaneos right after the creation of his request
   *
   *
   * @param properties The event's properties (e.g. agency_id)
   * @param options Amplitude event options.
   */
  et1Displayed(
    properties: Et1DisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new Et1Displayed(properties), options);
  }

  /**
   * Exit story clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Exit%20story%20clicked)
   *
   * Clic sur la croix permettant de fermer la story
   *
   * \[Ajouter screen\]
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  exitStoryClicked(
    properties: ExitStoryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExitStoryClicked(properties), options);
  }

  /**
   * Facet clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Facet%20clicked)
   *
   * Clic sur une card permattant d'afficher une facet
   *
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  facetClicked(
    properties: FacetClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FacetClicked(properties), options);
  }

  /**
   * Fake explorer clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Fake%20explorer%20clicked)
   *
   * Click sur le fake CTA "explorer les activités"
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  fakeExplorerClicked(
    properties: FakeExplorerClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FakeExplorerClicked(properties), options);
  }

  /**
   * Fake explorer exit clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Fake%20explorer%20exit%20clicked)
   *
   * Exit clicked via :
   *
   * * CTA "OK" 
   *
   * * Option "close pop-in" 
   *
   * * Clicked en dehors de la pop-in
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  fakeExplorerExitClicked(
    properties: FakeExplorerExitClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FakeExplorerExitClicked(properties), options);
  }

  /**
   * Fake map clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Fake%20map%20clicked)
   *
   * Dans le cadre d'un smoke test réalisé sur mobile en octobre 2024, cet event est créé pour suivre les taux de clics des users afin de déterminer leur intéret avant de développer la feature si nécessaire.
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  fakeMapClicked(
    properties: FakeMapClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FakeMapClicked(properties), options);
  }

  /**
   * Family with babies filter selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Family%20with%20babies%20filter%20selected)
   *
   * Event to track when users select the "Family with babies" filter on Evaneos Explore Activity List
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  familyWithBabiesFilterSelected(
    properties: FamilyWithBabiesFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FamilyWithBabiesFilterSelected(properties), options);
  }

  /**
   * Filter clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Filter%20clicked)
   *
   * Clic sur un filtre
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  filterClicked(
    properties: FilterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterClicked(properties), options);
  }

  /**
   * Flight price month selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Flight%20price%20month%20selected)
   *
   * AB Test Cash - Flight Price
   *
   * **Event to track the month selected by the user when searching for flight prices**
   *
   *
   * Owner: Monica KESSNER
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  flightPriceMonthSelected(
    properties: FlightPriceMonthSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FlightPriceMonthSelected(properties), options);
  }

  /**
   * Header - all destinations clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20all%20destinations%20clicked)
   *
   * Clic permettant d'accéder à "Toutes les destinations" header - onglet Destinations
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerAllDestinationsClicked(
    properties: HeaderAllDestinationsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderAllDestinationsClicked(properties), options);
  }

  /**
   * Header - B corp clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20B%20corp%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerBCorpClicked(
    properties: HeaderBCorpClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderBCorpClicked(properties), options);
  }

  /**
   * Header - carbon neutral clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20carbon%20neutral%20clicked)
   *
   *
   *
   *
   *
   * = carbon offsetting clicked
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerCarbonNeutralClicked(
    properties: HeaderCarbonNeutralClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderCarbonNeutralClicked(properties), options);
  }

  /**
   * Header - commitments clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20commitments%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerCommitmentsClicked(
    properties: HeaderCommitmentsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderCommitmentsClicked(properties), options);
  }

  /**
   * Header - concept clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20concept%20clicked)
   *
   *
   *
   *
   * Avec le nouveau design :
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerConceptClicked(
    properties: HeaderConceptClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderConceptClicked(properties), options);
  }

  /**
   * Header - continent clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20continent%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  headerContinentClicked(
    properties: HeaderContinentClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderContinentClicked(properties), options);
  }

  /**
   * Header - destination wheretogo clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20destination%20wheretogo%20clicked)
   *
   * Click sur le CTA permettant d'accéder à la page where to go
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerDestinationWheretogoClicked(
    properties: HeaderDestinationWheretogoClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderDestinationWheretogoClicked(properties), options);
  }

  /**
   * Header - help center clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20help%20center%20clicked)
   *
   * Clic sur le CTA présent dans le header permettant d'accéder au centre d'aide
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerHelpCenterClicked(
    properties: HeaderHelpCenterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderHelpCenterClicked(properties), options);
  }

  /**
   * Header - how thematic clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20how%20thematic%20clicked)
   *
   * * who thematic = Avec qui partir ? 
   *
   * * when thematic = quelle période ?
   *
   * * what thematic = quelle activité ?
   *
   * * how thematic = quel style de voyage ?
   *
   *
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerHowThematicClicked(
    properties: HeaderHowThematicClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderHowThematicClicked(properties), options);
  }

  /**
   * Header - impact report clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20impact%20report%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerImpactReportClicked(
    properties: HeaderImpactReportClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderImpactReportClicked(properties), options);
  }

  /**
   * Header - logo clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20logo%20clicked)
   *
   * Clic sur le logo sur le header 
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerLogoClicked(
    properties: HeaderLogoClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderLogoClicked(properties), options);
  }

  /**
   * Header - magazine clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20magazine%20clicked)
   *
   * Clic sur le CTA présent dans le header permettant d'accéder au mag
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerMagazineClicked(
    properties: HeaderMagazineClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderMagazineClicked(properties), options);
  }

  /**
   * Header - other destination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20other%20destination%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerOtherDestinationClicked(
    properties: HeaderOtherDestinationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderOtherDestinationClicked(properties), options);
  }

  /**
   * Header - our impact clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20our%20impact%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerOurImpactClicked(
    properties: HeaderOurImpactClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderOurImpactClicked(properties), options);
  }

  /**
   * Header - recommendation clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20recommendation%20clicked)
   *
   * Tracks when a CTA in the header redirecting to the recommendation page is clicked 
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Replay ID)
   * @param options Amplitude event options.
   */
  headerRecommendationClicked(
    properties: HeaderRecommendationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderRecommendationClicked(properties), options);
  }

  /**
   * Header - top destination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20top%20destination%20clicked)
   *
   * Clic sur une des cards du header - onglet Destinations
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerTopDestinationClicked(
    properties: HeaderTopDestinationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderTopDestinationClicked(properties), options);
  }

  /**
   * Header - what thematic clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20what%20thematic%20clicked)
   *
   * * who thematic = Avec qui partir ? 
   *
   * * when thematic = quelle période ?
   *
   * * what thematic = quelle activité ?
   *
   * * how thematic = quel style de voyage ?
   *
   *
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerWhatThematicClicked(
    properties: HeaderWhatThematicClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderWhatThematicClicked(properties), options);
  }

  /**
   * Header - when thematic clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20when%20thematic%20clicked)
   *
   * * who thematic = Avec qui partir ? 
   *
   * * when thematic = quelle période ?
   *
   * * what thematic = quelle activité ?
   *
   * * how thematic = quel style de voyage ?
   *
   *
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerWhenThematicClicked(
    properties: HeaderWhenThematicClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderWhenThematicClicked(properties), options);
  }

  /**
   * Header - wheretogo clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20wheretogo%20clicked)
   *
   *
   *
   *
   * if block = header\_*wheretogo*\_cta :
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerWheretogoClicked(
    properties: HeaderWheretogoClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderWheretogoClicked(properties), options);
  }

  /**
   * Header - who thematic clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Header%20-%20who%20thematic%20clicked)
   *
   * * who thematic = Avec qui partir ? 
   *
   * * when thematic = quelle période ?
   *
   * * what thematic = quelle activité ?
   *
   * * how thematic = quel style de voyage ?
   *
   *
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  headerWhoThematicClicked(
    properties: HeaderWhoThematicClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderWhoThematicClicked(properties), options);
  }

  /**
   * Help center clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Help%20center%20clicked)
   *
   * Clic permetant d'acdéder au help center
   *
   * Exemple sur la homepage
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  helpCenterClicked(
    properties: HelpCenterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HelpCenterClicked(properties), options);
  }

  /**
   * Help question completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Help%20question%20completed)
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  helpQuestionCompleted(
    properties: HelpQuestionCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HelpQuestionCompleted(properties), options);
  }

  /**
   * Help suggestion question completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Help%20suggestion%20question%20completed)
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  helpSuggestionQuestionCompleted(
    properties: HelpSuggestionQuestionCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HelpSuggestionQuestionCompleted(properties), options);
  }

  /**
   * Hub clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Hub%20clicked)
   *
   * This event is triggered when a hub is clicked.
   *
   * On the homepage : 
   *
   *
   *
   *
   *
   * On the where-to-go page :
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  hubClicked(
    properties: HubClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HubClicked(properties), options);
  }

  /**
   * Impact report clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Impact%20report%20clicked)
   *
   * à déclencher au clic sur le CTA “Lire le rapport d’impact Evaneos”
   *
   *
   *
   *
   * Owner: Kris Amazan
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  impactReportClicked(
    properties: ImpactReportClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ImpactReportClicked(properties), options);
  }

  /**
   * Information step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Information%20step%20completed)
   *
   * This event tracks when a user completes the information step in the payment funnel
   *
   *
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  informationStepCompleted(
    properties: InformationStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InformationStepCompleted(properties), options);
  }

  /**
   * Insurance step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Insurance%20step%20completed)
   *
   *
   *
   *
   * This event tracks the completion of a step in the insurance process on our website
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  insuranceStepCompleted(
    properties: InsuranceStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsuranceStepCompleted(properties), options);
  }

  /**
   * Insurance step more details button clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Insurance%20step%20more%20details%20button%20clicked)
   *
   * This event tracks when a user clicks on the "Details" button in the insurance step of the payment funnel 
   *
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  insuranceStepMoreDetailsButtonClicked(
    properties: InsuranceStepMoreDetailsButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsuranceStepMoreDetailsButtonClicked(properties), options);
  }

  /**
   * Insurance step other offers clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Insurance%20step%20other%20offers%20clicked)
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  insuranceStepOtherOffersClicked(
    properties: InsuranceStepOtherOffersClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsuranceStepOtherOffersClicked(properties), options);
  }

  /**
   * Insurance step product cancellation clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Insurance%20step%20product%20cancellation%20clicked)
   *
   *
   * This event tracks when a user clicks on the cancellation option during the insurance step of the payment funnel for a product
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  insuranceStepProductCancellationClicked(
    properties: InsuranceStepProductCancellationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsuranceStepProductCancellationClicked(properties), options);
  }

  /**
   * Insurance step product multiple risks clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Insurance%20step%20product%20multiple%20risks%20clicked)
   *
   *
   * This event tracks when a user clicks on multiple insurance risk options during the payment funnel of the insurance selection step
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  insuranceStepProductMultipleRisksClicked(
    properties: InsuranceStepProductMultipleRisksClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsuranceStepProductMultipleRisksClicked(properties), options);
  }

  /**
   * Insurance step product premium assistance clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Insurance%20step%20product%20premium%20assistance%20clicked)
   *
   * Traveler clicks on premium assistance insurance product in the insurance step in the payment funnel
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  insuranceStepProductPremiumAssistanceClicked(
    properties: InsuranceStepProductPremiumAssistanceClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsuranceStepProductPremiumAssistanceClicked(properties), options);
  }

  /**
   * Insurance step refusal clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Insurance%20step%20refusal%20clicked)
   *
   *
   * This event tracks when a user clicks on the "refusal" button during the insurance step of the payment funnel
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  insuranceStepRefusalClicked(
    properties: InsuranceStepRefusalClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsuranceStepRefusalClicked(properties), options);
  }

  /**
   * International flight help center clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/International%20flight%20help%20center%20clicked)
   *
   *
   *
   *  AB Test Cash - flight price in page desti
   *
   * Owner: Monica KESSNER
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  internationalFlightHelpCenterClicked(
    properties: InternationalFlightHelpCenterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InternationalFlightHelpCenterClicked(properties), options);
  }

  /**
   * Itineraries list clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Itineraries%20list%20clicked)
   *
   * Clic permettant d'afficher la page Liste Iti ensuite.
   *
   * Exemple : sur une page iti, on peut cliquer sur la navbar pour y accéder.
   *
   * les prop desti_id + desti_slug de la desti sur le bouton.
   *
   *
   *
   *
   * ••••••••••
   *
   * ou sur le CTA du bloc "no match" •••••••••••
   *
   *
   *
   *
   * Sur la page agence :
   *
   *
   *
   *
   * Sur la page Travel guide (block = facets)
   *
   *
   *
   *
   * sur le nouveau template Facet :
   *
   *
   * Sur la page liste-agence :
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  itinerariesListClicked(
    properties: ItinerariesListClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ItinerariesListClicked(properties), options);
  }

  /**
   * Itinerary clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Itinerary%20clicked)
   *
   * Clic sur un iti.
   *
   * Le block permet de savoir la position de l'iti dans la page.
   *
   *
   *
   *
   * On the itinerary page, if the card number is 0, then it correspond to the agent's pepite
   *
   *
   *
   *
   *
   * Sur la page facet (AB test de l'équipe activation juillet 2024) 
   *
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. agency_better_trip_index)
   * @param options Amplitude event options.
   */
  itineraryClicked(
    properties: ItineraryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ItineraryClicked(properties), options);
  }

  /**
   * Itinerary details clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Itinerary%20details%20clicked)
   *
   * Clique sur les CTA permettant d'afficher on-page des infos supplémentaires sur l'itinéraire.
   *
   * Test activation sur les pages facet version mobile (fin juillet 2024) :
   *
   *
   *
   *
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. card_number)
   * @param options Amplitude event options.
   */
  itineraryDetailsClicked(
    properties: ItineraryDetailsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ItineraryDetailsClicked(properties), options);
  }

  /**
   * Itinerary step click
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Itinerary%20step%20click)
   *
   *
   *
   *
   *  A Click on the day open the full description of a step Event to track when a user clicks on a step within an itinerary
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  itineraryStepClick(
    properties: ItineraryStepClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new ItineraryStepClick(properties), options);
  }

  /**
   * Itinerary wishlist added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Itinerary%20wishlist%20added)
   *
   * Clic permettant d'ajouter un iti à sa wishlist
   *
   *
   *
   *
   * ••••••
   *
   * \__\_ sur la nouvelle card Iti :
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  itineraryWishlistAdded(
    properties: ItineraryWishlistAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ItineraryWishlistAdded(properties), options);
  }

  /**
   * List activity onboarding skip clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/List%20activity%20onboarding%20skip%20clicked)
   *
   * Event to track users skipping the onboarding process for Evaneos Explore Activity List
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  listActivityOnboardingSkipClicked(
    properties: ListActivityOnboardingSkipClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListActivityOnboardingSkipClicked(properties), options);
  }

  /**
   * List activity onboarding start clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/List%20activity%20onboarding%20start%20clicked)
   *
   * Event to track when users click on the start button to begin the experience at the end of the onboarding process for Evaneos Explore Activity List 
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  listActivityOnboardingStartClicked(
    properties: ListActivityOnboardingStartClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListActivityOnboardingStartClicked(properties), options);
  }

  /**
   * List activity onboarding step 1 viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/List%20activity%20onboarding%20step%201%20viewed)
   *
   * L'étape 1 de l'onboarding Evaneos Explore Activities est vueEvent to track when users view step 1 of the onboarding process for Evaneos Explore Activity List
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  listActivityOnboardingStep1Viewed(
    properties: ListActivityOnboardingStep1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListActivityOnboardingStep1Viewed(properties), options);
  }

  /**
   * List activity onboarding step 2 viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/List%20activity%20onboarding%20step%202%20viewed)
   *
   * Event to track when users view step 2 of the onboarding process for Evaneos Explore Activity List
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  listActivityOnboardingStep2Viewed(
    properties: ListActivityOnboardingStep2ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListActivityOnboardingStep2Viewed(properties), options);
  }

  /**
   * List activity onboarding step 3 viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/List%20activity%20onboarding%20step%203%20viewed)
   *
   * Event to track when users view step 3 of the onboarding process for Evaneos Explore Activity List
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  listActivityOnboardingStep3Viewed(
    properties: ListActivityOnboardingStep3ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListActivityOnboardingStep3Viewed(properties), options);
  }

  /**
   * List itineraries filter clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/List%20itineraries%20filter%20clicked)
   *
   * Clic sur un filtre depuis la page list itineraries 
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  listItinerariesFilterClicked(
    properties: ListItinerariesFilterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListItinerariesFilterClicked(properties), options);
  }

  /**
   * List itineraries filter modal closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/List%20itineraries%20filter%20modal%20closed)
   *
   * Action de fermer la modale de filtre présente sur la page list itineraries
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  listItinerariesFilterModalClosed(
    properties: ListItinerariesFilterModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListItinerariesFilterModalClosed(properties), options);
  }

  /**
   * List itineraries filter selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/List%20itineraries%20filter%20selected)
   *
   * Valeur selectionée sur un filtre depuis la page list itineraries
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  listItinerariesFilterSelected(
    properties: ListItinerariesFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListItinerariesFilterSelected(properties), options);
  }

  /**
   * Magazine clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Magazine%20clicked)
   *
   * Clic sur un article du mag
   *
   *
   *
   *
   * Sur la homepage
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  magazineClicked(
    properties: MagazineClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MagazineClicked(properties), options);
  }

  /**
   * Map dragged or dropped
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Map%20dragged%20or%20dropped)
   *
   * Sur la crate, le user peut sélectionner la carte avec sa souris pour la bouger. Cette action déclenche cet event.
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Recorded)
   * @param options Amplitude event options.
   */
  mapDraggedOrDropped(
    properties: MapDraggedOrDroppedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MapDraggedOrDropped(properties), options);
  }

  /**
   * Map zoomed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Map%20zoomed)
   *
   * Zoom in or out sur la map
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  mapZoomed(
    properties: MapZoomedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MapZoomed(properties), options);
  }

  /**
   * Marketing destination details CTA clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Marketing%20destination%20details%20CTA%20clicked)
   *
   * Click on one of the CTA of the destination details page
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  marketingDestinationDetailsCtaClicked(
    properties: MarketingDestinationDetailsCtaClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MarketingDestinationDetailsCtaClicked(properties), options);
  }

  /**
   * Marketing top destination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Marketing%20top%20destination%20clicked)
   *
   * Click sur l'un des blocks desti de la landing page top desti:
   *
   *
   * Sur la homepage avec le block = highlight :
   *
   *
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. card_number)
   * @param options Amplitude event options.
   */
  marketingTopDestinationClicked(
    properties: MarketingTopDestinationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MarketingTopDestinationClicked(properties), options);
  }

  /**
   * Maturity question completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Maturity%20question%20completed)
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  maturityQuestionCompleted(
    properties: MaturityQuestionCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MaturityQuestionCompleted(properties), options);
  }

  /**
   * Modal redirection reco page clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Modal%20redirection%20reco%20page%20clicked)
   *
   * Event indicating when the CTA on a modale informing about recommendation page is clicked
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  modalRedirectionRecoPageClicked(
    properties: ModalRedirectionRecoPageClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModalRedirectionRecoPageClicked(properties), options);
  }

  /**
   * Modal redirection reco page closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Modal%20redirection%20reco%20page%20closed)
   *
   * Event indicating when a modale informing about recommendation page is closed
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  modalRedirectionRecoPageClosed(
    properties: ModalRedirectionRecoPageClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModalRedirectionRecoPageClosed(properties), options);
  }

  /**
   * Modal redirection reco page displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Modal%20redirection%20reco%20page%20displayed)
   *
   * Event indicating when a modale informing about recommendation page is displayed
   *
   *
   *
   *
   *
   *
   * ![](https://prod-files-secure.s3.us-west-2.amazonaws.com/d83f3cad-de62-4cd8-be63-e85c5f35bc56/9f80d1e6-6ac4-4e2a-ba69-31ddba7ad64d/image.png)
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  modalRedirectionRecoPageDisplayed(
    properties: ModalRedirectionRecoPageDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModalRedirectionRecoPageDisplayed(properties), options);
  }

  /**
   * Modal site suggestion closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Modal%20site%20suggestion%20closed)
   *
   * Uniquement sur next 
   *  Clic permettant de fermer la popin de langue : sur la croix ou via l'arrière plan du site) 
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  modalSiteSuggestionClosed(
    properties: ModalSiteSuggestionClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModalSiteSuggestionClosed(properties), options);
  }

  /**
   * Modal site suggestion displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Modal%20site%20suggestion%20displayed)
   *
   * Uniquement sur next
   *
   * Affichage de la popin permettant de selectionner la langue du site. *il ne faut pas être connecté ni avoir vu la modal depuis moins de 2 jours*
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  modalSiteSuggestionDisplayed(
    properties: ModalSiteSuggestionDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModalSiteSuggestionDisplayed(properties), options);
  }

  /**
   * More details clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/More%20details%20clicked)
   *
   * Clic sur chaque lien/CTA permettant d'afficher "plus de détail" 
   *
   * Cet event peut notamment déclencher :
   *
   * 1. L'ouverture d'une modale (=popin),
   *
   * 2. L'affichage d'un bloc texte,
   *
   * 3. L'ouverture d'une nouvelle page.
   *
   * Voici les events concernés sur la page iti :
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * Sur la page facet : bloc associé ***whentogo***
   *
   *
   *
   *
   * Sur le travel guide :
   *
   *
   *
   *
   *
   *
   *
   * Sur la page desti (migrée), ça correspond au block itineraries 
   *
   *
   * sur le nouveau template de facet, country et POI
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  moreDetailsClicked(
    properties: MoreDetailsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MoreDetailsClicked(properties), options);
  }

  /**
   * More itineraries clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/More%20itineraries%20clicked)
   *
   * Clic sur le CTA permettant d'afficher plus d'itinéraires. Le block associé est ***itineraries***
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  moreItinerariesClicked(
    properties: MoreItinerariesClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MoreItinerariesClicked(properties), options);
  }

  /**
   * More photo clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/More%20photo%20clicked)
   *
   * This event is triggered when a user clicked on the more photo button 
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  morePhotoClicked(
    properties: MorePhotoClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MorePhotoClicked(properties), options);
  }

  /**
   * More testimonials clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/More%20testimonials%20clicked)
   *
   * Clic sur le CTA permettant d'afficher plus de témoignages voyageurs au sein d'une même page
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  moreTestimonialsClicked(
    properties: MoreTestimonialsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MoreTestimonialsClicked(properties), options);
  }

  /**
   * My trip - Agency discussion clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Agency%20discussion%20clicked)
   *
   * This event tracks when a user has clicked on a button to switch to the discussion with the agent.
   *
   *
   *
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripAgencyDiscussionClicked(
    properties: MyTripAgencyDiscussionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripAgencyDiscussionClicked(properties), options);
  }

  /**
   * My trip - Agency information clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Agency%20information%20clicked)
   *
   *
   * This event tracks when a user clicks on agency information while viewing their trip details
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripAgencyInformationClicked(
    properties: MyTripAgencyInformationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripAgencyInformationClicked(properties), options);
  }

  /**
   * My trip - App download clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20App%20download%20clicked)
   *
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. app_system)
   * @param options Amplitude event options.
   */
  myTripAppDownloadClicked(
    properties: MyTripAppDownloadClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripAppDownloadClicked(properties), options);
  }

  /**
   * My Trip - Ask agent clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20Trip%20-%20Ask%20agent%20clicked)
   *
   *
   *
   * Event triggered when a user clicks on the "Ask agent" button during their trip on the Esper platform
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripAskAgentClicked(
    properties: MyTripAskAgentClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripAskAgentClicked(properties), options);
  }

  /**
   * My Trip - Ask agent closed clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20Trip%20-%20Ask%20agent%20closed%20clicked)
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripAskAgentClosedClicked(
    properties: MyTripAskAgentClosedClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripAskAgentClosedClicked(properties), options);
  }

  /**
   * My Trip - Ask agent sent clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20Trip%20-%20Ask%20agent%20sent%20clicked)
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripAskAgentSentClicked(
    properties: MyTripAskAgentSentClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripAskAgentSentClicked(properties), options);
  }

  /**
   * My Trip - Assistant closed clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20Trip%20-%20Assistant%20closed%20clicked)
   *
   * For mobile only
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripAssistantClosedClicked(
    properties: MyTripAssistantClosedClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripAssistantClosedClicked(properties), options);
  }

  /**
   * My Trip - Assistant opened clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20Trip%20-%20Assistant%20opened%20clicked)
   *
   * For mobile only
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripAssistantOpenedClicked(
    properties: MyTripAssistantOpenedClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripAssistantOpenedClicked(properties), options);
  }

  /**
   * My trip - Calendly form submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Calendly%20form%20submitted)
   *
   * Event to track when a user submit the calendly form to make an appointment with the agent in ESPER.
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripCalendlyFormSubmitted(
    properties: MyTripCalendlyFormSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripCalendlyFormSubmitted(properties), options);
  }

  /**
   * My trip - Call button clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Call%20button%20clicked)
   *
   * This event tracks when a user has clicked on the button for view Evaneos phone.
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripCallButtonClicked(
    properties: MyTripCallButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripCallButtonClicked(properties), options);
  }

  /**
   * My trip - Co traveler invited
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Co%20traveler%20invited)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripCoTravelerInvited(
    properties: MyTripCoTravelerInvitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripCoTravelerInvited(properties), options);
  }

  /**
   * My trip - Email clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Email%20clicked)
   *
   *
   *
   *
   * This event tracks when a user has clicked on the email and is redirected to the email inbox.
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripEmailClicked(
    properties: MyTripEmailClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripEmailClicked(properties), options);
  }

  /**
   * My trip - Evaneos number called
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Evaneos%20number%20called)
   *
   * This event tracks when a user has clicked on the phone and is redirected to the phone app.
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripEvaneosNumberCalled(
    properties: MyTripEvaneosNumberCalledProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripEvaneosNumberCalled(properties), options);
  }

  /**
   * My Trip - Explore flights clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20Trip%20-%20Explore%20flights%20clicked)
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripExploreFlightsClicked(
    properties: MyTripExploreFlightsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripExploreFlightsClicked(properties), options);
  }

  /**
   * My Trip - Flight clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20Trip%20-%20Flight%20clicked)
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripFlightClicked(
    properties: MyTripFlightClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripFlightClicked(properties), options);
  }

  /**
   * My trip - Flight information clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Flight%20information%20clicked)
   *
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripFlightInformationClicked(
    properties: MyTripFlightInformationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripFlightInformationClicked(properties), options);
  }

  /**
   * My trip - Help center clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Help%20center%20clicked)
   *
   * This event tracks when a user has clicked on a button to go to the FAQ.
   *
   *
   *
   *
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripHelpCenterClicked(
    properties: MyTripHelpCenterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripHelpCenterClicked(properties), options);
  }

  /**
   * My trip - I understand clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20I%20understand%20clicked)
   *
   * This event tracks when a user has clicked on a button "i understand".
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripIUnderstandClicked(
    properties: MyTripIUnderstandClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripIUnderstandClicked(properties), options);
  }

  /**
   * My trip - Insurance information clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Insurance%20information%20clicked)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripInsuranceInformationClicked(
    properties: MyTripInsuranceInformationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripInsuranceInformationClicked(properties), options);
  }

  /**
   * My trip - Message sent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Message%20sent)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripMessageSent(
    properties: MyTripMessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripMessageSent(properties), options);
  }

  /**
   * My trip - More details clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20More%20details%20clicked)
   *
   * This event tracks when a user has clicked on a button to get more details.
   *
   *
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  myTripMoreDetailsClicked(
    properties: MyTripMoreDetailsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripMoreDetailsClicked(properties), options);
  }

  /**
   * My Trip - No flight clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20Trip%20-%20No%20flight%20clicked)
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripNoFlightClicked(
    properties: MyTripNoFlightClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripNoFlightClicked(properties), options);
  }

  /**
   * My trip - Non-useful clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Non-useful%20clicked)
   *
   * This event tracks when a user has clicked on a button "this content is not useful".
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripNonUsefulClicked(
    properties: MyTripNonUsefulClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripNonUsefulClicked(properties), options);
  }

  /**
   * My trip - Payment schedule clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Payment%20schedule%20clicked)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripPaymentScheduleClicked(
    properties: MyTripPaymentScheduleClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripPaymentScheduleClicked(properties), options);
  }

  /**
   * My trip - Schedule appointment clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Schedule%20appointment%20clicked)
   *
   * Event to track when a user clicks on the "Make an appointment" button within the "My trip" in ESPER. 
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripScheduleAppointmentClicked(
    properties: MyTripScheduleAppointmentClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripScheduleAppointmentClicked(properties), options);
  }

  /**
   * My trip - Selection process clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Selection%20process%20clicked)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripSelectionProcessClicked(
    properties: MyTripSelectionProcessClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripSelectionProcessClicked(properties), options);
  }

  /**
   * My trip - Send email clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Send%20email%20clicked)
   *
   * This event tracks when a user has clicked on the button for view Evaneos email.
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripSendEmailClicked(
    properties: MyTripSendEmailClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripSendEmailClicked(properties), options);
  }

  /**
   * My Trip - Send info clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20Trip%20-%20Send%20info%20clicked)
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripSendInfoClicked(
    properties: MyTripSendInfoClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripSendInfoClicked(properties), options);
  }

  /**
   * My Trip - Send info closed clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20Trip%20-%20Send%20info%20closed%20clicked)
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripSendInfoClosedClicked(
    properties: MyTripSendInfoClosedClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripSendInfoClosedClicked(properties), options);
  }

  /**
   * My Trip - Send info sent clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20Trip%20-%20Send%20info%20sent%20clicked)
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripSendInfoSentClicked(
    properties: MyTripSendInfoSentClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripSendInfoSentClicked(properties), options);
  }

  /**
   * My trip - Shared document downloaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Shared%20document%20downloaded)
   *
   * This event tracks when a user views a shared document is downloaded 
   *
   *
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripSharedDocumentDownloaded(
    properties: MyTripSharedDocumentDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripSharedDocumentDownloaded(properties), options);
  }

  /**
   * My trip - Shared document viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Shared%20document%20viewed)
   *
   *
   *
   *
   * This event tracks when a user views a shared document within the "My Trip" feature
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripSharedDocumentViewed(
    properties: MyTripSharedDocumentViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripSharedDocumentViewed(properties), options);
  }

  /**
   * My trip - Testimonials clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Testimonials%20clicked)
   *
   *
   *
   * This event tracks when users click on the "Testimonials" section within the "My Trip" feature on the website
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripTestimonialsClicked(
    properties: MyTripTestimonialsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripTestimonialsClicked(properties), options);
  }

  /**
   * My trip - Useful clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Useful%20clicked)
   *
   * This event tracks when a user has clicked on a button "this content is useful".
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripUsefulClicked(
    properties: MyTripUsefulClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripUsefulClicked(properties), options);
  }

  /**
   * My trip - Visa information clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Visa%20information%20clicked)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. request_id)
   * @param options Amplitude event options.
   */
  myTripVisaInformationClicked(
    properties: MyTripVisaInformationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripVisaInformationClicked(properties), options);
  }

  /**
   * My trip - Wifi information clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20-%20Wifi%20information%20clicked)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripWifiInformationClicked(
    properties: MyTripWifiInformationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripWifiInformationClicked(properties), options);
  }

  /**
   * My Trip activity placeholder clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20Trip%20activity%20placeholder%20clicked)
   *
   * Event to track when a user clicks on the placeholder to add new activities on My Trip. Part of MVPOC Maturity Evaneos Explore
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripActivityPlaceholderClicked(
    properties: MyTripActivityPlaceholderClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripActivityPlaceholderClicked(properties), options);
  }

  /**
   * My trip button clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20button%20clicked)
   *
   * Event to track when a user clicks on the "My Trip" button on the website. Part of MVPOC Maturity Evaneos Explore.
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. activities_count)
   * @param options Amplitude event options.
   */
  myTripButtonClicked(
    properties: MyTripButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripButtonClicked(properties), options);
  }

  /**
   * My trip button displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trip%20button%20displayed)
   *
   * Event triggered when the "My Trip" button is shown to the user on the website. Part of MVPOC Maturity Evaneos Explore.
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. activities_count)
   * @param options Amplitude event options.
   */
  myTripButtonDisplayed(
    properties: MyTripButtonDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripButtonDisplayed(properties), options);
  }

  /**
   * My trips clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/My%20trips%20clicked)
   *
   * Le user clique sur "mes voyages" pour quitter la discussion entre Evaneos et lui après la création de sa request.
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  myTripsClicked(
    properties: MyTripsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyTripsClicked(properties), options);
  }

  /**
   * Navbar - agencies clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20-%20agencies%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  navbarAgenciesClicked(
    properties: NavbarAgenciesClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarAgenciesClicked(properties), options);
  }

  /**
   * Navbar - destination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20-%20destination%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  navbarDestinationClicked(
    properties: NavbarDestinationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarDestinationClicked(properties), options);
  }

  /**
   * Navbar - experience clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20-%20experience%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  navbarExperienceClicked(
    properties: NavbarExperienceClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarExperienceClicked(properties), options);
  }

  /**
   * Navbar - facet clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20-%20facet%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  navbarFacetClicked(
    properties: NavbarFacetClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarFacetClicked(properties), options);
  }

  /**
   * Navbar - group clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20-%20group%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  navbarGroupClicked(
    properties: NavbarGroupClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarGroupClicked(properties), options);
  }

  /**
   * Navbar - inspiration clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20-%20inspiration%20clicked)
   *
   * Permet d'accéder aux récits de voyages écrits par des voyageurs  
   *  *(ça dirige vers la category coco.list.inspiration)*
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  navbarInspirationClicked(
    properties: NavbarInspirationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarInspirationClicked(properties), options);
  }

  /**
   * Navbar - list itineraries clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20-%20list%20itineraries%20clicked)
   *
   *
   *
   *
   *
   * Sur certaine desti, il n'y a pas de facet associées donc on peut cliquer directement sur la liste iti depuis la navbar :
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  navbarListItinerariesClicked(
    properties: NavbarListItinerariesClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarListItinerariesClicked(properties), options);
  }

  /**
   * Navbar - places clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20-%20places%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  navbarPlacesClicked(
    properties: NavbarPlacesClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarPlacesClicked(properties), options);
  }

  /**
   * Navbar - practical details clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20-%20practical%20details%20clicked)
   *
   * *Ca envoie vers la page avec la category coco.article.essential*
   *
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  navbarPracticalDetailsClicked(
    properties: NavbarPracticalDetailsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarPracticalDetailsClicked(properties), options);
  }

  /**
   * Navbar - region clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20-%20region%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  navbarRegionClicked(
    properties: NavbarRegionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarRegionClicked(properties), options);
  }

  /**
   * Navbar - testimonial clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20-%20testimonial%20clicked)
   *
   * This event tracks when a user clicks on a testimonial in the website's navigation bar (!= header)
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  navbarTestimonialClicked(
    properties: NavbarTestimonialClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarTestimonialClicked(properties), options);
  }

  /**
   * Navbar - travel guide clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20-%20travel%20guide%20clicked)
   *
   * *Cela dirige vers coco.home*
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  navbarTravelGuideClicked(
    properties: NavbarTravelGuideClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarTravelGuideClicked(properties), options);
  }

  /**
   * Navbar iti - budget clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20iti%20-%20budget%20clicked)
   *
   * The user clicked on "Budget" on the navigation bar of the iti page.
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  navbarItiBudgetClicked(
    properties: NavbarItiBudgetClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarItiBudgetClicked(properties), options);
  }

  /**
   * Navbar iti - circuit clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20iti%20-%20circuit%20clicked)
   *
   * The user clicked on "circuit" on the navigation bar of the iti page.
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  navbarItiCircuitClicked(
    properties: NavbarItiCircuitClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarItiCircuitClicked(properties), options);
  }

  /**
   * Navbar iti - details clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20iti%20-%20details%20clicked)
   *
   * The user clicked on "details" on the navigation bar of the iti page.
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  navbarItiDetailsClicked(
    properties: NavbarItiDetailsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarItiDetailsClicked(properties), options);
  }

  /**
   * Navbar iti - transport clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Navbar%20iti%20-%20transport%20clicked)
   *
   * The user clicked on "transport" on the navigation bar of the iti page.
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  navbarItiTransportClicked(
    properties: NavbarItiTransportClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarItiTransportClicked(properties), options);
  }

  /**
   * Needs filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Needs%20filtered)
   *
   *
   * This event tracks when a user applies filters to narrow down their search for travel destinations on the website
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  needsFiltered(
    properties: NeedsFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new NeedsFiltered(properties), options);
  }

  /**
   * Newsletter subscribed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Newsletter%20subscribed)
   *
   * Event déclenché lorsqu’un user s’inscrit à la NL (=event de succès) La propriété block indique via quel élément l'utilisateur s'est inscrit : footer :sidebar_newsletter :
   *
   *
   *
   *
   * sidebar_newsletter:
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  newsletterSubscribed(
    properties: NewsletterSubscribedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewsletterSubscribed(properties), options);
  }

  /**
   * Next carousel clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Next%20carousel%20clicked)
   *
   * Clic sur la flèche du carrousel permettant d'afficher davantage de POI
   *
   * Sur la page desti, il y a aussi le carrousel des desti, région, facet, mag
   *
   * Sur la page reco, il y a un succession de carrousel
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  nextCarouselClicked(
    properties: NextCarouselClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NextCarouselClicked(properties), options);
  }

  /**
   * Next story clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Next%20story%20clicked)
   *
   * Clic/scroll permettant devoir la prochaine story 
   *
   * \[Ajouter screen\]
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  nextStoryClicked(
    properties: NextStoryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NextStoryClicked(properties), options);
  }

  /**
   * Non-useful button clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Non-useful%20button%20clicked)
   *
   * Event indicating that a user has clicked on a button this content is not useful.
   *
   * Related event: Useful button clicked
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  nonUsefulButtonClicked(
    properties: NonUsefulButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NonUsefulButtonClicked(properties), options);
  }

  /**
   * Opinion - POI searched
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Opinion%20-%20POI%20searched)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  opinionPoiSearched(
    properties: OpinionPoiSearchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpinionPoiSearched(properties), options);
  }

  /**
   * Opinion - POI testimonial published
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Opinion%20-%20POI%20testimonial%20published)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  opinionPoiTestimonialPublished(
    properties: OpinionPoiTestimonialPublishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpinionPoiTestimonialPublished(properties), options);
  }

  /**
   * Page shared
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Page%20shared)
   *
   * Clic permettant de partager une page. 
   *  Une propriété permet de distinguer le type de page (facet, iti...) 
   *  Ainsi que le type de partage (FB, twitter, whatsapp, lien, email ou impressison)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  pageShared(
    properties: PageSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageShared(properties), options);
  }

  /**
   * Pagination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pagination%20clicked)
   *
   * Clique sur un élément de pagination
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  paginationClicked(
    properties: PaginationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaginationClicked(properties), options);
  }

  /**
   * Partnership clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Partnership%20clicked)
   *
   * Click on one of the CTAs of the landing page partnership:
   *
   *
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  partnershipClicked(
    properties: PartnershipClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PartnershipClicked(properties), options);
  }

  /**
   * Pathfinder - accommodations defined
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20accommodations%20defined)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderAccommodationsDefined(
    properties: PathfinderAccommodationsDefinedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderAccommodationsDefined(properties), options);
  }

  /**
   * Pathfinder - accommodations question asked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20accommodations%20question%20asked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderAccommodationsQuestionAsked(
    properties: PathfinderAccommodationsQuestionAskedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderAccommodationsQuestionAsked(properties), options);
  }

  /**
   * Pathfinder - activities defined
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20activities%20defined)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderActivitiesDefined(
    properties: PathfinderActivitiesDefinedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderActivitiesDefined(properties), options);
  }

  /**
   * Pathfinder - activities question asked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20activities%20question%20asked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderActivitiesQuestionAsked(
    properties: PathfinderActivitiesQuestionAskedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderActivitiesQuestionAsked(properties), options);
  }

  /**
   * Pathfinder - advice asked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20advice%20asked)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderAdviceAsked(
    properties: PathfinderAdviceAskedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderAdviceAsked(properties), options);
  }

  /**
   * Pathfinder - advice given
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20advice%20given)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderAdviceGiven(
    properties: PathfinderAdviceGivenProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderAdviceGiven(properties), options);
  }

  /**
   * Pathfinder - agency presentation displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20agency%20presentation%20displayed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderAgencyPresentationDisplayed(
    properties: PathfinderAgencyPresentationDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderAgencyPresentationDisplayed(properties), options);
  }

  /**
   * Pathfinder - alternative destination selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20alternative%20destination%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_alternative_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderAlternativeDestinationSelected(
    properties: PathfinderAlternativeDestinationSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderAlternativeDestinationSelected(properties), options);
  }

  /**
   * Pathfinder - autonomous banner download clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20autonomous%20banner%20download%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  pathfinderAutonomousBannerDownloadClicked(
    properties: PathfinderAutonomousBannerDownloadClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderAutonomousBannerDownloadClicked(properties), options);
  }

  /**
   * Pathfinder - autonomous survey answer selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20autonomous%20survey%20answer%20selected)
   *
   * Choix d'une réponse au sein du survey autonomous affiché à l'issue du click sur "Planifier mon voyage"
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  pathfinderAutonomousSurveyAnswerSelected(
    properties: PathfinderAutonomousSurveyAnswerSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderAutonomousSurveyAnswerSelected(properties), options);
  }

  /**
   * Pathfinder - budget alternatives displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20budget%20alternatives%20displayed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_budget_alternatives_asked)
   * @param options Amplitude event options.
   */
  pathfinderBudgetAlternativesDisplayed(
    properties: PathfinderBudgetAlternativesDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderBudgetAlternativesDisplayed(properties), options);
  }

  /**
   * Pathfinder - budget component displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20budget%20component%20displayed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderBudgetComponentDisplayed(
    properties: PathfinderBudgetComponentDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderBudgetComponentDisplayed(properties), options);
  }

  /**
   * Pathfinder - budget confirmed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20budget%20confirmed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_budget_below_minimum)
   * @param options Amplitude event options.
   */
  pathfinderBudgetConfirmed(
    properties: PathfinderBudgetConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderBudgetConfirmed(properties), options);
  }

  /**
   * Pathfinder - budget defined
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20budget%20defined)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderBudgetDefined(
    properties: PathfinderBudgetDefinedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderBudgetDefined(properties), options);
  }

  /**
   * Pathfinder - budget question asked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20budget%20question%20asked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderBudgetQuestionAsked(
    properties: PathfinderBudgetQuestionAskedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderBudgetQuestionAsked(properties), options);
  }

  /**
   * Pathfinder - budget type selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20budget%20type%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_budget_type)
   * @param options Amplitude event options.
   */
  pathfinderBudgetTypeSelected(
    properties: PathfinderBudgetTypeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderBudgetTypeSelected(properties), options);
  }

  /**
   * Pathfinder - budget unknown selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20budget%20unknown%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderBudgetUnknownSelected(
    properties: PathfinderBudgetUnknownSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderBudgetUnknownSelected(properties), options);
  }

  /**
   * Pathfinder - contact agent selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20contact%20agent%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderContactAgentSelected(
    properties: PathfinderContactAgentSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderContactAgentSelected(properties), options);
  }

  /**
   * Pathfinder - dates defined
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20dates%20defined)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderDatesDefined(
    properties: PathfinderDatesDefinedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderDatesDefined(properties), options);
  }

  /**
   * Pathfinder - destination plan trip clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20destination%20plan%20trip%20clicked)
   *
   * Clic sur le CTA "Planifier mon voyage" sur une page desti : bloc intention.
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  pathfinderDestinationPlanTripClicked(
    properties: PathfinderDestinationPlanTripClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderDestinationPlanTripClicked(properties), options);
  }

  /**
   * Pathfinder - duration defined
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20duration%20defined)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderDurationDefined(
    properties: PathfinderDurationDefinedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderDurationDefined(properties), options);
  }

  /**
   * Pathfinder - experiences recommended
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20experiences%20recommended)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderExperiencesRecommended(
    properties: PathfinderExperiencesRecommendedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderExperiencesRecommended(properties), options);
  }

  /**
   * Pathfinder - experiences selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20experiences%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderExperiencesSelected(
    properties: PathfinderExperiencesSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderExperiencesSelected(properties), options);
  }

  /**
   * Pathfinder - first message sent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20first%20message%20sent)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderFirstMessageSent(
    properties: PathfinderFirstMessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderFirstMessageSent(properties), options);
  }

  /**
   * Pathfinder - group composition question asked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20group%20composition%20question%20asked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderGroupCompositionQuestionAsked(
    properties: PathfinderGroupCompositionQuestionAskedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderGroupCompositionQuestionAsked(properties), options);
  }

  /**
   * Pathfinder - group defined
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20group%20defined)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderGroupDefined(
    properties: PathfinderGroupDefinedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderGroupDefined(properties), options);
  }

  /**
   * Pathfinder - has minimum budget selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20has%20minimum%20budget%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderHasMinimumBudgetSelected(
    properties: PathfinderHasMinimumBudgetSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderHasMinimumBudgetSelected(properties), options);
  }

  /**
   * Pathfinder - inspiration message sent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20inspiration%20message%20sent)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderInspirationMessageSent(
    properties: PathfinderInspirationMessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderInspirationMessageSent(properties), options);
  }

  /**
   * Pathfinder - inspiration survey answered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20inspiration%20survey%20answered)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderInspirationSurveyAnswered(
    properties: PathfinderInspirationSurveyAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderInspirationSurveyAnswered(properties), options);
  }

  /**
   * Pathfinder - itinerary commented
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20itinerary%20commented)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderItineraryCommented(
    properties: PathfinderItineraryCommentedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderItineraryCommented(properties), options);
  }

  /**
   * Pathfinder - itinerary viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20itinerary%20viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderItineraryViewed(
    properties: PathfinderItineraryViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderItineraryViewed(properties), options);
  }

  /**
   * Pathfinder - message sent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20message%20sent)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderMessageSent(
    properties: PathfinderMessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderMessageSent(properties), options);
  }

  /**
   * Pathfinder - nav insights intents
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20nav%20insights%20intents)
   *
   * Evénement utilisé pour observer les intents inferrés par l'IA à partir du parcours sur le site
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderNavInsightsIntents(
    properties: PathfinderNavInsightsIntentsProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderNavInsightsIntents(properties), options);
  }

  /**
   * Pathfinder - no alternative destination selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20no%20alternative%20destination%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderNoAlternativeDestinationSelected(
    properties: PathfinderNoAlternativeDestinationSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderNoAlternativeDestinationSelected(properties), options);
  }

  /**
   * Pathfinder - no alternative selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20no%20alternative%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderNoAlternativeSelected(
    properties: PathfinderNoAlternativeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderNoAlternativeSelected(properties), options);
  }

  /**
   * Pathfinder - onboarding question answered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20onboarding%20question%20answered)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderOnboardingQuestionAnswered(
    properties: PathfinderOnboardingQuestionAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderOnboardingQuestionAnswered(properties), options);
  }

  /**
   * Pathfinder - project invalidated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20project%20invalidated)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_activities_evaluation)
   * @param options Amplitude event options.
   */
  pathfinderProjectInvalidated(
    properties: PathfinderProjectInvalidatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderProjectInvalidated(properties), options);
  }

  /**
   * Pathfinder - project validated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20project%20validated)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_activities_evaluation)
   * @param options Amplitude event options.
   */
  pathfinderProjectValidated(
    properties: PathfinderProjectValidatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderProjectValidated(properties), options);
  }

  /**
   * Pathfinder - project validation displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20project%20validation%20displayed)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_activities_evaluation)
   * @param options Amplitude event options.
   */
  pathfinderProjectValidationDisplayed(
    properties: PathfinderProjectValidationDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderProjectValidationDisplayed(properties), options);
  }

  /**
   * Pathfinder - request sent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20request%20sent)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_activities)
   * @param options Amplitude event options.
   */
  pathfinderRequestSent(
    properties: PathfinderRequestSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderRequestSent(properties), options);
  }

  /**
   * Pathfinder - travel dates question asked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20travel%20dates%20question%20asked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderTravelDatesQuestionAsked(
    properties: PathfinderTravelDatesQuestionAskedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderTravelDatesQuestionAsked(properties), options);
  }

  /**
   * Pathfinder - travel duration question asked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20travel%20duration%20question%20asked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderTravelDurationQuestionAsked(
    properties: PathfinderTravelDurationQuestionAskedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderTravelDurationQuestionAsked(properties), options);
  }

  /**
   * Pathfinder - view alternative destinations selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20view%20alternative%20destinations%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderViewAlternativeDestinationsSelected(
    properties: PathfinderViewAlternativeDestinationsSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderViewAlternativeDestinationsSelected(properties), options);
  }

  /**
   * Pathfinder - view budget alternatives selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20view%20budget%20alternatives%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderViewBudgetAlternativesSelected(
    properties: PathfinderViewBudgetAlternativesSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderViewBudgetAlternativesSelected(properties), options);
  }

  /**
   * Pathfinder - view itinerary selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20view%20itinerary%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderViewItinerarySelected(
    properties: PathfinderViewItinerarySelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderViewItinerarySelected(properties), options);
  }

  /**
   * Pathfinder - view other experiences selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pathfinder%20-%20view%20other%20experiences%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  pathfinderViewOtherExperiencesSelected(
    properties: PathfinderViewOtherExperiencesSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PathfinderViewOtherExperiencesSelected(properties), options);
  }

  /**
   * Pay button on payment schedule clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pay%20button%20on%20payment%20schedule%20clicked)
   *
   *
   *
   *
   * Event: Pay button on payment schedule clicked (Payment funnel)
   *
   * Description: This event tracks when a user clicks on the "Pay" button within the payment schedule, which is part of the payment funnel. It indicates user engagement and progression towards completing a payment transaction 
   *
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  payButtonOnPaymentScheduleClicked(
    properties: PayButtonOnPaymentScheduleClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayButtonOnPaymentScheduleClicked(properties), options);
  }

  /**
   * Payment information step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Payment%20information%20step%20completed)
   *
   *
   * Event: Payment information step completed
   *
   * Description: This event is triggered when a user successfully completes the payment information step during the booking process. It indicates that the user has provided their payment details and is ready to proceed with the transaction
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  paymentInformationStepCompleted(
    properties: PaymentInformationStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentInformationStepCompleted(properties), options);
  }

  /**
   * Payment information step failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Payment%20information%20step%20failed)
   *
   * This event tracks when a user fails to complete the payment information step during their journey on the website.
   *
   *
   * For now we only track the payment type selected (CB, Visa, etc)
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  paymentInformationStepFailed(
    properties: PaymentInformationStepFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentInformationStepFailed(properties), options);
  }

  /**
   * Payment type changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Payment%20type%20changed)
   *
   * This event tracks the submission of a payment in the payment funnel
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  paymentTypeChanged(
    properties: PaymentTypeChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentTypeChanged(properties), options);
  }

  /**
   * Period filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Period%20filtered)
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  periodFiltered(
    properties: PeriodFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new PeriodFiltered(properties), options);
  }

  /**
   * Personal information - Advice emails clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Personal%20information%20-%20Advice%20emails%20clicked)
   *
   * Clic sur cet optin
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Recorded)
   * @param options Amplitude event options.
   */
  personalInformationAdviceEmailsClicked(
    properties: PersonalInformationAdviceEmailsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PersonalInformationAdviceEmailsClicked(properties), options);
  }

  /**
   * Personal information - Information registered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Personal%20information%20-%20Information%20registered)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  personalInformationInformationRegistered(
    properties: PersonalInformationInformationRegisteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new PersonalInformationInformationRegistered(properties), options);
  }

  /**
   * Personal information - Newsletter emails clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Personal%20information%20-%20Newsletter%20emails%20clicked)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  personalInformationNewsletterEmailsClicked(
    properties: PersonalInformationNewsletterEmailsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PersonalInformationNewsletterEmailsClicked(properties), options);
  }

  /**
   * Personal information - Password registred
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Personal%20information%20-%20Password%20registred)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  personalInformationPasswordRegistred(
    properties: PersonalInformationPasswordRegistredProperties,
    options?: EventOptions,
  ) {
    return this.track(new PersonalInformationPasswordRegistred(properties), options);
  }

  /**
   * Personal information - Sms notifications clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Personal%20information%20-%20Sms%20notifications%20clicked)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  personalInformationSmsNotificationsClicked(
    properties: PersonalInformationSmsNotificationsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PersonalInformationSmsNotificationsClicked(properties), options);
  }

  /**
   * POI clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/POI%20clicked)
   *
   * Pour distinguer les cas, il faut utiliser la notion de block et category.
   *
   *
   *
   *
   * OU
   *
   *
   *
   *
   * OU sur une page facet MVP : un nouveau type de click est ajouté. Ca sera \*\*\*card. \*\*\**A date (14/06/2023), on ne suit pas les tracks sur le texte*
   *
   *
   *
   *
   *
   *
   *
   * Sur la page Travel guide : 
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  poiClicked(
    properties: PoiClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PoiClicked(properties), options);
  }

  /**
   * POI cluster clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/POI%20cluster%20clicked)
   *
   * Clic sur un aggréagat de POI
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  poiClusterClicked(
    properties: PoiClusterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PoiClusterClicked(properties), options);
  }

  /**
   * POI list clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/POI%20list%20clicked)
   *
   * Clic sur le CTA permettant d'afficher tous les POI d'une desti
   *
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  poiListClicked(
    properties: PoiListClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PoiListClicked(properties), options);
  }

  /**
   * Pop-in login displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Pop-in%20login%20displayed)
   *
   * Event to track when the pop-in login is displayed to users on Evaneos Explore Activity List
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  popInLoginDisplayed(
    properties: PopInLoginDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PopInLoginDisplayed(properties), options);
  }

  /**
   * Popin details itinerary closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Popin%20details%20itinerary%20closed)
   *
   * Close of the popin about the details of an iti in the hesitating tipform flow in the offer step (1st use in the Exp. hesitating in Oct. 24):
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  popinDetailsItineraryClosed(
    properties: PopinDetailsItineraryClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PopinDetailsItineraryClosed(properties), options);
  }

  /**
   * Popin infos destination closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Popin%20infos%20destination%20closed)
   *
   * Close of the popin info destination in the flow specific to the hesitating users:
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  popinInfosDestinationClosed(
    properties: PopinInfosDestinationClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PopinInfosDestinationClosed(properties), options);
  }

  /**
   * Popin newsletter closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Popin%20newsletter%20closed)
   *
   * This event is triggered when the modal of the newsletter has been closed
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  popinNewsletterClosed(
    properties: PopinNewsletterClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PopinNewsletterClosed(properties), options);
  }

  /**
   * Popin newsletter displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Popin%20newsletter%20displayed)
   *
   * This event is displayed if the newsletter pop-in is displayed to the user
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  popinNewsletterDisplayed(
    properties: PopinNewsletterDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PopinNewsletterDisplayed(properties), options);
  }

  /**
   * Popin save activities closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Popin%20save%20activities%20closed)
   *
   * The user has closed the Popin "save my activities".
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  popinSaveActivitiesClosed(
    properties: PopinSaveActivitiesClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PopinSaveActivitiesClosed(properties), options);
  }

  /**
   * Popin save activities displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Popin%20save%20activities%20displayed)
   *
   * Event to track when user see the popin pushing him to save his trip project in Evaneos Explore MVPOC. Only if not already logged-in user and has at least added 1 activity. 
   *
   *
   *
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  popinSaveActivitiesDisplayed(
    properties: PopinSaveActivitiesDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PopinSaveActivitiesDisplayed(properties), options);
  }

  /**
   * Practical information clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Practical%20information%20clicked)
   *
   * L'event est déclenché uniquement lorsque le user clique afin de dérouler le texte. 
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  practicalInformationClicked(
    properties: PracticalInformationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PracticalInformationClicked(properties), options);
  }

  /**
   * Practical information displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Practical%20information%20displayed)
   *
   * Practical information popin displayed
   *
   *
   *
   *
   *
   * Owner: Anne-Camille Favret
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  practicalInformationDisplayed(
    properties: PracticalInformationDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PracticalInformationDisplayed(properties), options);
  }

  /**
   * Preferences choice viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Preferences%20choice%20viewed)
   *
   * Vu de la modale de modification des préférences, accessible page reco.  
   *
   *
   *
   *
   * Owner: clarisse.bolignano@external.evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  preferencesChoiceViewed(
    properties: PreferencesChoiceViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreferencesChoiceViewed(properties), options);
  }

  /**
   * Preferences closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Preferences%20closed)
   *
   * The user close this modal without save 
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  preferencesClosed(
    properties: PreferencesClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreferencesClosed(properties), options);
  }

  /**
   * Preferences closed without save
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Preferences%20closed%20without%20save)
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Replay ID)
   * @param options Amplitude event options.
   */
  preferencesClosedWithoutSave(
    properties: PreferencesClosedWithoutSaveProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreferencesClosedWithoutSave(properties), options);
  }

  /**
   * Preferences updated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Preferences%20updated)
   *
   * Lorsque l’utilisateur a modifié au moins une préférence et clique sur “Enregistrer”
   *
   *
   *
   *
   * Owner: clarisse.bolignano@external.evaneos.com
   *
   * @param properties The event's properties (e.g. budget_updated)
   * @param options Amplitude event options.
   */
  preferencesUpdated(
    properties: PreferencesUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreferencesUpdated(properties), options);
  }

  /**
   * Previous story clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Previous%20story%20clicked)
   *
   * Clic/scroll permettant devoir la prochaine story 
   *
   * \[Ajouter screen\]
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  previousStoryClicked(
    properties: PreviousStoryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreviousStoryClicked(properties), options);
  }

  /**
   * Primary nav - account clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20account%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavAccountClicked(
    properties: PrimaryNavAccountClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavAccountClicked(properties), options);
  }

  /**
   * Primary nav - agencies list clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20agencies%20list%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavAgenciesListClicked(
    properties: PrimaryNavAgenciesListClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavAgenciesListClicked(properties), options);
  }

  /**
   * Primary nav - all destinations clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20all%20destinations%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavAllDestinationsClicked(
    properties: PrimaryNavAllDestinationsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavAllDestinationsClicked(properties), options);
  }

  /**
   * Primary nav - B corp clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20B%20corp%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavBCorpClicked(
    properties: PrimaryNavBCorpClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavBCorpClicked(properties), options);
  }

  /**
   * Primary nav - carbon neutral clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20carbon%20neutral%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavCarbonNeutralClicked(
    properties: PrimaryNavCarbonNeutralClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavCarbonNeutralClicked(properties), options);
  }

  /**
   * Primary nav - commitments clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20commitments%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavCommitmentsClicked(
    properties: PrimaryNavCommitmentsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavCommitmentsClicked(properties), options);
  }

  /**
   * Primary nav - concept clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20concept%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavConceptClicked(
    properties: PrimaryNavConceptClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavConceptClicked(properties), options);
  }

  /**
   * Primary nav - continent clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20continent%20clicked)
   *
   *
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavContinentClicked(
    properties: PrimaryNavContinentClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavContinentClicked(properties), options);
  }

  /**
   * Primary nav - continent selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20continent%20selected)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavContinentSelected(
    properties: PrimaryNavContinentSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavContinentSelected(properties), options);
  }

  /**
   * Primary nav - destination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20destination%20clicked)
   *
   *
   *
   *
   * Cet event peut également être déclenché lors du clic, sur le carrousel région, sur une destination de type région. 
   *  Nous pouvons distinguer ces deux cas via la propriété click*type 
   *  - click*type = text pour la position en haut (ici Voyage Italie) 
   *  - click_type = card pour la position dans le carrousel
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavDestinationClicked(
    properties: PrimaryNavDestinationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavDestinationClicked(properties), options);
  }

  /**
   * Primary nav - destination recommendation clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20destination%20recommendation%20clicked)
   *
   *
   * Uniquement pour les users connectés qui ont une recommandation proposée
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavDestinationRecommendationClicked(
    properties: PrimaryNavDestinationRecommendationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavDestinationRecommendationClicked(properties), options);
  }

  /**
   * Primary nav - destination wheretogo clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20destination%20wheretogo%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavDestinationWheretogoClicked(
    properties: PrimaryNavDestinationWheretogoClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavDestinationWheretogoClicked(properties), options);
  }

  /**
   * Primary nav - explore clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20explore%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavExploreClicked(
    properties: PrimaryNavExploreClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavExploreClicked(properties), options);
  }

  /**
   * Primary nav - favorite clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20favorite%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavFavoriteClicked(
    properties: PrimaryNavFavoriteClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavFavoriteClicked(properties), options);
  }

  /**
   * Primary nav - help center clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20help%20center%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavHelpCenterClicked(
    properties: PrimaryNavHelpCenterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavHelpCenterClicked(properties), options);
  }

  /**
   * Primary nav - hub destination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20hub%20destination%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavHubDestinationClicked(
    properties: PrimaryNavHubDestinationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavHubDestinationClicked(properties), options);
  }

  /**
   * Primary nav - impact report clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20impact%20report%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavImpactReportClicked(
    properties: PrimaryNavImpactReportClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavImpactReportClicked(properties), options);
  }

  /**
   * Primary nav - itineraries list clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20itineraries%20list%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavItinerariesListClicked(
    properties: PrimaryNavItinerariesListClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavItinerariesListClicked(properties), options);
  }

  /**
   * Primary nav - logo clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20logo%20clicked)
   *
   * Clic sur le logo sur le header  
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavLogoClicked(
    properties: PrimaryNavLogoClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavLogoClicked(properties), options);
  }

  /**
   * Primary nav - logout clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20logout%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavLogoutClicked(
    properties: PrimaryNavLogoutClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavLogoutClicked(properties), options);
  }

  /**
   * Primary nav - magazine clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20magazine%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavMagazineClicked(
    properties: PrimaryNavMagazineClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavMagazineClicked(properties), options);
  }

  /**
   * Primary nav - other destination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20other%20destination%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavOtherDestinationClicked(
    properties: PrimaryNavOtherDestinationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavOtherDestinationClicked(properties), options);
  }

  /**
   * Primary nav - our impact clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20our%20impact%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavOurImpactClicked(
    properties: PrimaryNavOurImpactClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavOurImpactClicked(properties), options);
  }

  /**
   * Primary nav - personal info clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20personal%20info%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavPersonalInfoClicked(
    properties: PrimaryNavPersonalInfoClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavPersonalInfoClicked(properties), options);
  }

  /**
   * Primary nav - POI list clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20POI%20list%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavPoiListClicked(
    properties: PrimaryNavPoiListClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavPoiListClicked(properties), options);
  }

  /**
   * Primary nav - previous step clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20previous%20step%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavPreviousStepClicked(
    properties: PrimaryNavPreviousStepClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavPreviousStepClicked(properties), options);
  }

  /**
   * Primary nav - recommendation clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20recommendation%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavRecommendationClicked(
    properties: PrimaryNavRecommendationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavRecommendationClicked(properties), options);
  }

  /**
   * Primary nav - region clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20region%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavRegionClicked(
    properties: PrimaryNavRegionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavRegionClicked(properties), options);
  }

  /**
   * Primary nav - testimonial clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20testimonial%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavTestimonialClicked(
    properties: PrimaryNavTestimonialClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavTestimonialClicked(properties), options);
  }

  /**
   * Primary nav - thematic clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20thematic%20clicked)
   *
   *
   *
   *
   *
   *
   *
   * ```
   * ```
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavThematicClicked(
    properties: PrimaryNavThematicClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavThematicClicked(properties), options);
  }

  /**
   * Primary nav - thematic selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20thematic%20selected)
   *
   *
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavThematicSelected(
    properties: PrimaryNavThematicSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavThematicSelected(properties), options);
  }

  /**
   * Primary nav - top destination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20top%20destination%20clicked)
   *
   * Clic sur une des cards de la primary nav - onglet Destinations
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavTopDestinationClicked(
    properties: PrimaryNavTopDestinationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavTopDestinationClicked(properties), options);
  }

  /**
   * Primary nav - travel guide clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20travel%20guide%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavTravelGuideClicked(
    properties: PrimaryNavTravelGuideClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavTravelGuideClicked(properties), options);
  }

  /**
   * Primary nav - trips list clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20trips%20list%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavTripsListClicked(
    properties: PrimaryNavTripsListClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavTripsListClicked(properties), options);
  }

  /**
   * Primary nav - whentogo clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20whentogo%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavWhentogoClicked(
    properties: PrimaryNavWhentogoClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavWhentogoClicked(properties), options);
  }

  /**
   * Primary nav - wheretogo clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Primary%20nav%20-%20wheretogo%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  primaryNavWheretogoClicked(
    properties: PrimaryNavWheretogoClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrimaryNavWheretogoClicked(properties), options);
  }

  /**
   * Promotion card clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Promotion%20card%20clicked)
   *
   * The user clicked on the promotion card (only one card is displayed depenging on wether or not the user has a trip project).
   *
   *
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  promotionCardClicked(
    properties: PromotionCardClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PromotionCardClicked(properties), options);
  }

  /**
   * Question clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Question%20clicked)
   *
   * Clic sur la question entrainant une découverte du texte de réponse
   *
   *
   *
   *
   * Sur la homepage :
   *
   *
   *
   *  La propriété is_open est à false par défaut (pyrite)
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  questionClicked(
    properties: QuestionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuestionClicked(properties), options);
  }

  /**
   * Recommendation clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20clicked)
   *
   * Tracks when a CTA redirecting to the recommendation page is clicked
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  recommendationClicked(
    properties: RecommendationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationClicked(properties), options);
  }

  /**
   * Recommendation modal agency closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20modal%20agency%20closed)
   *
   * Tracks when the user closes the modal
   *
   *
   *
   *
   *
   * Owner: Anne-Camille Favret
   *
   * @param properties The event's properties (e.g. button_type)
   * @param options Amplitude event options.
   */
  recommendationModalAgencyClosed(
    properties: RecommendationModalAgencyClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationModalAgencyClosed(properties), options);
  }

  /**
   * Recommendation modal agency displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20modal%20agency%20displayed)
   *
   * Visualization recommendation modal agency step 
   *
   *
   *
   *
   *
   * Owner: Anne-Camille Favret
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  recommendationModalAgencyDisplayed(
    properties: RecommendationModalAgencyDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationModalAgencyDisplayed(properties), options);
  }

  /**
   * Recommendation modal closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20modal%20closed)
   *
   * Tracks when the recommendation modal is closed. Steps are defined thanks to properties step_number and step_name
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  recommendationModalClosed(
    properties: RecommendationModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationModalClosed(properties), options);
  }

  /**
   * Recommendation modal destination closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20modal%20destination%20closed)
   *
   * The user closed the modal
   *
   *
   *
   *
   *
   * Owner: Anne-Camille Favret
   *
   * @param properties The event's properties (e.g. button_type)
   * @param options Amplitude event options.
   */
  recommendationModalDestinationClosed(
    properties: RecommendationModalDestinationClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationModalDestinationClosed(properties), options);
  }

  /**
   * Recommendation modal destination displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20modal%20destination%20displayed)
   *
   * Visualization recommendation modal destination step 
   *
   *
   *
   *
   *
   * Owner: Anne-Camille Favret
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  recommendationModalDestinationDisplayed(
    properties: RecommendationModalDestinationDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationModalDestinationDisplayed(properties), options);
  }

  /**
   * Recommendation modal displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20modal%20displayed)
   *
   * Tracks the display of the recommendation Modal. Steps are defined thanks to properties step_number and step_name
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  recommendationModalDisplayed(
    properties: RecommendationModalDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationModalDisplayed(properties), options);
  }

  /**
   * Recommendation modal inspiration closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20modal%20inspiration%20closed)
   *
   *
   *
   *
   *
   * Tracks when the user closes the modal
   *
   * Owner: Anne-Camille Favret
   *
   * @param properties The event's properties (e.g. button_type)
   * @param options Amplitude event options.
   */
  recommendationModalInspirationClosed(
    properties: RecommendationModalInspirationClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationModalInspirationClosed(properties), options);
  }

  /**
   * Recommendation modal inspiration displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20modal%20inspiration%20displayed)
   *
   * Visualization recommendation modal inspiration step
   *
   *
   *
   *
   * Owner: Anne-Camille Favret
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  recommendationModalInspirationDisplayed(
    properties: RecommendationModalInspirationDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationModalInspirationDisplayed(properties), options);
  }

  /**
   * Recommendation modal next clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20modal%20next%20clicked)
   *
   * Allow us to understand if a user cliks on the "next" CTA of the recommendation modal and the choice he made
   *
   *
   *
   *
   *
   * Owner: Anne-Camille Favret
   *
   * @param properties The event's properties (e.g. card_number)
   * @param options Amplitude event options.
   */
  recommendationModalNextClicked(
    properties: RecommendationModalNextClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationModalNextClicked(properties), options);
  }

  /**
   * Recommendation modal previous clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20modal%20previous%20clicked)
   *
   * Tracks the CTA "previous" is clicked in a recommandation modal. Steps are defined thanks to properties step_number and step_name
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  recommendationModalPreviousClicked(
    properties: RecommendationModalPreviousClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationModalPreviousClicked(properties), options);
  }

  /**
   * Recommendation modal support closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20modal%20support%20closed)
   *
   * Tracks when the user closes the modal
   *
   *
   *
   *
   *
   * Owner: Anne-Camille Favret
   *
   * @param properties The event's properties (e.g. button_type)
   * @param options Amplitude event options.
   */
  recommendationModalSupportClosed(
    properties: RecommendationModalSupportClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationModalSupportClosed(properties), options);
  }

  /**
   * Recommendation modal support displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20modal%20support%20displayed)
   *
   * Visualization recommendation modal support step
   *
   *
   *
   *
   * Owner: Anne-Camille Favret
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  recommendationModalSupportDisplayed(
    properties: RecommendationModalSupportDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationModalSupportDisplayed(properties), options);
  }

  /**
   * Recommendation page teaser clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20page%20teaser%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  recommendationPageTeaserClicked(
    properties: RecommendationPageTeaserClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationPageTeaserClicked(properties), options);
  }

  /**
   * Recommendation page teaser closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20page%20teaser%20closed)
   *
   *
   *
   *  Quand on clique sur la croix ou qu'on clique sur le CTA, l'event est envoyé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  recommendationPageTeaserClosed(
    properties: RecommendationPageTeaserClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationPageTeaserClosed(properties), options);
  }

  /**
   * Recommendation page teaser CTA clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20page%20teaser%20CTA%20clicked)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  recommendationPageTeaserCtaClicked(
    properties: RecommendationPageTeaserCtaClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationPageTeaserCtaClicked(properties), options);
  }

  /**
   * Recommendation page teaser displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendation%20page%20teaser%20displayed)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  recommendationPageTeaserDisplayed(
    properties: RecommendationPageTeaserDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationPageTeaserDisplayed(properties), options);
  }

  /**
   * Recommendations - Preferences clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Recommendations%20-%20Preferences%20clicked)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  recommendationsPreferencesClicked(
    properties: RecommendationsPreferencesClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendationsPreferencesClicked(properties), options);
  }

  /**
   * Region clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Region%20clicked)
   *
   * Pour l'instant dispo uniquement sur les pages Régions et Desti
   *
   * ou sur whentogo region :
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  regionClicked(
    properties: RegionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegionClicked(properties), options);
  }

  /**
   * Region filter clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Region%20filter%20clicked)
   *
   * Event indicating that a user has clicked on a region filter on the Evaneos Explore Activity List
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  regionFilterClicked(
    properties: RegionFilterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegionFilterClicked(properties), options);
  }

  /**
   * Region filter selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Region%20filter%20selected)
   *
   * Event indicating that a user has selected a specific region filter for Evaneos Explore Activity List
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  regionFilterSelected(
    properties: RegionFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegionFilterSelected(properties), options);
  }

  /**
   * Reinsurance clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Reinsurance%20clicked)
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  reinsuranceClicked(
    properties: ReinsuranceClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReinsuranceClicked(properties), options);
  }

  /**
   * Request Sent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Request%20Sent)
   *
   * Cet event est déclenché au clic sur le CTA d’envoi d’un TF lorsque la demande de devis n’est pas sujette à une requalification. Dans ce cas, les events Tripform sent, Request sent et Viewed page completedForm doivent s’enchaîner de façon très proche.
   *
   * Lorsqu’il y a une requalification, cet event est envoyé au même moment que l’event Viewed page completedForm. Cela nous permet de récupérer le tripform_id et request*id.  
   *  *Pour des raisons technique, la propriété quality_grouping ne peut pas être envoyée lorsqu'il y a une requalification. Cette propriété est donc passée en optionnelle. 
   *  Attention : à date (19/07), les events provenant de device Apple semblent bcp moins remonter que les autres. Une issue est identifiée côté tech.
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. already_existing)
   * @param options Amplitude event options.
   */
  requestSent(
    properties: RequestSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new RequestSent(properties), options);
  }

  /**
   * Rhythm question answered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Rhythm%20question%20answered)
   *
   * The user answered the rhythm question in the discussion with Evaneos after the creation of his request.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  rhythmQuestionAnswered(
    properties: RhythmQuestionAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new RhythmQuestionAnswered(properties), options);
  }

  /**
   * Rhythm question displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Rhythm%20question%20displayed)
   *
   * We display the rhtyhm question in the discussion to the user after the creation of his request.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  rhythmQuestionDisplayed(
    properties: RhythmQuestionDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RhythmQuestionDisplayed(properties), options);
  }

  /**
   * Save my trip clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Save%20my%20trip%20clicked)
   *
   * Event indicating that a user has clicked on the "Save my trip" button on Evaneos Explore Activity List.
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  saveMyTripClicked(
    properties: SaveMyTripClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SaveMyTripClicked(properties), options);
  }

  /**
   * Save my trip later clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Save%20my%20trip%20later%20clicked)
   *
   * The user has clicked on "later" when the save my trip popin was displayed.
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  saveMyTripLaterClicked(
    properties: SaveMyTripLaterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SaveMyTripLaterClicked(properties), options);
  }

  /**
   * Searchbar used
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Searchbar%20used)
   *
   * This event track the search bar usage 
   *
   *
   * It either track the "j'y vais" button next to the search bar :
   *
   *
   * and/or what the user enter in the search bar :
   *
   *
   *
   *
   * It also track if the user used the scrolling menu :
   *
   *
   *
   * DETAIL TRACKING :
   *
   * Si le boutton est cliqué :
   * Alors button_clicked is True et on enregistre la search value au moment où le bouton est cliqué, potentiellement la valeur est none si l'utilisateur n'a rien entré
   *
   * Si le boutton n'est pas cliqué et que l'utilisateur a cliqué sur le menu déroulant : On enregistre l'autocomplete value sur lequel a cliqué le user
   *
   * Si le boutton n'est pas cliqué et que l'utilisateur n'a pas cliqué sur le menu déroulant :
   * On enregistre la dernière valeur que l'utilisateur a entré dans la search bar
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  searchbarUsed(
    properties: SearchbarUsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchbarUsed(properties), options);
  }

  /**
   * Show map clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Show%20map%20clicked)
   *
   * The user wants to see the map on mobile.
   *
   *
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  showMapClicked(
    properties: ShowMapClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShowMapClicked(properties), options);
  }

  /**
   * Sign in button clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Sign%20in%20button%20clicked)
   *
   * The user has clicked in "Sign in" when he was presented with the save my activities pop in at exit/inactivity.
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  signInButtonClicked(
    properties: SignInButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignInButtonClicked(properties), options);
  }

  /**
   * Sign up button clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Sign%20up%20button%20clicked)
   *
   * The user has clicked on Create my account when he was presented the save my activities popin at exit/inactivity.
   * ![](https://files.slack.com/files-tmb/T024YKAMT-F073Y9GJBU7-66da1f2db8/image_480.png)![](https://files.slack.com/files-tmb/T024YKAMT-F073Y9GJBU7-66da1f2db8/image_480.png)![](https://files.slack.com/files-tmb/T024YKAMT-F073Y9GJBU7-66da1f2db8/image_480.png)
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  signUpButtonClicked(
    properties: SignUpButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignUpButtonClicked(properties), options);
  }

  /**
   * Site suggestion changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Site%20suggestion%20changed)
   *
   * Uniquement sur next
   *
   * Sur la popin de langue, clic pour que le user change de site. Ici ça serait sur le drapeau FR ou sur le CRA "go to website" 
   *
   *
   * Si le user selectionne le site proposé, alors site_suggested = true (ici ça serait le cas si le user selectionne le site FR) 
   *
   *
   * sinon, c'est à false quand le user selectionne une autre langue dans le menu déroulant
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  siteSuggestionChanged(
    properties: SiteSuggestionChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SiteSuggestionChanged(properties), options);
  }

  /**
   * Site suggestion stayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Site%20suggestion%20stayed)
   *
   * Uniquement sur next
   *
   * Sur la popin de langue, clic sur le site où est déjà le user. Ici c'est le clic vers le site Italien 
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  siteSuggestionStayed(
    properties: SiteSuggestionStayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SiteSuggestionStayed(properties), options);
  }

  /**
   * Story started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Story%20started)
   *
   * Dans le cadre d'un test, ajout d'un CTA permettant de voir des Stories sur certaines pages destinations
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  storyStarted(
    properties: StoryStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StoryStarted(properties), options);
  }

  /**
   * Suggestion clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Suggestion%20clicked)
   *
   * Suivi du clic sur les suggestions de l'AB test présent sur le TF par Better Business.
   *
   * Les users peuvent cliquer sur une Desti ou une Facet : cela dépend des destinations et des marchés. Une propriété est ajoutée pour distinguer si c'est une facet ou une desti.
   *
   * Sur la maquette, c'est le clic sur des Agences mais elle n'est pas à jour: maintenant c'est des desti ou des facets.
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  suggestionClicked(
    properties: SuggestionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SuggestionClicked(properties), options);
  }

  /**
   * Suitcase article clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Suitcase%20article%20clicked)
   *
   * clic sur un bouton menant à une page valise
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  suitcaseArticleClicked(
    properties: SuitcaseArticleClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SuitcaseArticleClicked(properties), options);
  }

  /**
   * Summary step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Summary%20step%20completed)
   *
   *
   * This event tracks the completion of the summary step in the payment funnel. It indicates that users have successfully reviewed and confirmed their payment details before proceeding to the next step
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  summaryStepCompleted(
    properties: SummaryStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SummaryStepCompleted(properties), options);
  }

  /**
   * Survey story answered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Survey%20story%20answered)
   *
   * Clic sur un des emoji permettant d'envoyer la réponse au survey.
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  surveyStoryAnswered(
    properties: SurveyStoryAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new SurveyStoryAnswered(properties), options);
  }

  /**
   * TE sent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/TE%20sent)
   *
   * The user has sent a message to Evaneos in the discussion with Evaneos right after the creation of his request.
   *
   * @param properties The event's properties (e.g. agency_id)
   * @param options Amplitude event options.
   */
  teSent(
    properties: TeSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new TeSent(properties), options);
  }

  /**
   * Testimonial clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Testimonial%20clicked)
   *
   *
   *
   *
   * Sur la page facet :
   *
   *
   *
   *
   * Sur la nouvelle page facet; c'est pareil mais sur le bloc ***agencies*** + sur le bloc ***testimonials***
   *
   *
   *
   *
   * Sur la homepage :
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  testimonialClicked(
    properties: TestimonialClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TestimonialClicked(properties), options);
  }

  /**
   * Thematic clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Thematic%20clicked)
   *
   * This event tracks when a user clicks on a thematic page on the website. 
   *
   *
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  thematicClicked(
    properties: ThematicClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ThematicClicked(properties), options);
  }

  /**
   * Thematic filter clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Thematic%20filter%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. card_number)
   * @param options Amplitude event options.
   */
  thematicFilterClicked(
    properties: ThematicFilterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ThematicFilterClicked(properties), options);
  }

  /**
   * Tipi Recommend - Choice clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tipi%20Recommend%20-%20Choice%20clicked)
   *
   * Event indicating that a user clicked on a choice on what based to use from TiPi Recommendation
   *
   *
   *
   *
   *
   *
   *
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tipiRecommendChoiceClicked(
    properties: TipiRecommendChoiceClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TipiRecommendChoiceClicked(properties), options);
  }

  /**
   * Tipi Recommend - Choice submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tipi%20Recommend%20-%20Choice%20submitted)
   *
   * Event indicating that a user has submitted a TiPi choice and its been sent to agent.
   *
   *
   *
   *
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tipiRecommendChoiceSubmitted(
    properties: TipiRecommendChoiceSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TipiRecommendChoiceSubmitted(properties), options);
  }

  /**
   * Tipi Recommend - Comment popin closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tipi%20Recommend%20-%20Comment%20popin%20closed)
   *
   * Event to track when a user closes the comment popin after choosing a TiPi AI recommendation.
   *
   *
   *
   *
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tipiRecommendCommentPopinClosed(
    properties: TipiRecommendCommentPopinClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TipiRecommendCommentPopinClosed(properties), options);
  }

  /**
   * Tipi Recommend - Day clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tipi%20Recommend%20-%20Day%20clicked)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tipiRecommendDayClicked(
    properties: TipiRecommendDayClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TipiRecommendDayClicked(properties), options);
  }

  /**
   * Tipi Recommend - Skip clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tipi%20Recommend%20-%20Skip%20clicked)
   *
   * Event indicating that a user skipped the page of the Tipi Platform AI Recommend.
   *
   *
   *
   *
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tipiRecommendSkipClicked(
    properties: TipiRecommendSkipClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TipiRecommendSkipClicked(properties), options);
  }

  /**
   * Tipi Recommend - Tab clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tipi%20Recommend%20-%20Tab%20clicked)
   *
   * Event to track when a user clicks on a tab to select wich recommended TiPi to view.
   *
   *
   *
   *
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tipiRecommendTabClicked(
    properties: TipiRecommendTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TipiRecommendTabClicked(properties), options);
  }

  /**
   * Toaster activity added clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Toaster%20activity%20added%20clicked)
   *
   * The user clicked on the toaster "Activity added".
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  toasterActivityAddedClicked(
    properties: ToasterActivityAddedClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToasterActivityAddedClicked(properties), options);
  }

  /**
   * Transport search clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Transport%20search%20clicked)
   *
   * **click sur le CTA Rechercher un train/avion**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  transportSearchClicked(
    properties: TransportSearchClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransportSearchClicked(properties), options);
  }

  /**
   * Travel date modification clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Travel%20date%20modification%20clicked)
   *
   * A l'étape 3 du TF, suite au clic sur le CTA "j'ai un budget plus restreint", alors une popin s'affiche.  
   *  Sur cette popin, cet event ce déclenche lorsqu'un user clique sur le CTA "je modifie mes dates"
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  travelDateModificationClicked(
    properties: TravelDateModificationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TravelDateModificationClicked(properties), options);
  }

  /**
   * Travel guide article clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Travel%20guide%20article%20clicked)
   *
   * EVENT PLUS UTILISE : on a décidé de dégrouper cet event via des event `Suitcase article clicked` et `Vocabulary article clicked` 
   *  This event tracks when a user clicks on a travel guide article on the website
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  travelGuideArticleClicked(
    properties: TravelGuideArticleClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TravelGuideArticleClicked(properties), options);
  }

  /**
   * Travel guide clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Travel%20guide%20clicked)
   *
   * sur l'ancien design de la page desti :
   *
   *
   *
   *
   * sur la page poi :
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  travelGuideClicked(
    properties: TravelGuideClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TravelGuideClicked(properties), options);
  }

  /**
   * Travelers testimonial article clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Travelers%20testimonial%20article%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  travelersTestimonialArticleClicked(
    properties: TravelersTestimonialArticleClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TravelersTestimonialArticleClicked(properties), options);
  }

  /**
   * Trip adjustment question answered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Trip%20adjustment%20question%20answered)
   *
   * The user answered the trip adjustement question after syaing he wanted to keep his budget.
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripAdjustmentQuestionAnswered(
    properties: TripAdjustmentQuestionAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripAdjustmentQuestionAnswered(properties), options);
  }

  /**
   * Trip adjustment question displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Trip%20adjustment%20question%20displayed)
   *
   * We display to the user the trip adjsutement question if his budget is too low in the discussion with evaneos after the creation of his request.
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripAdjustmentQuestionDisplayed(
    properties: TripAdjustmentQuestionDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripAdjustmentQuestionDisplayed(properties), options);
  }

  /**
   * Trip notation clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Trip%20notation%20clicked)
   *
   * Clic sur les étoiles permettant de noter son dernier voyage
   *
   *
   *
   *
   * Owner: Tim HOFMANN
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripNotationClicked(
    properties: TripNotationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripNotationClicked(properties), options);
  }

  /**
   * Trip preparation clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Trip%20preparation%20clicked)
   *
   * Clic sur le CTA présent dans la cover du travel guide (bleu) pour découvrir la destination
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripPreparationClicked(
    properties: TripPreparationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripPreparationClicked(properties), options);
  }

  /**
   * Trip project in progress my trip clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Trip%20project%20in%20progress%20my%20trip%20clicked)
   *
   * Event indicating when the user clicks "Continuer mon voyage ..." in the popin warning a trip project is already in progress 
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripProjectInProgressMyTripClicked(
    properties: TripProjectInProgressMyTripClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripProjectInProgressMyTripClicked(properties), options);
  }

  /**
   * Trip project in progress popin displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Trip%20project%20in%20progress%20popin%20displayed)
   *
   * Event indicating when the popin warning a trip project is already in progress is displayed
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripProjectInProgressPopinDisplayed(
    properties: TripProjectInProgressPopinDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripProjectInProgressPopinDisplayed(properties), options);
  }

  /**
   * Trip project in progress restart clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Trip%20project%20in%20progress%20restart%20clicked)
   *
   * Event indicating when the user clicks "Repartir de zero" in the popin warning a trip project is already in progress
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripProjectInProgressRestartClicked(
    properties: TripProjectInProgressRestartClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripProjectInProgressRestartClicked(properties), options);
  }

  /**
   * Trip review clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Trip%20review%20clicked)
   *
   * Clic sur un témoignage pour voir le retour complet du user 
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripReviewClicked(
    properties: TripReviewClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripReviewClicked(properties), options);
  }

  /**
   * Trip reviews sort clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Trip%20reviews%20sort%20clicked)
   *
   * Click sur les différents type de Tri
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripReviewsSortClicked(
    properties: TripReviewsSortClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripReviewsSortClicked(properties), options);
  }

  /**
   * Tripform - activities & hosting step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20activities%20%26%20hosting%20step%20completed)
   *
   * Completion de l'étape sur les envies voyageurs et hébergements dans le tripform flow des hesitatings (exp. maturity novembre 2024)
   *
   *
   * @param properties The event's properties (e.g. activities_displayed_first)
   * @param options Amplitude event options.
   */
  tripformActivitiesHostingStepCompleted(
    properties: TripformActivitiesHostingStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformActivitiesHostingStepCompleted(properties), options);
  }

  /**
   * Tripform - activities & hosting step viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20activities%20%26%20hosting%20step%20viewed)
   *
   * Vue du one pager envies et hebergement dans l'experiment sur les hesitating dans le tripform (Maturity - Novembre 2024).
   *
   *
   * @param properties The event's properties (e.g. activities_displayed_first)
   * @param options Amplitude event options.
   */
  tripformActivitiesHostingStepViewed(
    properties: TripformActivitiesHostingStepViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformActivitiesHostingStepViewed(properties), options);
  }

  /**
   * Tripform - activities step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20activities%20step%20completed)
   *
   * Completion de l'étape ou l'on montre des activités /envies aux users hesitating dans le tripform (experiment maturity).
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformActivitiesStepCompleted(
    properties: TripformActivitiesStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformActivitiesStepCompleted(properties), options);
  }

  /**
   * Tripform - budget step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20budget%20step%20completed)
   *
   * This event is triggered when the user clicks on the CTA on the budget step. Il y a eu un experiment sur DACH:
   *
   *
   *
   *
   * Il y a également un experiment Maturity avec une étape budget dédiée:
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. budget_max_user)
   * @param options Amplitude event options.
   */
  tripformBudgetStepCompleted(
    properties: TripformBudgetStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformBudgetStepCompleted(properties), options);
  }

  /**
   * Tripform - creation started from agencies list
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20agencies%20list)
   *
   * A partir de la migration de la liste agence (décembre 2024), ce CTA est déclenché depuis la page liste agence
   *
   *
   *
   *
   * @param properties The event's properties (e.g. agency_better_trip_index)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromAgenciesList(
    properties: TripformCreationStartedFromAgenciesListProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromAgenciesList(properties), options);
  }

  /**
   * Tripform - creation started from agency
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20agency)
   *
   * Clic sur le CTA menant à la création d'un TF depuis une page agence
   *
   *
   *
   *
   * A partir de la migration de la liste agence (décembre 2024), ce CTA n'est plus déclenché depuis la page agence mais créé du from agencies list  :
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. agency_better_trip_index)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromAgency(
    properties: TripformCreationStartedFromAgencyProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromAgency(properties), options);
  }

  /**
   * Tripform - creation started from destination
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20destination)
   *
   * Clic sur le CTA permettant l'envoi d'un TF depuis une page desti : navbar ou bloc intention.
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromDestination(
    properties: TripformCreationStartedFromDestinationProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromDestination(properties), options);
  }

  /**
   * Tripform - creation started from destination article
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20destination%20article)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. article_type)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromDestinationArticle(
    properties: TripformCreationStartedFromDestinationArticleProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromDestinationArticle(properties), options);
  }

  /**
   * Tripform - creation started from destination articles list
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20destination%20articles%20list)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromDestinationArticlesList(
    properties: TripformCreationStartedFromDestinationArticlesListProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromDestinationArticlesList(properties), options);
  }

  /**
   * Tripform - creation started from destination suitcase
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20destination%20suitcase)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromDestinationSuitcase(
    properties: TripformCreationStartedFromDestinationSuitcaseProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromDestinationSuitcase(properties), options);
  }

  /**
   * Tripform - creation started from destination visa
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20destination%20visa)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromDestinationVisa(
    properties: TripformCreationStartedFromDestinationVisaProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromDestinationVisa(properties), options);
  }

  /**
   * Tripform - creation started from destination vocabulary
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20destination%20vocabulary)
   *
   * Clic sur le CTA menant au TF depuis une page vocab
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromDestinationVocabulary(
    properties: TripformCreationStartedFromDestinationVocabularyProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromDestinationVocabulary(properties), options);
  }

  /**
   * Tripform - creation started from destination whentogo
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20destination%20whentogo)
   *
   * Clic sur le CTA permettant la création d'un TF depuis une page whentogo Destination
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromDestinationWhentogo(
    properties: TripformCreationStartedFromDestinationWhentogoProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromDestinationWhentogo(properties), options);
  }

  /**
   * Tripform - creation started from explore
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20explore)
   *
   * Event indicating the initiation of creating a trip form from the Evaneos Explore Activities. 
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromExplore(
    properties: TripformCreationStartedFromExploreProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromExplore(properties), options);
  }

  /**
   * Tripform - creation started from facet
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20facet)
   *
   * Clic sur un CTA d'accès au TF depuis une page facet (block cartouche)
   *
   *
   *
   *
   * et block experience banner
   *
   *
   *
   *
   * \___\_ pour le MVP facet : clic sur le CRA présent dans le block concept
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromFacet(
    properties: TripformCreationStartedFromFacetProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromFacet(properties), options);
  }

  /**
   * Tripform - creation started from iti
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20iti)
   *
   * Clic sur le CTA permettant d'afficher le début du funnel de création d'un TF depuis une page Iti
   *
   *
   *
   *
   * ••••••••
   *
   *
   *
   *
   * ••••••••
   *
   *
   *
   *
   * ••••••••
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. agency_better_trip_index)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromIti(
    properties: TripformCreationStartedFromItiProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromIti(properties), options);
  }

  /**
   * Tripform - creation started from list iti
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20list%20iti)
   *
   * Clic sur le CTA permettant d'afficher le début du funnel de création d'un TF depuis une page list itineraries
   *
   *
   *
   *
   *
   * Owner: Elodie Cojandé
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromListIti(
    properties: TripformCreationStartedFromListItiProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromListIti(properties), options);
  }

  /**
   * Tripform - creation started from poi
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20poi)
   *
   * Clic sur le CTA amenant au début du Tripform depuis une page POI 
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromPoi(
    properties: TripformCreationStartedFromPoiProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromPoi(properties), options);
  }

  /**
   * Tripform - creation started from poi list
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20poi%20list)
   *
   * Clic sur le CTA amenant au début du Tripform depuis une page POI 
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromPoiList(
    properties: TripformCreationStartedFromPoiListProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromPoiList(properties), options);
  }

  /**
   * Tripform - creation started from region
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20region)
   *
   * Clic sur le CTA permettant la création d'un TF depuis une page Region
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromRegion(
    properties: TripformCreationStartedFromRegionProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromRegion(properties), options);
  }

  /**
   * Tripform - creation started from region whentogo
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20region%20whentogo)
   *
   * Clic sur le CTA permettant la création d'un TF depuis une page whentogo Region
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromRegionWhentogo(
    properties: TripformCreationStartedFromRegionWhentogoProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromRegionWhentogo(properties), options);
  }

  /**
   * Tripform - creation started from travel guide
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20creation%20started%20from%20travel%20guide)
   *
   * clic sur le CTA permettant d'entrer dans le TF depuis la page guide de voyage
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  tripformCreationStartedFromTravelGuide(
    properties: TripformCreationStartedFromTravelGuideProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformCreationStartedFromTravelGuide(properties), options);
  }

  /**
   * Tripform - dates step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20dates%20step%20completed)
   *
   * Completion de l'étape dates au sein du Tripform:
   *
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformDatesStepCompleted(
    properties: TripformDatesStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformDatesStepCompleted(properties), options);
  }

  /**
   * Tripform - destination infos step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20destination%20infos%20step%20completed)
   *
   * Completion of the desti info step in the hesitating flow of the tripform (experiment by maturity in Oct. 24) - click on one of the two CTAs:
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  tripformDestinationInfosStepCompleted(
    properties: TripformDestinationInfosStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformDestinationInfosStepCompleted(properties), options);
  }

  /**
   * Tripform - exit button clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20exit%20button%20clicked)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformExitButtonClicked(
    properties: TripformExitButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformExitButtonClicked(properties), options);
  }

  /**
   * Tripform - exit popin continued
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20exit%20popin%20continued)
   *
   * Event to track users continuing the tripform after interacting with the exit pop-up on the info step
   *
   * Owner: Nicolas Léonard
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformExitPopinContinued(
    properties: TripformExitPopinContinuedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformExitPopinContinued(properties), options);
  }

  /**
   * Tripform - exit popin displayed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20exit%20popin%20displayed)
   *
   * Event indicating that the exit popin on the information step for the Tripform was displayed to the user
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformExitPopinDisplayed(
    properties: TripformExitPopinDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformExitPopinDisplayed(properties), options);
  }

  /**
   * Tripform - exit popin left
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20exit%20popin%20left)
   *
   * Event indicating that a user has exited the Tripform from the pop-in
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformExitPopinLeft(
    properties: TripformExitPopinLeftProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformExitPopinLeft(properties), options);
  }

  /**
   * Tripform - hesitating qualification step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20hesitating%20qualification%20step%20completed)
   *
   * 2nd step of the tripform for hesitating users (click on one of the CTAs)
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformHesitatingQualificationStepCompleted(
    properties: TripformHesitatingQualificationStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformHesitatingQualificationStepCompleted(properties), options);
  }

  /**
   * Tripform - Hesitating trip step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20Hesitating%20trip%20step%20completed)
   *
   * Completion of the trip step in the new flow for hesitating in the Tripform (1st use in an experiment in Oct. 24):
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. budget_user)
   * @param options Amplitude event options.
   */
  tripformHesitatingTripStepCompleted(
    properties: TripformHesitatingTripStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformHesitatingTripStepCompleted(properties), options);
  }

  /**
   * Tripform - hosting step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20hosting%20step%20completed)
   *
   * Completion de l'étape hébergement dans le tripform.
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformHostingStepCompleted(
    properties: TripformHostingStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformHostingStepCompleted(properties), options);
  }

  /**
   * Tripform - Inspirational itinerary clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20Inspirational%20itinerary%20clicked)
   *
   * Click on the CTA "s'inspirer de ce circuit' in the Experiment new flow hesitating in the Tripform:
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. card_number)
   * @param options Amplitude event options.
   */
  tripformInspirationalItineraryClicked(
    properties: TripformInspirationalItineraryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformInspirationalItineraryClicked(properties), options);
  }

  /**
   * Tripform - mail connection
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20mail%20connection)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. already_existing)
   * @param options Amplitude event options.
   */
  tripformMailConnection(
    properties: TripformMailConnectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformMailConnection(properties), options);
  }

  /**
   * Tripform - Maturity check step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20Maturity%20check%20step%20completed)
   *
   * Completion of the maturity check step ion the new hesitating flow in the Tripform (1st use in the Experiment in Oct. 24): 
   *
   *
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformMaturityCheckStepCompleted(
    properties: TripformMaturityCheckStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformMaturityCheckStepCompleted(properties), options);
  }

  /**
   * Tripform - maturity step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20maturity%20step%20completed)
   *
   * Event déclenché quand le CTA de l'étape de maturité est cliqué (première étape du TF)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformMaturityStepCompleted(
    properties: TripformMaturityStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformMaturityStepCompleted(properties), options);
  }

  /**
   * Tripform - missing informations step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20missing%20informations%20step%20completed)
   *
   * L'utilisateur soumet l'étape lui demandant de préciser les informations manquantes nécessaires à l'envoie de sa request.
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. already_existing)
   * @param options Amplitude event options.
   */
  tripformMissingInformationsStepCompleted(
    properties: TripformMissingInformationsStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformMissingInformationsStepCompleted(properties), options);
  }

  /**
   * Tripform - missing informations viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20missing%20informations%20viewed)
   *
   * L'utilisateur voit l'étape demandant de préciser les informations manquantes nécessaires à l'envoie de sa request.
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. already_existing)
   * @param options Amplitude event options.
   */
  tripformMissingInformationsViewed(
    properties: TripformMissingInformationsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformMissingInformationsViewed(properties), options);
  }

  /**
   * Tripform - Personalization step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20Personalization%20step%20completed)
   *
   * Etape dans le TF ajoutée pour un test DACH : split de l'étape de création de compte en deux.
   *
   * Une étape en début de funnel qui demande nom/prénom/genre (= cet event)
   *
   * Une étape en fin de funnel pour ajouter le mail
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformPersonalizationStepCompleted(
    properties: TripformPersonalizationStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformPersonalizationStepCompleted(properties), options);
  }

  /**
   * Tripform - previous step
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20previous%20step)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformPreviousStep(
    properties: TripformPreviousStepProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformPreviousStep(properties), options);
  }

  /**
   * Tripform - profile step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20profile%20step%20completed)
   *
   * Event déclenché quand le CTA de l'étape de profile est cliqué (seconde étape du TF)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformProfileStepCompleted(
    properties: TripformProfileStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformProfileStepCompleted(properties), options);
  }

  /**
   * Tripform - project description clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20project%20description%20clicked)
   *
   * Event indicating that a user has clicked on a project description (textarea) within the Tripform
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformProjectDescriptionClicked(
    properties: TripformProjectDescriptionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformProjectDescriptionClicked(properties), options);
  }

  /**
   * Tripform - ready to sent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20ready%20to%20sent)
   *
   * Correspond au moment où le tripform est considéré comme prêt à être envoyé. Cet event intervient juste avant l'envoi du tripform.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformReadyToSent(
    properties: TripformReadyToSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformReadyToSent(properties), options);
  }

  /**
   * Tripform - rhythm step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20rhythm%20step%20completed)
   *
   * Completion de l'étape rythme de voyage pour les hesitatings dans le tripform (experiment maturity).
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformRhythmStepCompleted(
    properties: TripformRhythmStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformRhythmStepCompleted(properties), options);
  }

  /**
   * Tripform - skip step clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20skip%20step%20clicked)
   *
   * Skiping of a step in the Tripform. 1st use in the Experiment hesitating to skip the offer display step:
   *
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. button_type)
   * @param options Amplitude event options.
   */
  tripformSkipStepClicked(
    properties: TripformSkipStepClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformSkipStepClicked(properties), options);
  }

  /**
   * Tripform - social login add phone number
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20social%20login%20add%20phone%20number)
   *
   * Event déclenché à l'affichage de cette page demandant au user d'ajouter son numéro de téléphone s'il n'est pas déjà renseigné.
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. already_existing)
   * @param options Amplitude event options.
   */
  tripformSocialLoginAddPhoneNumber(
    properties: TripformSocialLoginAddPhoneNumberProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformSocialLoginAddPhoneNumber(properties), options);
  }

  /**
   * Tripform - social login connection
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20social%20login%20connection)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. already_existing)
   * @param options Amplitude event options.
   */
  tripformSocialLoginConnection(
    properties: TripformSocialLoginConnectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformSocialLoginConnection(properties), options);
  }

  /**
   * Tripform - start from zero clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20start%20from%20zero%20clicked)
   *
   * Click pour partir de zero dans l'experiment new flow hesitating (maturity - oct. 24) au sein du tripform
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformStartFromZeroClicked(
    properties: TripformStartFromZeroClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformStartFromZeroClicked(properties), options);
  }

  /**
   * Tripform - trip step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20trip%20step%20completed)
   *
   * Event déclenché après le clic sur le CTA (troisème étape du TF)
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripformTripStepCompleted(
    properties: TripformTripStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformTripStepCompleted(properties), options);
  }

  /**
   * Tripform - tripform sent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Tripform%20-%20tripform%20sent)
   *
   * Event déclenché lors de l'envoi d'un TF : clic sur le CTA ou envoi directement si le user se connecte en SL.
   *
   *
   *
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. already_existing)
   * @param options Amplitude event options.
   */
  tripformTripformSent(
    properties: TripformTripformSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripformTripformSent(properties), options);
  }

  /**
   * Trips - Destination recommendation clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Trips%20-%20Destination%20recommendation%20clicked)
   *
   * This event tracks when a user clicks on a recommended destination while on Esper.
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripsDestinationRecommendationClicked(
    properties: TripsDestinationRecommendationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripsDestinationRecommendationClicked(properties), options);
  }

  /**
   * Trips - Recommendation clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Trips%20-%20Recommendation%20clicked)
   *
   *
   *
   *
   * This event tracks when a user clicks on the destination recommendation cta on the Trip section of Esper
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripsRecommendationClicked(
    properties: TripsRecommendationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripsRecommendationClicked(properties), options);
  }

  /**
   * Trips - Trip clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Trips%20-%20Trip%20clicked)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  tripsTripClicked(
    properties: TripsTripClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TripsTripClicked(properties), options);
  }

  /**
   * Useful button clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Useful%20button%20clicked)
   *
   * Event indicating that a user has clicked on a button this content is useful.
   *
   * Related event: Non-useful button clicked
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  usefulButtonClicked(
    properties: UsefulButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UsefulButtonClicked(properties), options);
  }

  /**
   * Video played
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Video%20played)
   *
   * Clic pour lancer une video
   *
   * Exemple de la homepage :
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  videoPlayed(
    properties: VideoPlayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VideoPlayed(properties), options);
  }

  /**
   * View my trip clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/View%20my%20trip%20clicked)
   *
   * Event to track when a user clicks on "View my trip" button to access My Trip Project, on Evaneos Explore Activity. 
   *
   *
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  viewMyTripClicked(
    properties: ViewMyTripClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewMyTripClicked(properties), options);
  }

  /**
   * Viewed page -hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20-hub)
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageHub(
    properties?: ViewedPageHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageHub(properties), options);
  }

  /**
   * Viewed page account
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccount(
    properties: ViewedPageAccountProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccount(properties), options);
  }

  /**
   * Viewed page account-personal-informations
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-personal-informations)
   *
   *
   *
   *
   * This event tracks when a user views the personal information page within their account
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountPersonalInformations(
    properties: ViewedPageAccountPersonalInformationsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountPersonalInformations(properties), options);
  }

  /**
   * Viewed page account-personal-informations-notifications
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-personal-informations-notifications)
   *
   *
   *
   *
   * This event tracks when a user views the "Account Personal Informations Notifications" page on the website
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountPersonalInformationsNotifications(
    properties: ViewedPageAccountPersonalInformationsNotificationsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountPersonalInformationsNotifications(properties), options);
  }

  /**
   * Viewed page account-personal-informations-password
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-personal-informations-password)
   *
   *
   *
   *
   * This event tracks when a user views the "Account Personal Informations Password" page
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountPersonalInformationsPassword(
    properties: ViewedPageAccountPersonalInformationsPasswordProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountPersonalInformationsPassword(properties), options);
  }

  /**
   * Viewed page account-poi-opinions
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-poi-opinions)
   *
   *
   *
   *
   * This event tracks when a user views the "Account POI Opinions" page on the website
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountPoiOpinions(
    properties: ViewedPageAccountPoiOpinionsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountPoiOpinions(properties), options);
  }

  /**
   * Viewed page account-poi-opinions-add
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-poi-opinions-add)
   *
   *
   *
   *
   * This event tracks when a user clicks on the "Add Opinions"  within their account on the Points of Interest (POI) page.
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountPoiOpinionsAdd(
    properties: ViewedPageAccountPoiOpinionsAddProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountPoiOpinionsAdd(properties), options);
  }

  /**
   * Viewed page account-recommendations
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-recommendations)
   *
   * Vu de la page de recommandation personalisée
   *
   *
   *
   *
   * Owner: clarisse.bolignano@external.evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountRecommendations(
    properties: ViewedPageAccountRecommendationsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountRecommendations(properties), options);
  }

  /**
   * Viewed page account-trip-about-the-agency
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trip-about-the-agency)
   *
   *
   *
   *
   * This event tracks when a user views the "About the Agency" page within their account trip
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripAboutTheAgency(
    properties: ViewedPageAccountTripAboutTheAgencyProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripAboutTheAgency(properties), options);
  }

  /**
   * Viewed page account-trip-join
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trip-join)
   *
   *
   *
   *
   * This event tracks when a user views the "Join Trip" page in their account.
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripJoin(
    properties: ViewedPageAccountTripJoinProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripJoin(properties), options);
  }

  /**
   * Viewed page account-trip-join-expired
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trip-join-expired)
   *
   * This event tracks when a user views the expired trip join page for their account
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripJoinExpired(
    properties: ViewedPageAccountTripJoinExpiredProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripJoinExpired(properties), options);
  }

  /**
   * Viewed page account-trip-messages
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trip-messages)
   *
   *
   *
   *
   * This event tracks when a user views the account trip messages page on the esper.
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripMessages(
    properties: ViewedPageAccountTripMessagesProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripMessages(properties), options);
  }

  /**
   * Viewed page account-trip-need-help
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trip-need-help)
   *
   * This event tracks when a user views the "Need help" page within their account trip
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripNeedHelp(
    properties: ViewedPageAccountTripNeedHelpProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripNeedHelp(properties), options);
  }

  /**
   * Viewed page account-trip-partners
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trip-partners)
   *
   * This event tracks when a user views the "Partners" page on esper.
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripPartners(
    properties: ViewedPageAccountTripPartnersProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripPartners(properties), options);
  }

  /**
   * Viewed page account-trip-payments
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trip-payments)
   *
   *
   *
   *
   * This event tracks when a user views the "Payments" section of their account trip page
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripPayments(
    properties: ViewedPageAccountTripPaymentsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripPayments(properties), options);
  }

  /**
   * Viewed page account-trip-shared-documents
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trip-shared-documents)
   *
   *
   *
   *
   * This event tracks when a user views the shared documents page within their account trip
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripSharedDocuments(
    properties: ViewedPageAccountTripSharedDocumentsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripSharedDocuments(properties), options);
  }

  /**
   * Viewed page account-trip-transports
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trip-transports)
   *
   * Event indicating that a user has viewed the transports tab on the Esper application
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripTransports(
    properties: ViewedPageAccountTripTransportsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripTransports(properties), options);
  }

  /**
   * Viewed page account-trip-travellers
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trip-travellers)
   *
   *
   *
   *
   * This event tracks when a user views the "Travellers" section of their account trip page
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripTravellers(
    properties: ViewedPageAccountTripTravellersProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripTravellers(properties), options);
  }

  /**
   * Viewed page account-trip-validate
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trip-validate)
   *
   * This event tracks when a user views the account trip validation page
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripValidate(
    properties: ViewedPageAccountTripValidateProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripValidate(properties), options);
  }

  /**
   * Viewed page account-trip-validate-confirmation
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trip-validate-confirmation)
   *
   * This event tracks when a user views the account trip validation confirmation page in the Esper section of the website
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripValidateConfirmation(
    properties: ViewedPageAccountTripValidateConfirmationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripValidateConfirmation(properties), options);
  }

  /**
   * Viewed page account-trips
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trips)
   *
   *
   *
   *
   *
   * View page Esper Trips![](https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F4c467f28-c204-4dd2-96d8-8250d133d279%2FCapture_decran_2023-08-09_a_15.49.20.png?id=438cb269-5709-437a-bae5-eec52bc21445&table=block&spaceId=d83f3cad-de62-4cd8-be63-e85c5f35bc56&width=600&userId=bf9cb138-ef28-4ba4-86b9-8a9d58a9917d&cache=v2)![](https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F4c467f28-c204-4dd2-96d8-8250d133d279%2FCapture_decran_2023-08-09_a_15.49.20.png?id=438cb269-5709-437a-bae5-eec52bc21445&table=block&spaceId=d83f3cad-de62-4cd8-be63-e85c5f35bc56&width=600&userId=bf9cb138-ef28-4ba4-86b9-8a9d58a9917d&cache=v2)
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTrips(
    properties: ViewedPageAccountTripsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTrips(properties), options);
  }

  /**
   * Viewed page account-trips-recommendations
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-trips-recommendations)
   *
   *
   * This event tracks when a user views the "trips-recommendations" page on esper.
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountTripsRecommendations(
    properties: ViewedPageAccountTripsRecommendationsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountTripsRecommendations(properties), options);
  }

  /**
   * Viewed page account-wishlist
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-wishlist)
   *
   *
   *
   *
   * This event tracks when a user views their wishlist page on the account section of the website
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountWishlist(
    properties: ViewedPageAccountWishlistProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountWishlist(properties), options);
  }

  /**
   * Viewed page account-wishlist-destination
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20account-wishlist-destination)
   *
   *
   *
   *
   * This event tracks when a user views the wishlist page for a specific destination in their account
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAccountWishlistDestination(
    properties: ViewedPageAccountWishlistDestinationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAccountWishlistDestination(properties), options);
  }

  /**
   * Viewed page activities
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20activities)
   *
   * Event tracking for user viewing the Evaneos Explore Activities List Page
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageActivities(
    properties: ViewedPageActivitiesProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageActivities(properties), options);
  }

  /**
   * Viewed page activities-my-trip
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20activities-my-trip)
   *
   * Event to track when a user views the "My Trip" activities page. Part of MVPOC Maturity Evaneos Explore
   *
   *
   *
   *
   *
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. activities_count)
   * @param options Amplitude event options.
   */
  viewedPageActivitiesMyTrip(
    properties: ViewedPageActivitiesMyTripProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageActivitiesMyTrip(properties), options);
  }

  /**
   * Viewed page activity-details
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20activity-details)
   *
   * Event capturing when a user views the details of an activity on Evaneos Explore
   *
   * Owner: Anthony Vidal
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageActivityDetails(
    properties: ViewedPageActivityDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageActivityDetails(properties), options);
  }

  /**
   * Viewed page agencies list
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20agencies%20list)
   *
   * Liste des agences : anciennement coco.agencies
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAgenciesList(
    properties: ViewedPageAgenciesListProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAgenciesList(properties), options);
  }

  /**
   * Viewed page article
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20article)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageArticle(
    properties: ViewedPageArticleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageArticle(properties), options);
  }

  /**
   * Viewed page autumn-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20autumn-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageAutumnHub(
    properties: ViewedPageAutumnHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageAutumnHub(properties), options);
  }

  /**
   * Viewed page beginForm
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20beginForm)
   *
   * User started the tripform and viewed 1st step
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageBeginForm(
    properties: ViewedPageBeginFormProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageBeginForm(properties), options);
  }

  /**
   * Viewed page best-sellers-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20best-sellers-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageBestSellersHub(
    properties: ViewedPageBestSellersHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageBestSellersHub(properties), options);
  }

  /**
   * Viewed page Better Traveler Pledge
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20Better%20Traveler%20Pledge)
   *
   * à déclencher à chaque vue d'une étape du BTP
   *
   *
   *
   *
   * Owner: Kris Amazan
   *
   * @param properties The event's properties (e.g. btp_step_name)
   * @param options Amplitude event options.
   */
  viewedPageBetterTravelerPledge(
    properties: ViewedPageBetterTravelerPledgeProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageBetterTravelerPledge(properties), options);
  }

  /**
   * Viewed page Better Traveler Pledge - Confirmation
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20Better%20Traveler%20Pledge%20-%20Confirmation)
   *
   * à déclencher à l’affichage de la page de confirmation de signature du Pledge
   *
   *
   *
   *
   * Owner: Kris Amazan
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageBetterTravelerPledgeConfirmation(
    properties: ViewedPageBetterTravelerPledgeConfirmationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageBetterTravelerPledgeConfirmation(properties), options);
  }

  /**
   * Viewed page Better Traveler Pledge - Intro
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20Better%20Traveler%20Pledge%20-%20Intro)
   *
   * Vue de la page d'introduction au better traveler pledge
   *
   *
   *
   *
   * Owner: Kris Amazan
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageBetterTravelerPledgeIntro(
    properties: ViewedPageBetterTravelerPledgeIntroProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageBetterTravelerPledgeIntro(properties), options);
  }

  /**
   * Viewed page bike-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20bike-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageBikeHub(
    properties: ViewedPageBikeHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageBikeHub(properties), options);
  }

  /**
   * Viewed page city-break-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20city-break-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCityBreakHub(
    properties: ViewedPageCityBreakHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCityBreakHub(properties), options);
  }

  /**
   * Viewed page coco.agencies
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.agencies)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCocoAgencies(
    properties: ViewedPageCocoAgenciesProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoAgencies(properties), options);
  }

  /**
   * Viewed page coco.article.essential
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.article.essential)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCocoArticleEssential(
    properties: ViewedPageCocoArticleEssentialProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoArticleEssential(properties), options);
  }

  /**
   * Viewed page coco.article.inspiration
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.article.inspiration)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCocoArticleInspiration(
    properties: ViewedPageCocoArticleInspirationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoArticleInspiration(properties), options);
  }

  /**
   * Viewed page coco.article.practical
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.article.practical)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCocoArticlePractical(
    properties: ViewedPageCocoArticlePracticalProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoArticlePractical(properties), options);
  }

  /**
   * Viewed page coco.article.suitcase
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.article.suitcase)
   *
   * Exemple de page [https://www.evaneos.fr/costa-rica/voyage/preparer-sa-valise/]()
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCocoArticleSuitcase(
    properties: ViewedPageCocoArticleSuitcaseProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoArticleSuitcase(properties), options);
  }

  /**
   * Viewed page coco.article.visa
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.article.visa)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCocoArticleVisa(
    properties: ViewedPageCocoArticleVisaProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoArticleVisa(properties), options);
  }

  /**
   * Viewed page coco.article.vocabulary
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.article.vocabulary)
   *
   * Exemple de page : [https://www.evaneos.fr/costa-rica/voyage/vocabulaire-de-survie/]()
   *
   *
   *
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Recorded)
   * @param options Amplitude event options.
   */
  viewedPageCocoArticleVocabulary(
    properties: ViewedPageCocoArticleVocabularyProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoArticleVocabulary(properties), options);
  }

  /**
   * Viewed page coco.article.whentogo
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.article.whentogo)
   *
   * Exemple de page : [https://www.evaneos.fr/costa-rica/voyage/quand-partir/]()
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCocoArticleWhentogo(
    properties: ViewedPageCocoArticleWhentogoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoArticleWhentogo(properties), options);
  }

  /**
   * Viewed page coco.article.whytogo
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.article.whytogo)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCocoArticleWhytogo(
    properties: ViewedPageCocoArticleWhytogoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoArticleWhytogo(properties), options);
  }

  /**
   * Viewed page coco.home
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.home)
   *
   * = guide de voyage
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCocoHome(
    properties: ViewedPageCocoHomeProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoHome(properties), options);
  }

  /**
   * Viewed page coco.list.inspiration
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.list.inspiration)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCocoListInspiration(
    properties: ViewedPageCocoListInspirationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoListInspiration(properties), options);
  }

  /**
   * Viewed page coco.list.place
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.list.place)
   *
   * Page listant tous les POI
   *
   *
   *
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCocoListPlace(
    properties: ViewedPageCocoListPlaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoListPlace(properties), options);
  }

  /**
   * Viewed page coco.place
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20coco.place)
   *
   * Page POI
   *
   *
   *
   *
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCocoPlace(
    properties: ViewedPageCocoPlaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCocoPlace(properties), options);
  }

  /**
   * Viewed page completedForm
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20completedForm)
   *
   * TripForm has been submitted and sent to the local agency. No extra lead qualification
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCompletedForm(
    properties: ViewedPageCompletedFormProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCompletedForm(properties), options);
  }

  /**
   * Viewed page concept
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20concept)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageConcept(
    properties: ViewedPageConceptProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageConcept(properties), options);
  }

  /**
   * Viewed page contest
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20contest)
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageContest(
    properties?: ViewedPageContestProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageContest(properties), options);
  }

  /**
   * Viewed page context-continent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20context-continent)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageContextContinent(
    properties: ViewedPageContextContinentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageContextContinent(properties), options);
  }

  /**
   * Viewed page countries
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20countries)
   *
   * Liste de toutes les desti
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCountries(
    properties: ViewedPageCountriesProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCountries(properties), options);
  }

  /**
   * Viewed page country
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20country)
   *
   * User have seen "Destination" page
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCountry(
    properties: ViewedPageCountryProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCountry(properties), options);
  }

  /**
   * Viewed page country recommendation
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20country%20recommendation)
   *
   * Visualisation of the country page including recommendation
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageCountryRecommendation(
    properties: ViewedPageCountryRecommendationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageCountryRecommendation(properties), options);
  }

  /**
   * Viewed page destination article
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20destination%20article)
   *
   * Affichage d'une page article inspiration ou info pratique
   *
   * @param properties The event's properties (e.g. article_type)
   * @param options Amplitude event options.
   */
  viewedPageDestinationArticle(
    properties: ViewedPageDestinationArticleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageDestinationArticle(properties), options);
  }

  /**
   * Viewed page destination articles list
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20destination%20articles%20list)
   *
   * Affichage d'une page liste d'article (via, valise et vocabulaire ne sont pas pris en compte)
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageDestinationArticlesList(
    properties: ViewedPageDestinationArticlesListProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageDestinationArticlesList(properties), options);
  }

  /**
   * Viewed page destination details
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20destination%20details)
   *
   * Landing page destination details vue
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageDestinationDetails(
    properties: ViewedPageDestinationDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageDestinationDetails(properties), options);
  }

  /**
   * Viewed page destination suitcase
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20destination%20suitcase)
   *
   * Affichage d'une page article **Préparer sa valise**
   *
   * Anciennement le nom de la page était : "Viewed page coco.article.suitcase" dans Amplitude
   *
   * Exemple de page : [https://www.evaneos.fr/costa-rica/voyage/preparer-sa-valise/]()
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageDestinationSuitcase(
    properties: ViewedPageDestinationSuitcaseProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageDestinationSuitcase(properties), options);
  }

  /**
   * Viewed page destination visa
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20destination%20visa)
   *
   * Affichage d'une page article **Visa**
   *
   * Anciennement le nom de la page était : "Viewed page coco.article.visa" dans Amplitude
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageDestinationVisa(
    properties: ViewedPageDestinationVisaProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageDestinationVisa(properties), options);
  }

  /**
   * Viewed page destination vocabulary
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20destination%20vocabulary)
   *
   * Anciennement : `Viewed page coco.article.vocabulary`
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageDestinationVocabulary(
    properties: ViewedPageDestinationVocabularyProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageDestinationVocabulary(properties), options);
  }

  /**
   * Viewed page destination whentogo
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20destination%20whentogo)
   *
   * Exemple : [https://www.evaneos.fr/costa-rica/voyage/quand-partir/]()
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageDestinationWhentogo(
    properties: ViewedPageDestinationWhentogoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageDestinationWhentogo(properties), options);
  }

  /**
   * Viewed page destination-what-to-do
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20destination-what-to-do)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageDestinationWhatToDo(
    properties: ViewedPageDestinationWhatToDoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageDestinationWhatToDo(properties), options);
  }

  /**
   * Viewed page exceptional-journey-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20exceptional-journey-hub)
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageExceptionalJourneyHub(
    properties?: ViewedPageExceptionalJourneyHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageExceptionalJourneyHub(properties), options);
  }

  /**
   * Viewed page experience
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20experience)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageExperience(
    properties: ViewedPageExperienceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageExperience(properties), options);
  }

  /**
   * Viewed page facet
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20facet)
   *
   * Page ayant une thématique + desti
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageFacet(
    properties: ViewedPageFacetProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageFacet(properties), options);
  }

  /**
   * Viewed page family-experience
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20family-experience)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageFamilyExperience(
    properties: ViewedPageFamilyExperienceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageFamilyExperience(properties), options);
  }

  /**
   * Viewed page family-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20family-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageFamilyHub(
    properties: ViewedPageFamilyHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageFamilyHub(properties), options);
  }

  /**
   * Viewed page february-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20february-hub)
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageFebruaryHub(
    properties: ViewedPageFebruaryHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageFebruaryHub(properties), options);
  }

  /**
   * Viewed page flights
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20flights)
   *
   * Event indicating that a user has viewed the transports tab on the Esper application 
   *
   *
   *
   *
   * Owner: Vincent Lemire
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageFlights(
    properties: ViewedPageFlightsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageFlights(properties), options);
  }

  /**
   * Viewed page gastronomy-experience
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20gastronomy-experience)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageGastronomyExperience(
    properties: ViewedPageGastronomyExperienceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageGastronomyExperience(properties), options);
  }

  /**
   * Viewed page gastronomy-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20gastronomy-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageGastronomyHub(
    properties: ViewedPageGastronomyHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageGastronomyHub(properties), options);
  }

  /**
   * Viewed page hikes-trek-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20hikes-trek-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageHikesTrekHub(
    properties: ViewedPageHikesTrekHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageHikesTrekHub(properties), options);
  }

  /**
   * Viewed page honeymoon-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20honeymoon-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageHoneymoonHub(
    properties: ViewedPageHoneymoonHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageHoneymoonHub(properties), options);
  }

  /**
   * Viewed page index
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20index)
   *
   * Homepage
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageIndex(
    properties: ViewedPageIndexProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageIndex(properties), options);
  }

  /**
   * Viewed page itinerary
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20itinerary)
   *
   * This event tracks when a user views an itinerary page on the website
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Recorded)
   * @param options Amplitude event options.
   */
  viewedPageItinerary(
    properties: ViewedPageItineraryProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageItinerary(properties), options);
  }

  /**
   * Viewed page january-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20january-hub)
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageJanuaryHub(
    properties: ViewedPageJanuaryHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageJanuaryHub(properties), options);
  }

  /**
   * Viewed page join-a-group-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20join-a-group-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageJoinAGroupHub(
    properties: ViewedPageJoinAGroupHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageJoinAGroupHub(properties), options);
  }

  /**
   * Viewed page july-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20july-hub)
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageJulyHub(
    properties?: ViewedPageJulyHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageJulyHub(properties), options);
  }

  /**
   * Viewed page landing-events
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20landing-events)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageLandingEvents(
    properties: ViewedPageLandingEventsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageLandingEvents(properties), options);
  }

  /**
   * Viewed page landing-respire-warranty
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20landing-respire-warranty)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageLandingRespireWarranty(
    properties: ViewedPageLandingRespireWarrantyProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageLandingRespireWarranty(properties), options);
  }

  /**
   * Viewed page landing-top-destination-2020
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20landing-top-destination-2020)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageLandingTopDestination2020(
    properties: ViewedPageLandingTopDestination2020Properties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageLandingTopDestination2020(properties), options);
  }

  /**
   * Viewed page lead-nurturing
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20lead-nurturing)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageLeadNurturing(
    properties: ViewedPageLeadNurturingProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageLeadNurturing(properties), options);
  }

  /**
   * Viewed page list-itineraries
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20list-itineraries)
   *
   * This event tracks when a user views the List itineraries page on the website
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageListItineraries(
    properties: ViewedPageListItinerariesProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageListItineraries(properties), options);
  }

  /**
   * Viewed page list-itineraries recommendation
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20list-itineraries%20recommendation)
   *
   * Visualisation of the list itineraries via recommendation
   *
   *
   *
   *
   *
   * Owner: Anne-Camille Favret
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageListItinerariesRecommendation(
    properties: ViewedPageListItinerariesRecommendationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageListItinerariesRecommendation(properties), options);
  }

  /**
   * Viewed page local-agent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20local-agent)
   *
   * This event tracks when a user views the local agent page on the website
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. agency_better_trip_index)
   * @param options Amplitude event options.
   */
  viewedPageLocalAgent(
    properties: ViewedPageLocalAgentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageLocalAgent(properties), options);
  }

  /**
   * Viewed page lp-acquisition
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20lp-acquisition)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageLpAcquisition(
    properties: ViewedPageLpAcquisitionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageLpAcquisition(properties), options);
  }

  /**
   * Viewed page magazine
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20magazine)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageMagazine(
    properties: ViewedPageMagazineProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageMagazine(properties), options);
  }

  /**
   * Viewed page magazine-article
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20magazine-article)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageMagazineArticle(
    properties: ViewedPageMagazineArticleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageMagazineArticle(properties), options);
  }

  /**
   * Viewed page magazine-article-preview
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20magazine-article-preview)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageMagazineArticlePreview(
    properties: ViewedPageMagazineArticlePreviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageMagazineArticlePreview(properties), options);
  }

  /**
   * Viewed page nature-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20nature-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageNatureHub(
    properties: ViewedPageNatureHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageNatureHub(properties), options);
  }

  /**
   * Viewed page no-plane-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20no-plane-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageNoPlaneHub(
    properties: ViewedPageNoPlaneHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageNoPlaneHub(properties), options);
  }

  /**
   * Viewed page northern-light-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20northern-light-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageNorthernLightHub(
    properties: ViewedPageNorthernLightHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageNorthernLightHub(properties), options);
  }

  /**
   * Viewed page null
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20null)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageNull(
    properties: ViewedPageNullProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageNull(properties), options);
  }

  /**
   * Viewed page off-the-beaten-track-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20off-the-beaten-track-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageOffTheBeatenTrackHub(
    properties: ViewedPageOffTheBeatenTrackHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageOffTheBeatenTrackHub(properties), options);
  }

  /**
   * Viewed page one-week-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20one-week-hub)
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Replay ID)
   * @param options Amplitude event options.
   */
  viewedPageOneWeekHub(
    properties: ViewedPageOneWeekHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageOneWeekHub(properties), options);
  }

  /**
   * Viewed page paradise-beach-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20paradise-beach-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageParadiseBeachHub(
    properties: ViewedPageParadiseBeachHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageParadiseBeachHub(properties), options);
  }

  /**
   * Viewed page partnership
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20partnership)
   *
   * Landing page partnership vue 
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePartnership(
    properties: ViewedPagePartnershipProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePartnership(properties), options);
  }

  /**
   * Viewed page pathfinder-conversation
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20pathfinder-conversation)
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. pathfinder_destination_slug)
   * @param options Amplitude event options.
   */
  viewedPagePathfinderConversation(
    properties?: ViewedPagePathfinderConversationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePathfinderConversation(properties), options);
  }

  /**
   * Viewed page payment
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20payment)
   *
   * ```
   * ```
   *
   * This event tracks when a user views the payment page in the payment funnel
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePayment(
    properties: ViewedPagePaymentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePayment(properties), options);
  }

  /**
   * Viewed page payment-iban
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20payment-iban)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePaymentIban(
    properties: ViewedPagePaymentIbanProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePaymentIban(properties), options);
  }

  /**
   * Viewed page payment-insurance
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20payment-insurance)
   *
   * This event tracks when a user views the payment-insurance page in the payment funnel
   *
   *
   *
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePaymentInsurance(
    properties?: ViewedPagePaymentInsuranceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePaymentInsurance(properties), options);
  }

  /**
   * Viewed page payment-payer-info
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20payment-payer-info)
   *
   *
   * This event tracks when a user views the payment payer information page in the payment funnel
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePaymentPayerInfo(
    properties?: ViewedPagePaymentPayerInfoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePaymentPayerInfo(properties), options);
  }

  /**
   * Viewed page payment-result
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20payment-result)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePaymentResult(
    properties: ViewedPagePaymentResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePaymentResult(properties), options);
  }

  /**
   * Viewed page payment-result-failure
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20payment-result-failure)
   *
   *
   *
   *
   * This event tracks when a user views the payment result failure page in the payment funnel
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePaymentResultFailure(
    properties?: ViewedPagePaymentResultFailureProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePaymentResultFailure(properties), options);
  }

  /**
   * Viewed page payment-result-success
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20payment-result-success)
   *
   *
   * Event: Viewed page payment-result-success
   *
   * Description: This event tracks when a user views the payment result success page after completing a successful payment transaction. It provides insights into the number of users who successfully complete a payment and reach the payment result success page
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePaymentResultSuccess(
    properties?: ViewedPagePaymentResultSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePaymentResultSuccess(properties), options);
  }

  /**
   * Viewed page payment-schedule
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20payment-schedule)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePaymentSchedule(
    properties: ViewedPagePaymentScheduleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePaymentSchedule(properties), options);
  }

  /**
   * Viewed page payment-summary
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20payment-summary)
   *
   *
   *
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePaymentSummary(
    properties?: ViewedPagePaymentSummaryProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePaymentSummary(properties), options);
  }

  /**
   * Viewed page payment-travel-information
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20payment-travel-information)
   *
   *
   * This event tracks when a user views the payment travel information page in the payment funnel
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePaymentTravelInformation(
    properties?: ViewedPagePaymentTravelInformationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePaymentTravelInformation(properties), options);
  }

  /**
   * Viewed page payment-with-adyen
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20payment-with-adyen)
   *
   *
   * This event tracks when a user views the payment page with Adyen during the payment funnel
   *
   * Owner: alexandra.stackler@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePaymentWithAdyen(
    properties?: ViewedPagePaymentWithAdyenProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePaymentWithAdyen(properties), options);
  }

  /**
   * Viewed page POI list
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20POI%20list)
   *
   * Page liste POI
   *
   * anciennement : Viewed page coco.list.place
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePoiList(
    properties: ViewedPagePoiListProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePoiList(properties), options);
  }

  /**
   * Viewed page press
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20press)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePress(
    properties: ViewedPagePressProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePress(properties), options);
  }

  /**
   * Viewed page prismic-preview
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20prismic-preview)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPagePrismicPreview(
    properties: ViewedPagePrismicPreviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPagePrismicPreview(properties), options);
  }

  /**
   * Viewed page region
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20region)
   *
   * Vue d'une page region
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageRegion(
    properties: ViewedPageRegionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageRegion(properties), options);
  }

  /**
   * Viewed page region whentogo
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20region%20whentogo)
   *
   * Page similaire à mais dédiée à une région : [https://www.evaneos.fr/costa-rica/voyage/quand-partir/]()
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageRegionWhentogo(
    properties: ViewedPageRegionWhentogoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageRegionWhentogo(properties), options);
  }

  /**
   * Viewed page requalificationForm
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20requalificationForm)
   *
   * Il y a deux types de formulaires en fonction du type de filtrage : un avec peu de questions et un autre plus long. 
   *  Dans les deux cas, cette page s'affiche à la fin du TF : si le user le remplit, alors une request est envoyée. 
   *  Le user reçoit également un mail permettant d'ouvrir ce formulaire.
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageRequalificationForm(
    properties: ViewedPageRequalificationFormProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageRequalificationForm(properties), options);
  }

  /**
   * Viewed page requalificationForm-email
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20requalificationForm-email)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageRequalificationFormEmail(
    properties: ViewedPageRequalificationFormEmailProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageRequalificationFormEmail(properties), options);
  }

  /**
   * Viewed page safaris-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20safaris-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageSafarisHub(
    properties: ViewedPageSafarisHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageSafarisHub(properties), options);
  }

  /**
   * Viewed page selfdrive-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20selfdrive-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageSelfdriveHub(
    properties: ViewedPageSelfdriveHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageSelfdriveHub(properties), options);
  }

  /**
   * Viewed page september-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20september-hub)
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageSeptemberHub(
    properties?: ViewedPageSeptemberHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageSeptemberHub(properties), options);
  }

  /**
   * Viewed page sitemap
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20sitemap)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageSitemap(
    properties: ViewedPageSitemapProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageSitemap(properties), options);
  }

  /**
   * Viewed page sport-adventure-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20sport-adventure-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageSportAdventureHub(
    properties: ViewedPageSportAdventureHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageSportAdventureHub(properties), options);
  }

  /**
   * Viewed page summer-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20summer-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageSummerHub(
    properties: ViewedPageSummerHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageSummerHub(properties), options);
  }

  /**
   * Viewed page team
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20team)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageTeam(
    properties: ViewedPageTeamProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageTeam(properties), options);
  }

  /**
   * Viewed page thematic.landing
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20thematic.landing)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageThematicLanding(
    properties: ViewedPageThematicLandingProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageThematicLanding(properties), options);
  }

  /**
   * Viewed page tipi-recommendation
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20tipi-recommendation)
   *
   * Event indicating that a user has viewed the tipi-recommendation page on the website
   *
   *
   *
   *
   * Owner: Axel Jean-Théodore
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageTipiRecommendation(
    properties: ViewedPageTipiRecommendationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageTipiRecommendation(properties), options);
  }

  /**
   * Viewed page top destinations
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20top%20destinations)
   *
   * Landing Page top desti vue:
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageTopDestinations(
    properties: ViewedPageTopDestinationsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageTopDestinations(properties), options);
  }

  /**
   * Viewed page travel guide
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20travel%20guide)
   *
   * Ancienne page coco.home = guide de voyage
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageTravelGuide(
    properties: ViewedPageTravelGuideProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageTravelGuide(properties), options);
  }

  /**
   * Viewed page travel-explorer
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20travel-explorer)
   *
   * Owner: Florian Kroockmann
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageTravelExplorer(
    properties: ViewedPageTravelExplorerProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageTravelExplorer(properties), options);
  }

  /**
   * Viewed page trip-review
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20trip-review)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageTripReview(
    properties: ViewedPageTripReviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageTripReview(properties), options);
  }

  /**
   * Viewed page trip-review-destination
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20trip-review-destination)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageTripReviewDestination(
    properties: ViewedPageTripReviewDestinationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageTripReviewDestination(properties), options);
  }

  /**
   * Viewed page trip-review-list
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20trip-review-list)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageTripReviewList(
    properties: ViewedPageTripReviewListProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageTripReviewList(properties), options);
  }

  /**
   * Viewed page two-weeks-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20two-weeks-hub)
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Replay ID)
   * @param options Amplitude event options.
   */
  viewedPageTwoWeeksHub(
    properties: ViewedPageTwoWeeksHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageTwoWeeksHub(properties), options);
  }

  /**
   * Viewed page universal-list-itineraries
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20universal-list-itineraries)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageUniversalListItineraries(
    properties: ViewedPageUniversalListItinerariesProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageUniversalListItineraries(properties), options);
  }

  /**
   * Viewed page webinar
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20webinar)
   *
   * Landing page Webinar viewed
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageWebinar(
    properties: ViewedPageWebinarProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageWebinar(properties), options);
  }

  /**
   * Viewed page where-to-go
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20where-to-go)
   *
   * This event tracks when a user views the "Where to Go" page on the website
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageWhereToGo(
    properties: ViewedPageWhereToGoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageWhereToGo(properties), options);
  }

  /**
   * Viewed page winter-hub
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20winter-hub)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageWinterHub(
    properties: ViewedPageWinterHubProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageWinterHub(properties), options);
  }

  /**
   * Viewed page working-abroad
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Viewed%20page%20working-abroad)
   *
   * Owner: nicolas.risi@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  viewedPageWorkingAbroad(
    properties: ViewedPageWorkingAbroadProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedPageWorkingAbroad(properties), options);
  }

  /**
   * Vocabulary article clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Vocabulary%20article%20clicked)
   *
   * clic sur un bouton menant à une page vocabulaire
   *
   *
   *
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  vocabularyArticleClicked(
    properties: VocabularyArticleClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VocabularyArticleClicked(properties), options);
  }

  /**
   * Webinar replay clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Webinar%20replay%20clicked)
   *
   * Card cliked in the carroussel of the webinar Landing page:
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. card_number)
   * @param options Amplitude event options.
   */
  webinarReplayClicked(
    properties: WebinarReplayClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebinarReplayClicked(properties), options);
  }

  /**
   * Webinar sign-up clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Webinar%20sign-up%20clicked)
   *
   * Inscription à un Webinar:
   *
   *
   * Owner: Clement Salis
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  webinarSignUpClicked(
    properties: WebinarSignUpClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebinarSignUpClicked(properties), options);
  }

  /**
   * Wheretogo - Continent step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Continent%20step%20completed)
   *
   * **5th step**  
   *  This event tracks when a user completes the step of selecting a continent on the "Where to go" page
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoContinentStepCompleted(
    properties: WheretogoContinentStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoContinentStepCompleted(properties), options);
  }

  /**
   * Wheretogo - Destination recommendation started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Destination%20recommendation%20started)
   *
   * Event: Destination recommendation started Description: This event is triggered when a user begins the process of receiving recommendations for travel destinations. It indicates the initial step in the user journey towards finding the perfect destination for their trip
   *
   *
   *
   *
   *
   * Ou :
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoDestinationRecommendationStarted(
    properties: WheretogoDestinationRecommendationStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoDestinationRecommendationStarted(properties), options);
  }

  /**
   * Wheretogo - Duration step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Duration%20step%20completed)
   *
   * **3rd step** 
   *
   * This event tracks the completion of a duration step within a wheretogo user journey
   *
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoDurationStepCompleted(
    properties: WheretogoDurationStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoDurationStepCompleted(properties), options);
  }

  /**
   * Wheretogo - exit clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20exit%20clicked)
   *
   * This event tracks when a user clicks on the previous button on the "Where to go" page
   *
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoExitClicked(
    properties: WheretogoExitClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoExitClicked(properties), options);
  }

  /**
   * Wheretogo - Experiences iteration 3 step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Experiences%20iteration%203%20step%20completed)
   *
   *
   *
   *  **STEP 4**
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoExperiencesIteration3StepCompleted(
    properties: WheretogoExperiencesIteration3StepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoExperiencesIteration3StepCompleted(properties), options);
  }

  /**
   * Wheretogo - Experiences step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Experiences%20step%20completed)
   *
   * **4th step** 
   *
   * This event is triggered when a user completes a step in the experiences flow on our website
   *
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoExperiencesStepCompleted(
    properties: WheretogoExperiencesStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoExperiencesStepCompleted(properties), options);
  }

  /**
   * Wheretogo - Loader view results clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Loader%20view%20results%20clicked)
   *
   * Click sur le bouton "Afficher mes résultats" quand le chargement est terminé
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoLoaderViewResultsClicked(
    properties: WheretogoLoaderViewResultsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoLoaderViewResultsClicked(properties), options);
  }

  /**
   * Wheretogo - Maturity user step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Maturity%20user%20step%20completed)
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoMaturityUserStepCompleted(
    properties: WheretogoMaturityUserStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoMaturityUserStepCompleted(properties), options);
  }

  /**
   * Wheretogo - Optin NL completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Optin%20NL%20completed)
   *
   *
   *
   *
   *
   * This event tracks when a user has completed the opt-in process for the newsletter subscription on the "Where to Go" page
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoOptinNlCompleted(
    properties: WheretogoOptinNlCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoOptinNlCompleted(properties), options);
  }

  /**
   * Wheretogo - Pax step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Pax%20step%20completed)
   *
   * **STEP 1 :** This event tracks the completion of the step "avec qui partez-vous?" in the passenger journey on the "Where to Go" funnel 
   *
   *
   * ![](https://com-amplitude-orbit-media-prod-eu.s3.eu-central-1.amazonaws.com/100000330/b71c7061-e195-48ef-9c50-3358098561cd.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAS6JMKSBOWUS2GLRI%2F20231207%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20231207T135859Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEDwaDGV1LWNlbnRyYWwtMSJGMEQCIEGXQXpBKGnaUT0KSzTqnKYamxxXmPEyoOO2XbkpfBy8AiAVQF%2FBdvK8n7eHelQgimnT2HV0tR%2BxSY28dSyx%2F4d4cCrNBQil%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAMaDDIwMjQ5MzMwMDgyOSIMdo0VOJgPbl%2BnIim9KqEFR0ymHROwa3VO3rXDTplPQ2Pv4bKhC9nX5RVW8jsGGxCPFnrzzrm2RgbW9HdyjFh79iTsS9tmszpNTrkFzJKYBXgWoeodFbASLi794Ec%2B5XMRSfAtTWuDE%2F7rm1m69NN1k%2FS0Y3XmNVQ%2BwEYx9KLlyINkf8RtU9IjK%2FXh8ODcGEyN7Q8050xzSVIg7B0K8YhEuaZs4irsGoG6afppZICg0RX%2Bt1%2FqUBWfyoATmDKmbzdwwohvgiURWRuTlbsqnkWSTRihZwERg%2FprXl281ECCkurnKEqK428DygeaAcd4p5Fv%2BsW1aiU77hQPu896In29DL0gUqOMoUs22dFQTOMp3v60ItvhMSADX3kjFOWSdxEnQj0thnCjdWXwx9ZSrYjLRfi6%2FXJXMy%2Fk9T6SQkKI9V%2B1H6aPHDijU0dIUdgbWluGQ3BM90H%2FmFDqBGpgzbYJeKpLvRmedupRuTkxN9BzjO7QmqJG6xcNqQAuiiBNtME5kQdP8v%2FzpakgLhPZLGeXz8UFgAIoeFG13rlVE3ACz4caemxoMzoXx%2FKX1bsHHO8V6SckypQQGXqrzmg7FS5F1eYAkHJLomodxs51UhXJ%2BxuHN%2FQs50pN9MI2raMLUO9yw3GIAVsjzZ2f89RbSjkWN34E%2F59kzOSseRG6R0seJkJ6lsIvaJo1tKDjPkNxVzx7oL8ZsmLpjO1TnMN93ZZtRDxVe8X%2BfMMfmRrms23d09zZo3LsP%2FINTTUkSU06fFCUGoDtl8BoVFnhCJicocf4OdkdfHyHGX8fFsWIraS130qEZPXWIF8r8IZbQT2OkE%2Ba6JiAmUaPbxKii%2Fhz76EvklEJkEorudFGpk4g1Go%2B64Q2u3MDa9TXMKMah%2B7TMHkph%2Bc8X%2F6tr7pknCsb5P8%2FcDDb4MarBjqyAagD3VIATYqZvJHGz7rnISxR3qET6RiWydmrX6eg%2FV2zhJ%2Fr12mNGXvMbX8aP1pDuSsG9umNDz%2B5jXElG19M%2BcROKJ8cxSO67E0tdv3YDYYItRYFdN8Mx7z6yikc%2FwS0PmjnEv40DPhlsmTK6yhueieqbjubNjeXFgBhHi9y1NAdu%2FsBvj%2BO8cdc7iX6wtktDf%2Fjt9QsrklTfYV%2BZETu22EUy1fj%2Ba6Vt69yU%2FxyheJzfRo%3D&X-Amz-Signature=66f6497f185482eb78b943f9c8821ea3f5b9b377b2a78b09b7422c553aa4bde8&X-Amz-SignedHeaders=host&x-id=GetObject)
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoPaxStepCompleted(
    properties: WheretogoPaxStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoPaxStepCompleted(properties), options);
  }

  /**
   * Wheretogo - Period step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Period%20step%20completed)
   *
   * **2nd step**
   *
   * \*\*This event is triggered when a user completes the "season" step during their interaction with the website
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoPeriodStepCompleted(
    properties: WheretogoPeriodStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoPeriodStepCompleted(properties), options);
  }

  /**
   * Wheretogo - Previous step clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Previous%20step%20clicked)
   *
   * This event tracks when a user clicks on the previous button on the "Where to go" page
   *
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoPreviousStepClicked(
    properties: WheretogoPreviousStepClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoPreviousStepClicked(properties), options);
  }

  /**
   * Wheretogo - Recommendations viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Recommendations%20viewed)
   *
   * **Final step** This event tracks when a user views recommendations on the wheretogo funnel
   *
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoRecommendationsViewed(
    properties: WheretogoRecommendationsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoRecommendationsViewed(properties), options);
  }

  /**
   * Wheretogo - Results added to wishlist
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Results%20added%20to%20wishlist)
   *
   * Click sur l'icone "<3" sur les résultats "Destinations" et "Experiences" du POC Ou Partir.
   *
   * ⚠️ Ceci est un smoke test
   *
   *
   *
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. card_number)
   * @param options Amplitude event options.
   */
  wheretogoResultsAddedToWishlist(
    properties: WheretogoResultsAddedToWishlistProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoResultsAddedToWishlist(properties), options);
  }

  /**
   * Wheretogo - Results select budget
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Results%20select%20budget)
   *
   * Utilise les badges pour sélectionner son budget sur la page de résultats
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoResultsSelectBudget(
    properties: WheretogoResultsSelectBudgetProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoResultsSelectBudget(properties), options);
  }

  /**
   * Wheretogo - Results select segment
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Results%20select%20segment)
   *
   * Utilise les badges pour sélectionner son segment sur la page de résultats "itinéraires"
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoResultsSelectSegment(
    properties: WheretogoResultsSelectSegmentProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoResultsSelectSegment(properties), options);
  }

  /**
   * Wheretogo - Sustainability step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Sustainability%20step%20completed)
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoSustainabilityStepCompleted(
    properties: WheretogoSustainabilityStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoSustainabilityStepCompleted(properties), options);
  }

  /**
   * Wheretogo - Transportation step completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20-%20Transportation%20step%20completed)
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wheretogoTransportationStepCompleted(
    properties: WheretogoTransportationStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoTransportationStepCompleted(properties), options);
  }

  /**
   * Wheretogo clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wheretogo%20clicked)
   *
   * Clic sur "inspirez-moi" 
   *
   *
   * Sur la HP : remplacement de l'event CTA inspirez moi clicked par wheretogo clicked la semaine du 26 aout 2024
   *
   *
   *
   *
   *
   *
   *
   * Owner: clarisse.bolignano@external.evaneos.com
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  wheretogoClicked(
    properties: WheretogoClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WheretogoClicked(properties), options);
  }

  /**
   * Wishlist - Destination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wishlist%20-%20Destination%20clicked)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wishlistDestinationClicked(
    properties: WishlistDestinationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WishlistDestinationClicked(properties), options);
  }

  /**
   * Wishlist - Discover destination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wishlist%20-%20Discover%20destination%20clicked)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wishlistDiscoverDestinationClicked(
    properties: WishlistDiscoverDestinationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WishlistDiscoverDestinationClicked(properties), options);
  }

  /**
   * Wishlist - Itinerary clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wishlist%20-%20Itinerary%20clicked)
   *
   *
   *
   *
   * Owner: Camille Dalmais
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  wishlistItineraryClicked(
    properties: WishlistItineraryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WishlistItineraryClicked(properties), options);
  }

  /**
   * Wishlist added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wishlist%20added)
   *
   * Clic permettant d'ajouter un iti à sa wishlist
   *
   *
   *
   *
   * Owner: Marion Felder
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  wishlistAdded(
    properties: WishlistAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WishlistAdded(properties), options);
  }

  /**
   * Wishlist clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/evaneos/Website%20B2C/events/main/latest/Wishlist%20clicked)
   *
   * Clic permettant d'ajouter un iti à sa wishlist
   *
   *
   *
   *
   * Owner: quentin.mascart@evaneos.com
   *
   * @param properties The event's properties (e.g. block)
   * @param options Amplitude event options.
   */
  wishlistClicked(
    properties: WishlistClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WishlistClicked(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
